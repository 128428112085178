import React, { useState, useEffect } from "react";
import { Form, Select, Layout, Row, Col } from "antd";
import NormalFormIndex from "../NormalForm/normal_from_index";
import { SaveOutlined } from "@ant-design/icons";
import URL_WITH_VERSION, {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "..";
import redux_state from "../../services/redux_state";
import _ from "lodash";
const FormItem = Form.Item;
const Option = Select.Option;
const { Content } = Layout;
const OtherCostForm = (props) => {
  const [state, setState] = useState({
    frmName: "other_cost_detail_form",
    frmVisible: true,
    formData: props.formData,
  });

  useEffect(() => {
    const { formData } = state;
    console.log("othercost", formData["othercost"]);
    let _othercost =
      formData["othercost"] && formData["othercost"]?.length > 0
        ? formData["othercost"].map((el, ind) => ({
            ...el,
            s_no: ind + 1,
          }))
        : [];
    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, othercost: [..._othercost] },
    }));
  }, []);

  const ImportFormData = (data) => {
    const { frmName, formData } = state;

    if (
      redux_state &&
      redux_state?.reduxStore?.hasOwnProperty(frmName) &&
      data["id"]
    ) {
      let newdata = redux_state?.reduxStore[frmName];

      const cleanedNewData = _.cloneDeep(newdata);
      const cleanedFormData = _.cloneDeep(formData);

      cleanedNewData?.othercost?.forEach((item) => delete item.key);
      cleanedFormData?.othercost?.forEach((item) => delete item.key);

      if (!_.isEqual(cleanedNewData, cleanedFormData)) {
        openNotificationWithIcon("info", "Please Save the Form First", 2);
        return;
      }
    }
    if (data?.["id"]) {
      props.modalCloseEvent(data);
    } else {
      openNotificationWithIcon("info", "Please Save the Form first", 2);
    }
  };

  const saveFormData = (vData) => {
    try {
      let type = "save";
      let suMethod = "POST";
      if (vData.hasOwnProperty("id")) {
        type = "update";
        suMethod = "PUT";
      }
      const { frmName } = state;
      setState((prevState) => ({ ...prevState, frmVisible: false }));
      let suURL = `${URL_WITH_VERSION}/other-cost/${type}`;
      postAPICall(suURL, vData, suMethod, (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.row.msg);
          editformdata(data.row.other_cost_id);
        } else {
          openNotificationWithIcon("error", data.message);
          setState((prevState) => ({
            ...prevState,
            frmVisible: true,
          }));
        }
      });
    } catch (e) {
      console.error(e);
      setState((prevState) => ({ ...prevState, frmVisible: false }));
    }
  };

  const editformdata = async (id) => {
    if (id) {
      try {
        const data = await getAPICall(
          `${URL_WITH_VERSION}/other-cost/edit?ae=${id}`
        );

        if (data.data) {
          const respdata = await data["data"];

          let _othercost =
            respdata["othercost"] &&
            respdata["othercost"]?.map((el, index) => ({
              ...el,
              s_no: index + 1,
            }));
          respdata["othercost"] = [..._othercost];

          setState((prevState) => ({
            ...prevState,
            formData: { ...respdata, id: id },
            frmVisible: true,
          }));
        }
      } catch (err) {
        console.log("err", err);
        setState((prevState) => ({ ...prevState, frmVisible: false }));
      }
    }
  };

  const onClickExtraIcon = async (action, data, fulldata) => {
    let delete_id = data && data.id;
    let groupKey = action["gKey"];
    let frm_code = "";
    if (groupKey === "Other Cost") {
      groupKey = "othercost";
      frm_code = "other_cost_detail_form";
    }

    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey,
        key: data.key,
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  return (
    <div className="body-wrapper">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <Row gutter={16} style={{ marginRight: 0 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="body-wrapper">
                  <article className="article toolbaruiWrapper">
                    <div className="box box-default">
                      <div className="box-body">
                        {state.frmVisible ? (
                          <NormalFormIndex
                            key={"key_" + state.frmName + "_0"}
                            formClass="label-min-height"
                            formData={state.formData}
                            showForm={true}
                            frmCode={state.frmName}
                            frmVisible={state.frmVisible}
                            addForm={true}
                            inlineLayout={true}
                            showToolbar={[
                              {
                                isLeftBtn: [
                                  {
                                    isSets: [
                                      {
                                        id: "3",
                                        key: "save",
                                        type: (
                                          <SaveOutlined
                                            style={{
                                              cursor: props.disablebtn
                                                ? "not-allowed"
                                                : "pointer",
                                              opacity: props.disablebtn
                                                ? 0.5
                                                : 1,
                                            }}
                                          />
                                        ),
                                        withText: "Save",
                                        showToolTip: true,

                                        event: (key, data) =>
                                          props.disablebtn
                                            ? undefined
                                            : saveFormData(data),
                                      },
                                    ],
                                  },
                                ],
                                isRightBtn: [],
                              },
                            ]}
                            tableRowDeleteAction={(action, data, fulldata) =>
                              onClickExtraIcon(action, data, fulldata)
                            }
                            showButtons={[
                              {
                                id: "import",
                                title: "import",
                                type: "primary",
                                isDisabled: props.disablebtn,
                                event: (data) => {
                                  ImportFormData(data);
                                },
                              },
                            ]}
                            summary={[
                              { gKey: "othercost", showTotalFor: ["amount"] },
                            ]}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </article>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default OtherCostForm;
