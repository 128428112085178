import React, {
  Component,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { Modal, Spin } from "antd";
import moment from "moment";
// import { T } from 'antd/lib/upload/utils';
// import URL_WITH_VERSION, { getAPICall, objectToQueryStringFunc, apiDeleteCall, openNotificationWithIcon, ResizeableTitle } from '../../shared';
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import URL_WITH_VERSION, {
  awaitPostAPICall,
  openNotificationWithIcon,
} from "../../shared";
import Email from "../../components/Email/index";
import { handleBeforePrint } from "../../services/utils";
/* ES5 */
const ComponentToPrint = forwardRef((props, ref) => {
  const [formReportdata, setFormReportdata] = useState(
    Object.assign({}, props.data || {})
  );

  // const bunker_expenses =
  //   formReportdata &&
  //     formReportdata.estimate &&
  //     formReportdata.estimate.expenses &&
  //     formReportdata.estimate.expenses.bunker_expenses
  //     ? formReportdata.estimate.expenses.bunker_expenses
  //     : {};
  // let totalExpenses = 0;

  // for (const category in bunker_expenses) {
  //   if (bunker_expenses.hasOwnProperty(category)) {
  //     const categoryExpenses = bunker_expenses[category];

  //     for (const expense in categoryExpenses) {
  //       if (categoryExpenses.hasOwnProperty(expense)) {
  //         const expenseValue = categoryExpenses[expense];
  //         // Check if the value is a string before trying to replace
  //         if (typeof expenseValue === 'string') {
  //           const value = expenseValue.replace(/,/g, "");
  //           if (!isNaN(value)) {
  //             totalExpenses += parseFloat(value);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // const vesselexpobj =
  //   formReportdata &&
  //   formReportdata.estimate &&
  //   formReportdata.estimate.expenses.vessel_expenses;

  // const vesselExpenses =
  //   vesselexpobj && vesselexpobj.hire_expenses
  //     ? Number(vesselexpobj.hire_expenses.replace(/,/g, "")).toFixed(2)
  //     : "N/A";

  const {
    total_noneca_sea_cons,
    total_eca_sea_cons,
    total_noneca_port_cons,
    total_eca_port_cons,
  } = formReportdata?.estimate?.expenses?.bunker_expenses || {};

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  className="reportlogo"
                  src={formReportdata.logo}
                  alt="formReportdata"
                ></img>
                <p className="sub-title m-0">{formReportdata.full_name}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{formReportdata.address}</p>
              </div>
            </div>
          </div>
          <table className="table  custom-table-bordered tc-table">
            <br />
            <h4 className="font-weight-bold tc-sub-header ml-4">
              P & L Summary
            </h4>
            <tbody>
              <td className="border-0">
                <table className="table voy-table ">
                  <tbody>
                    <table className="table voy-report-cons">
                      <tbody>
                        <td>
                          <h5 className="font-weight-bold text-center">
                            Revenue
                          </h5>
                          <table className="table border-table ">
                            <tbody>
                              <tr>
                                <td className="font-weight-bold">Freight :</td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue?.freight ??
                                    "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Freight Commission :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue
                                    ?.freight_commission ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Misc. revenue :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue
                                    ?.mis_revenue ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Demurrage Amt :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue
                                    ?.demmurage ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Demurrage Comm Amt :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue
                                    ?.demmurageCommission ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Despatch Amt :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue
                                    ?.desmurage ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Gross Revenue :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue
                                    ?.gross_revenue ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Net Revenue :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.revenue
                                    ?.net_revenue ?? "0.00"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td>
                          <h5 className="font-weight-bold text-center voy-report-cons">
                            Expenese
                          </h5>
                          <table className="table border-table ">
                            <tbody>
                              <tr>
                                <td className="font-weight-bold">
                                  Vessel Expenses :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.vessel_expenses?.hire_expenses ?? "0.00"}
                                  {/* {vesselExpenses} */}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  TCI Address Commission :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.tci_add_comm ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  TCI Bro Commission :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.tci_broker_comm ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Port Expenses :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.port_expenses ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Emission Expenses :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.co2expense ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Misc. Expenses :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.mis_expenses ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Bunker Expenses :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.sum_bunker_expenses ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Sea Consp Non ECA :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.sum_sea_cons_non_eca ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Sea Consp ECA :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.sum_sea_eca_cons ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Port Consp :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.sum_port_cons ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Gross Expenses :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.grossexpensee ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Net Expenses :
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.expenses
                                    ?.total_expenses ?? "0.00"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td>
                          <h5 className="font-weight-bold text-center">
                            Voyage Result
                          </h5>
                          <table className="table border-table voy-report-cons">
                            <tbody>
                              <tr>
                                <td className="font-weight-bold">
                                  Profit (Loss):
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.profit_loss ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Daily Profit (Loss):
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.daily_profit_loss ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  TCE Hire (Net Daily):
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.tce_hire_net_daily ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">Gross TCE:</td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.gross_tce ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Average Freight rate ($/T):
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.avargefright ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Breakeven & Freight rate ($/T):
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.breakeven_and_freight_rate ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">Co2 Cost:</td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.co2expense ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  CO2 Adjusted Profit (Loss):
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.CO2_adj_profit ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  CO2 Adjusted (Net) TCE:
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.co2_adjusted_tce ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Total Sea Days:
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.total_sea_days ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Total Port Days:
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.total_port_days ?? "0.00"}
                                </td>
                              </tr>

                              <tr>
                                <td className="font-weight-bold">
                                  Net Voyage Days:
                                </td>
                                <td className="text-right">
                                  {formReportdata?.estimate?.voyage_result
                                    ?.net_voyage_days ?? "0.00"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tbody>
                    </table>
                  </tbody>
                </table>

                <h5 className="font-weight-bold tc-sub-header">
                  Vessel Details
                </h5>
                <table className="table table-bordered ">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Vessel Name/Code :</td>
                      <td className="text-right">
                        {formReportdata.vessel_name
                          ? formReportdata.vessel_name
                          : "N/A"}
                        /
                        {formReportdata.vessel_code
                          ? formReportdata.vessel_code
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">My Company/LOB :</td>
                      <td className="text-right">
                        {formReportdata.my_company_name
                          ? formReportdata.my_company_name
                          : "N/A"}{" "}
                        /{" "}
                        {formReportdata.company_lob
                          ? formReportdata.company_lob
                          : "0.00"}
                      </td>

                      <td className="font-weight-bold">Voy Est. ID :</td>
                      <td className="text-right">
                        {formReportdata.estimate_id
                          ? formReportdata.estimate_id
                          : "0.00"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Vessel Type :</td>
                      <td className="text-right">
                        {formReportdata.vessel_type
                          ? formReportdata.vessel_type
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">C/P Date :</td>
                      <td className="text-right">
                        {formReportdata.cp_date
                          ? formReportdata.cp_date
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">Fixed By/Ops User :</td>
                      <td className="text-right">
                        {formReportdata.user_name_name
                          ? formReportdata.user_name_name
                          : "N/A"}
                        /
                        {formReportdata.ops_user_name
                          ? formReportdata.ops_user_name
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Vessel Sub Type:</td>
                      <td className="text-right">
                        {formReportdata.vessel_sub_type
                          ? formReportdata.vessel_sub_type
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold"> Other Cost:</td>
                      <td className="text-right">
                        {formReportdata.bb ? formReportdata.bb : "0.00"}
                      </td>
                      <td className="font-weight-bold">Commence Date :</td>
                      <td className="text-right">
                        {formReportdata.commence_date
                          ? formReportdata.commence_date
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">DWT :</td>
                      <td className="text-right">
                        {formReportdata.dwt ? formReportdata.dwt : "0.00"}
                      </td>
                      <td className="font-weight-bold">Ballast Bonus :</td>
                      <td className="text-right">
                        {formReportdata.ballast_bonus
                          ? formReportdata.ballast_bonus
                          : "0.00"}
                      </td>

                      <td className="font-weight-bold">Completing Date :</td>
                      <td className="text-right">
                        {formReportdata.completing_date
                          ? formReportdata.completing_date
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">HF/TCI Code :</td>
                      <td className="text-right">
                        {formReportdata[".tci_code"]
                          ? formReportdata[".tci_code"]
                          : "0.00"}
                      </td>
                      <td className="font-weight-bold">Voyage Ops Type:</td>
                      <td className="text-right">
                        {formReportdata.ops_type_name
                          ? formReportdata.ops_type_name
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold"> Total Voyage Days :</td>
                      <td className="text-right">
                        {formReportdata.total_days
                          ? formReportdata.total_days
                          : "0.00"}
                      </td>
                    </tr>

                    <tr></tr>

                    <tr>
                      <td className="font-weight-bold">Add Com./Broker % :</td>
                      <td className="text-right">
                        {formReportdata.add_percentage
                          ? formReportdata.add_percentage
                          : "0.00"}
                        /
                        {formReportdata.bro_percentage
                          ? formReportdata.bro_percentage
                          : "0.00"}
                      </td>

                      <td className="font-weight-bold">ECA Fuel Grade:</td>
                      <td className="text-right">
                        {formReportdata.eca_fuel_grade_name
                          ? formReportdata.eca_fuel_grade_name
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">Trade Area :</td>
                      <td className="text-right">
                        {formReportdata.trade_area_name
                          ? formReportdata.trade_area_name
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">WF (%) :</td>
                      <td className="text-right">
                        {formReportdata.dwf ? formReportdata.dwf : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <tbody>
                    <td>
                      <h5 className="font-weight-bold">Eco/Cp speed</h5>
                      <table className="table border-table ">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th rowSpan="2">SPD Type</th>
                            <th className="text-center">Ballast Bonus</th>
                            <th className="text-center">Laden</th>
                          </tr>
                          <tr className="HeaderBoxText">
                            <th>Kt</th>
                            <th>Cons</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata["-"]?.length > 0 &&
                            formReportdata["-"].map((e, idx) => (
                              <tr key={idx}>
                                <td>{e.spd_type ?? "0.00"}</td>
                                <td>{e.ballast_spd ?? "0.00"}</td>
                                <td>{e.laden_spd ?? "0.00"}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <h5 className="font-weight-bold">
                        Speed/Cons and price $
                      </h5>
                      <table className="table border-table ">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>CP$</th>
                            <th>Fuel Grade</th>
                            <th>Sea Con B</th>
                            <th>Sea Con.L</th>
                            <th>Load</th>
                            <th>Disch</th>
                            <th>Idle</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata["."]?.length > 0 &&
                            formReportdata["."].map((e, idx) => (
                              <tr key={idx}>
                                <td>{e.cp_price ?? "0.00"}</td>
                                <td>{e.fuel_code ?? "0.00"}</td>
                                <td>{e.ballast_value ?? "0.00"}</td>
                                <td>{e.laden_value ?? "0.00"}</td>
                                <td>{e.con_loading ?? "0.00"}</td>
                                <td>{e.con_disch ?? "0.00"}</td>
                                <td>{e.con_ideal_on ?? "0.00"}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </td>
                  </tbody>
                </table>

                <h5 className="font-weight-bold tc-sub-header">Cargo</h5>
                <table className="table border-table ">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>S/N</th>
                      <th>Cargo ID</th>
                      <th>Charterer Party</th>
                      <th>Cargo name</th>
                      <th>CP qty</th>
                      <th>UNIT</th>
                      <th>Opt%</th>
                      <th>OPT Type</th>
                      <th>Freight Type</th>
                      <th>Frt rate</th>
                      <th>Lumsum</th>
                      <th>World Scale</th>
                      <th>B Comm%</th>
                      <th>Extra Rev</th>
                      <th>Currency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.cargos?.length > 0 &&
                      formReportdata.cargos.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.serial_no ? e.serial_no : "0.00"}</td>
                          <td>
                            {e.cargo_contract_id ? e.cargo_contract_id : "0.00"}
                          </td>
                          <td>{e.charterer ? e.charterer : "0.00"}</td>
                          <td>{e.cargo_name ? e.cargo_name : "N/a"}</td>
                          <td>{e.cp_qty ? e.cp_qty : "0.00"}</td>
                          <td>{e.cp_unit_name ? e.cp_unit_name : "0.00"}</td>
                          <td>
                            {e.opt_percentage ? e.opt_percentage : "0.00"}
                          </td>
                          <td>{e.opt_type_name ? e.opt_type_name : "N/A"}</td>
                          <td>{e.frt_type_name ? e.frt_type_name : "N/A"}</td>
                          <td>{e.frat_rate ? e.frat_rate : "0.00"}</td>
                          <td>{e.lumsum ? e.lumsum : "0.00"}</td>
                          <td>{e.world_scale ? e.world_scale : "0.00"}</td>
                          <td>{e.b_commission ? e.b_commission : "0.00"}</td>
                          <td>{e.extra_rev ? e.extra_rev : "0.00"}</td>
                          <td>{e.currency_name ? e.currency_name : "N/A"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {/* Port Itinerary Table */}
                <table className="table voy-table ">
                  <tbody>
                    <h5 className="font-weight-bold tc-sub-header">
                      Port Itinerary
                    </h5>
                    <table className="table border-table n">
                      <thead className="">
                        <tr className="HeaderBoxText ">
                          <th>Port</th>
                          <th>Crossed</th>
                          <th>Eca/Seca Dist</th>
                          <th>Eca/Seca Days</th>
                          <th>Function</th>
                          <th>Passage</th>
                          <th>Miles</th>
                          <th>STYPE</th>
                          <th>WF%</th>
                          <th>SPD</th>
                          <th>Eff-SPD</th>
                          <th>GSD</th>
                          <th>TSD</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.portitinerary?.length > 0 &&
                          formReportdata.portitinerary.map((e, idx) => (
                            <tr key={idx}>
                              <td>{e.port || "N/A"}</td>
                              <td>{e.crossed ?? "0.00"}</td>
                              <td>{e.seca_length ?? "0.00"}</td>
                              <td>{e.eca_days ?? "0.00"}</td>
                              <td>{e.funct_name ?? "N/A"}</td>
                              <td>{e.passagename ?? "N/A"}</td>
                              <td>{e.miles ?? "0.00"}</td>
                              <td>{e.s_type_name ?? "N/A"}</td>
                              <td>{e.wf_per ?? "0.00"}</td>
                              <td>{e.speed ?? "0.00"}</td>
                              <td>{e.eff_speed ?? "0.00"}</td>
                              <td>{e.gsd ?? "0.00"}</td>
                              <td>{e.tsd ?? "0.00"}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <table className="table border-table n">
                      <thead className="">
                        <tr className="HeaderBoxText ">
                          <th>XSD</th>
                          <th>L/D QTY</th>
                          <th>L/D Rate(D)</th>
                          <th>L/D Rate(H)</th>
                          <th>L/D Term </th>
                          <th>TurnTime(H)</th>
                          <th>P Days</th>
                          <th>XPD</th>
                          <th>Ttl P Days</th>
                          <th>P.EXP</th>
                          <th>Demurrage/Dispatch</th>
                          <th>Dem/Des Final Amount</th>
                          <th>Euro. Port</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.portitinerary?.length > 0 &&
                          formReportdata.portitinerary.map((e, idx) => (
                            <tr key={idx}>
                              <td>{e.xsd ?? "0.00"}</td>
                              <td>{e.l_d_qty ?? "0.00"}</td>
                              <td>{e.l_d_rate ?? 0}</td>
                              <td>{e.l_d_rate1 ?? "0.00"}</td>
                              <td>{e.l_d_termname ?? "N/A"}</td>
                              <td>{e.turn_time ?? "0.00"}</td>
                              <td>{e.days ?? "0.00"}</td>
                              <td>{e.xpd ?? "0.00"}</td>
                              <td>{e.t_port_days ?? "0.00"}</td>
                              <td>{e.p_exp ?? "0.00"}</td>
                              <td>{e.dem_disp ?? "0.00"}</td>
                              <td>{e.dem_disp_amt ?? "0.00"}</td>
                              <td>{e.is_eur ?? "0.00"}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    {/* Bunker Details table */}
                    <h4 className="font-weight-bold tc-sub-header">
                      Bunker Details
                    </h4>
                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th rowSpan="2">Port</th>
                          <th rowSpan="2">Miles</th>
                          <th rowSpan="2">Function</th>
                          <th rowSpan="2">TSD</th>
                          <th rowSpan="2">Eca/Seca Dist</th>
                          <th rowSpan="2">Eca/Seca Days</th>
                          <th rowSpan="2">SPD Type</th>

                          <th rowSpan="2">SPD</th>
                          <th rowSpan="2">Arrival</th>
                          <th rowSpan="2">Departure</th>
                          <th colSpan="5">Fuel Grade (Sea Cons. In MT)</th>
                        </tr>
                        <tr className="HeaderBoxText">
                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.bunkerdetails?.length > 0 &&
                          formReportdata.bunkerdetails.map((e, idx) => (
                            <tr key={idx}>
                              <td>{e.port || "N/A"}</td>
                              <td>{e.miles ?? "0.00"}</td>
                              <td>{e.funct_name ?? "N/A"}</td>
                              <td>{e.tsd ?? "0.00"}</td>
                              <td>{e.seca_length ?? "0.00"}</td>
                              <td>{e.eca_days ?? "0.00"}</td>
                              <td>{e.spd_type_name ?? "N/A"}</td>

                              <td>{e.speed ?? "0.00"}</td>
                              <td>
                                {e.arrival_date_time
                                  ? e.arrival_date_time
                                  : "N/A"}
                              </td>
                              <td>{e.departure ? e.departure : "0.00"}</td>
                              <td>{e.ifo ?? "0.00"}</td>
                              <td>{e.vlsfo ?? "0.00"}</td>
                              <td>{e.lsmgo ?? "0.00"}</td>
                              <td>{e.mgo ?? "0.00"}</td>
                              <td>{e.ulsfo ?? "0.00"}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th rowSpan="2">Eca/Seca Cons.(in Mt)</th>
                          {/* <th rowSpan="2">Sea Co2 Emission</th> */}
                          <th colSpan="5">Arrival ROB</th>
                          <th colSpan="5">Port Cons. Fuel</th>
                        </tr>
                        <tr className="HeaderBoxText">
                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>

                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.bunkerdetails?.length > 0 &&
                          formReportdata.bunkerdetails.map((e, idx) => (
                            <tr key={idx}>
                              <td>{e.eca_consp ?? "0.00"}</td>
                              {/* <td>{e.co_emmission ?? "0.00"}</td> */}
                              <td>{e.arob_ifo ?? "0.00"}</td>
                              <td>{e.arob_vlsfo ?? "0.00"}</td>
                              <td>{e.arob_lsmgo ?? "0.00"}</td>
                              <td>{e.arob_mgo ?? "0.00"}</td>
                              <td>{e.arob_ulsfo ?? "0.00"}</td>
                              <td>{e.pc_ifo ?? "0.00"}</td>
                              <td>{e.pc_vlsfo ?? "0.00"}</td>
                              <td>{e.pc_lsmgo ?? "0.00"}</td>
                              <td>{e.pc_mgo ?? "0.00"}</td>
                              <td>{e.pc_ulsfo ?? "0.00"}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th colSpan="5">Received</th>
                          <th colSpan="5">Departure ROB</th>
                        </tr>
                        <tr className="HeaderBoxText">
                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>

                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.bunkerdetails?.length > 0 &&
                          formReportdata.bunkerdetails.map((e, idx) => (
                            <tr key={idx}>
                              <td>{e.r_ifo ?? "0.00"}</td>
                              <td>{e.r_vlsfo ?? "0.00"}</td>
                              <td>{e.r_lsmgo ?? "0.00"}</td>
                              <td>{e.r_mgo ?? "0.00"}</td>
                              <td>{e.r_ulsfo ?? "0.00"}</td>
                              <td>{e.dr_ifo ?? "0.00"}</td>
                              <td>{e.dr_vlsfo ?? "0.00"}</td>
                              <td>{e.dr_lsmgo ?? "0.00"}</td>
                              <td>{e.dr_mgo ?? "0.00"}</td>
                              <td>{e.dr_ulsfo ?? "0.00"}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    {/* Total Bunker Details table */}

                    <h4 className="font-weight-bold tc-sub-header">
                      Total Bunker Details
                    </h4>
                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Miles</th>
                          <th>Eca/Seca Dist.</th>
                          <th>Eca/Seca Days</th>
                          <th>SPD</th>
                          <th>Eca S.Cons</th>
                          <th>S.Cons IFO</th>
                          <th>S.cons VLSFO</th>
                          <th>S.Cons LSMGO</th>
                          <th>S.Cons MGO</th>
                          <th>S.Cons ULSFO</th>
                          <th>p.Cons IFO</th>
                          <th>P.Cons VLSFO</th>
                          <th>P.Cons LSMGO</th>
                          <th>P.Cons MGO</th>
                          <th>P.Cons ULSFO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.totalbunkerdetails?.length > 0 &&
                          formReportdata.totalbunkerdetails.map((e, idx) => (
                            <tr key={idx}>
                              <td>{e.ttl_miles ?? "0.00"}</td>
                              <td>{e.ttl_seca_length ?? "0.00"}</td>
                              <td>{e.ttl_eca_days ?? "0.00"}</td>
                              <td>{e.ttl_speed ?? "0.00"}</td>
                              <td>00</td>
                              <td>{e.ttl_ifo ?? "0.00"}</td>
                              <td>{e.ttl_vlsfo ?? "0.00"}</td>
                              <td>{e.ttl_lsmgo ?? "0.00"}</td>
                              <td>{e.ttl_mgo ?? "0.00"}</td>
                              <td>{e.ttl_ulsfo ?? "0.00"}</td>
                              <td>{e.ttl_pc_ifo ?? "0.00"}</td>
                              <td>{e.ttl_pc_vlsfo ?? "0.00"}</td>
                              <td>{e.ttl_pc_lsmgo ?? "0.00"}</td>
                              <td>{e.ttl_pc_mgo ?? "0.00"}</td>
                              <td>{e.ttl_pc_ulsfo ?? "0.00"}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <h4 className="font-weight-bold tc-sub-header">
                      Port Date Details
                    </h4>
                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Port</th>
                          <th>Funct.</th>
                          <th>MILES</th>
                          <th>Passage</th>
                          <th>STYPE</th>
                          <th>SPD</th>
                          <th>WF%</th>
                          <th>TSD</th>
                          <th>XSD</th>
                          <th>Arrival Date Time</th>
                          <th>Day</th>
                          <th>T.PDays</th>
                          <th>Departure</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.portdatedetails?.length > 0 &&
                          formReportdata.portdatedetails.map((e, idx) => (
                            <tr key={idx}>
                              <td>{e.port ? e.port : "N/A"}</td>
                              <td>{e.funct_name ?? "N/A"}</td>
                              <td>{e.miles ?? "0.00"}</td>
                              <td>{e.passagename ?? "N/A"}</td>
                              <td>{e.s_type_name ?? "N/A"}</td>
                              <td>{e.speed ?? "0.00"}</td>
                              <td>{e.wf_per ?? "0.00"}</td>
                              <td>{e.tsd ?? "0.00"}</td>
                              <td>{e.xsd ?? "0.00"}</td>
                              <td>
                                {e.arrival_date_time &&
                                e.arrival_date_time !== "0000-00-00 00:00"
                                  ? moment(e.arrival_date_time).format(
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  : "N/A"}
                              </td>
                              <td>{e.day ?? "0.00"}</td>
                              <td>{e.pdays ?? "0.00"}</td>
                              <td>
                                {e.departure &&
                                e.departure !== "0000-00-00 00:00"
                                  ? moment(e.departure).format(
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  : "N/A"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </tbody>
                </table>
                <h4 className="font-weight-bold tc-sub-header">CII Dynamics</h4>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">Port</th>
                      <th rowSpan="2">Function</th>
                      <th rowSpan="2">Passage</th>
                      <th colSpan="3">Operation Days</th>
                      <th rowSpan="2">TOTAL DISTANCE SAILED(Miles)</th>
                      <th colSpan="5"> Sea Consumption</th>
                      <th colSpan="5">Port Consumption</th>
                    </tr>
                    <tr className="HeaderBoxText">
                      <th>Sea</th>
                      <th>Port</th>
                      <th>Total</th>

                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>ULSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>

                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>ULSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.ciidynamics?.length > 0 &&
                      formReportdata.ciidynamics.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.port ?? "0.00"}</td>
                          <td>{e.funct_name ?? "N/A"}</td>
                          <td>{e.passagename ?? "N/A"}</td>
                          <td>{e.sea ?? "0.00"}</td>
                          <td>{e.ops_port ?? "0.00"}</td>
                          <td>{e.total ?? "0.00"}</td>
                          <td>{e.dst_sailed ?? "0.00"}</td>
                          <td>{e.ifo ?? "0.00"}</td>
                          <td>{e.vlsfo ?? "0.00"}</td>
                          <td>{e.ulsfo ?? "0.00"}</td>
                          <td>{e.lsmgo ?? "0.00"}</td>
                          <td>{e.mgo ?? "0.00"}</td>

                          <td>{e.pc_ifo ?? "0.00"}</td>
                          <td>{e.pc_vlsfo ?? "0.00"}</td>
                          <td>{e.pc_ulsfo ?? "0.00"}</td>
                          <td>{e.pc_lsmgo ?? "0.00"}</td>
                          <td>{e.pc_mgo ?? "0.00"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">Co2 Emission VLSFO/ULSFO</th>
                      <th rowSpan="2">Co2 Emission MGO/LSMGO</th>
                      <th rowSpan="2">Co2 Emission IFO</th>
                      <th rowSpan="2">Total Co2 Emission (MT)</th>
                      <th rowSpan="2">CII Ref</th>
                      <th rowSpan="2">CII Required</th>
                      <th rowSpan="2">CII Attained</th>
                      <th rowSpan="2">CII Rating</th>
                      <th rowSpan="2">CII Band</th>
                      <th rowSpan="2">Predicted Required CII 2025</th>
                      <th rowSpan="2">Predicted CII Rating 2025</th>
                      <th rowSpan="2">Year</th>
                    </tr>
                    <tr className="HeaderBoxText"></tr>
                  </thead>
                  <tbody>
                    {formReportdata.ciidynamics?.length > 0 &&
                      formReportdata.ciidynamics.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.co2_emission_vu ?? "0.00"}</td>
                          <td>{e.co2_emission_ml ?? "0.00"}</td>
                          <td>{e.co2_emission_ifo ?? "0.00"}</td>
                          <td>{e.co2_emission_total ?? "0.00"}</td>
                          <td>{e.cii_ref ?? "0.00"}</td>
                          <td>{e.cii_req ?? "0.00"}</td>
                          <td>{e.cii_att ?? "0.00"}</td>
                          <td>{e.cii_ret ?? "0.00"}</td>
                          <td>{e.cii_band ?? "0.00"}</td>
                          <td>{e.cii_pred ?? "0.00"}</td>
                          <td>{e.cii_pred_ret ?? "0.00"}</td>
                          <td>{e.year ?? "N/A"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">
                  Total CII Dynamics Summary
                </h4>

                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>VLSFO Consumption</th>
                      <th>ULSFO Consumption</th>
                      <th>IFO Consumption</th>
                      <th>LSMGO Consumption</th>
                      <th>MGO Consumption</th>
                      <th>CO2 Emission</th>
                    </tr>
                  </thead>

                  <tbody>
                    {formReportdata.totalciidynamicssummary?.length > 0 &&
                      formReportdata.totalciidynamicssummary.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.ttl_vlsfo_con ?? "0.00"}</td>
                          <td>{e.ttl_ulsfo_con ?? "0.00"}</td>
                          <td>{e.ttl_ifo_con ?? "0.00"}</td>
                          <td>{e.ttl_lsmgo_con ?? "0.00"}</td>
                          <td>{e.ttl_mgo_con ?? "0.00"}</td>
                          <td>{e.ttl_co2_emission_total ?? "0.00"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">EU ETS</h4>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">Port</th>
                      <th rowSpan="2">Function</th>
                      <th colSpan="5">Sea Consumption</th>
                      <th colSpan="5">Port Consumption</th>

                      <th rowSpan="2">Sea Emission (MT)</th>
                      <th rowSpan="2">Port Emission (MT)</th>
                    </tr>
                    <tr className="HeaderBoxText">
                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                      <th>ULSFO</th>

                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                      <th>ULSFO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.euets?.length > 0 &&
                      formReportdata.euets.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.port ?? "0.00"}</td>
                          <td>{e.funct_name ?? "0.00"}</td>

                          <td>{e.ifo ?? "0.00"}</td>
                          <td>{e.vlsfo ?? "0.00"}</td>
                          <td>{e.lsmgo ?? "0.00"}</td>
                          <td>{e.mgo ?? "0.00"}</td>
                          <td>{e.ulsfo ?? "0.00"}</td>

                          <td>{e.pc_ifo ?? "0.00"}</td>
                          <td>{e.pc_vlsfo ?? "0.00"}</td>
                          <td>{e.pc_lsmgo ?? "0.00"}</td>
                          <td>{e.pc_mgo ?? "0.00"}</td>
                          <td>{e.pc_ulsfo ?? "0.00"}</td>

                          <td>{e.sea_ems ?? "0.00"}</td>
                          <td>{e.port_ems ?? "0.00"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">Total Emission(MT)</th>
                      <th rowSpan="2">Phase In %</th>
                      <th rowSpan="2">Sea ETS %</th>
                      <th rowSpan="2">Sea ETS Emission (MT)</th>
                      <th rowSpan="2">Port ETS %</th>
                      <th rowSpan="2">Port ETS Emission (MT)</th>
                      <th rowSpan="2">Total EU ETS (MT)</th>
                      <th rowSpan="2">Total EU ETS Expense</th>
                      <th rowSpan="2">Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.euets?.length > 0 &&
                      formReportdata.euets.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.ttl_ems ?? "0.00"}</td>
                          <td>{e.phase_pre ?? "0.00"}</td>
                          <td>{e.sea_pre_ets ?? "0.00"}</td>
                          <td>{e.sea_ets_ems ?? "0.00"}</td>
                          <td>{e.port_pre_ems ?? "0.00"}</td>
                          <td>{e.port_ets_ems ?? "0.00"}</td>
                          <td>{e.ttl_eu_ets ?? "0.00"}</td>
                          <td>{e.ttl_eu_ets_exp ?? "0.00"}</td>
                          <td>{e.year ?? "0.00"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">Co2 Price Per MT</th>
                      <th rowSpan="2">Total Co2 Cost (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.euets?.length > 0 &&
                      formReportdata.euets.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.ttl_ems ?? "0.00"}</td>
                          <td>{e.phase_pre ?? "0.00"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">
                  Total EU ETS Summary
                </h4>

                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>Total Sea Emission</th>
                      <th>Total Port Emission</th>
                      <th>Total Emission</th>
                      <th>Total Sea Ets Emission</th>
                      <th>Total Port Ets Emission</th>
                      <th>Total Ets Emission</th>
                      <th>Total EU-ETS Emission</th>
                    </tr>
                  </thead>

                  <tbody>
                    {formReportdata.totaleuetssummary?.length > 0 &&
                      formReportdata.totaleuetssummary.map((e, idx) => (
                        <tr key={idx}>
                          <td>{e.ttl_sea_emi ?? "0.00"}</td>
                          <td>{e.ttl_port_emi ?? "0.00"}</td>
                          <td>{e.ttl_emi ?? "0.00"}</td>
                          <td>{e.ttl_sea_ets_emi ?? "0.00"}</td>
                          <td>{e.ttl_port_ets_emi ?? "0.00"}</td>
                          <td>{e.ttl_ets_emi ?? "0.00"}</td>
                          <td>{e.ttl_eu_ets_emi ?? "0.00"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {/* <table className="table border-table ">
                    <thead>
                      <tr className="HeaderBoxText">
                        <th>Total Distance(in Miles)</th>
                        <th>Total Port Days(in Days)</th>
                        <th>Total Sea Days(in Days)</th>
                        <th>Total Load(in Mt)</th>
                        <th>Gross Sea Days(in Days)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {formReportdata["---------"] &&
                          formReportdata["---------"]
                            ? formReportdata["---------"].total_distance
                            : "N/A"}{" "}
                          <span />
                        </td>
                        <td>
                          {formReportdata["---------"] &&
                          formReportdata["---------"]
                            ? formReportdata["---------"].totalt_port_days
                            : "N/A"}{" "}
                          <span />
                        </td>
                        <td>
                          {formReportdata["---------"] &&
                          formReportdata["---------"]
                            ? formReportdata["---------"].total_tsd
                            : "N/A"}{" "}
                          <span />
                        </td>
                        <td>
                          {formReportdata["---------"] &&
                          formReportdata["---------"]
                            ? formReportdata["---------"].total_load
                            : "N/A"}{" "}
                          <span />
                        </td>
                        <td>
                          {formReportdata["---------"] &&
                          formReportdata["---------"]
                            ? formReportdata["---------"].total_gsd
                            : "N/A"}{" "}
                          <span />
                        </td>
                      </tr>
                    </tbody>
                  </table> */}

                {/* 
 as per  discussion with pallav sir
                  <h5 className="font-weight-bold">P & L Summary</h5>
                  <div className="estimate-first">
                    <table className="table border-table  voy-report-L">
                      <tbody>
                        <tr>
                          <td className="font-weight-bold">Freight :</td>
                          {formReportdata &&
                          formReportdata.estimate &&
                          formReportdata.estimate.revenue &&
                          formReportdata.estimate.revenue ? (
                            <td>
                              {formReportdata.estimate.revenue.frtrate}{" "}
                            </td>
                          ) : (
                            undefined
                          )}
                        </tr>

                        <tr>
                          <td className="font-weight-bold">Demurrage :</td>
                          {formReportdata &&
                          formReportdata.estimate &&
                          formReportdata.estimate.revenue &&
                          formReportdata.estimate.revenue ? (
                            <td className="text-right">
                              {formReportdata.estimate.revenue.demurrage}
                            </td>
                          ) : (
                            undefined
                          )}
                        </tr>

                        <tr>
                          <td className="font-weight-bold">Gross Income :</td>
                          {formReportdata &&
                          formReportdata.estimate &&
                          formReportdata.estimate.revenue &&
                          formReportdata.estimate.revenue ? (
                            <td className="text-right">
                              {formReportdata.estimate.revenue.grossrevenue}
                            </td>
                          ) : (
                            undefined
                          )}
                        </tr>

                        <tr>
                          <td className="font-weight-bold">
                            Less Commissions :
                          </td>

                          {formReportdata &&
                          formReportdata.estimate &&
                          formReportdata.estimate.revenue &&
                          formReportdata.estimate.revenue ? (
                            <td className="text-right">
                              {formReportdata.estimate.revenue.b_commission}
                            </td>
                          ) : (
                            undefined
                          )}
                        </tr>

                        <tr>
                          <td className="font-weight-bold">Less Tax :</td>
                          {formReportdata &&
                          formReportdata.estimate &&
                          formReportdata.estimate.revenue ? (
                            <td className="text-right">
                              {formReportdata.cargos
                                ? formReportdata.cargos.frat_tax
                                : "N/A"}
                            </td>
                          ) : (
                            undefined
                          )}
                        </tr>

                        <tr>
                          <td className="font-weight-bold">Less Despatch :</td>
                          {formReportdata &&
                          formReportdata.estimate &&
                          formReportdata.estimate.revenue &&
                          formReportdata.estimate.revenue ? (
                            <td className="text-right">
                              {formReportdata.estimate.revenue.dem_disch
                                ? formReportdata.estimate.revenue.dem_disch
                                : ""}
                            </td>
                          ) : (
                            undefined
                          )}
                        </tr>

                        <tr>
                          <td className="font-weight-bold">Misc Income :</td>
                          {/* {formReportdata.estimate.revenue && formReportdata.estimate.revenue ? ( 
                          <td className="text-right">
                            {formReportdata.mis_cost}
                          </td>
                          {/* ):undefined

                      } 
                        </tr>

                        <tr>
                          <td className="font-weight-bold">Net Income :</td>
                          {/* {formReportdata.estimate.revenue && formReportdata.estimate.revenue ? (
                          <td className="text-right">
                            {formReportdata.n_income}
                          </td>
                          {/* ):undefined

                      } 
                        </tr>
                      </tbody>
                    </table>

                    <div className="estimate">
                      <table className="table border-table  mt-2 pltable">
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">
                              Vessel Expenses :
                            </td>

                            {formReportdata &&
                            formReportdata.estimate &&
                            formReportdata.estimate.revenue &&
                            formReportdata.estimate.revenue ? (
                              <td className="text-right">
                                {
                                  formReportdata.estimate.expense
                                    .vessel_expenxe
                                }
                              </td>
                            ) : (
                              undefined
                            )}
                          </tr>

                          <tr>
                            <td className="font-weight-bold">Less Address :</td>

                            {formReportdata &&
                            formReportdata.estimate &&
                            formReportdata.estimate.expense &&
                            formReportdata.estimate.expense ? (
                              <td className="text-right">
                                {
                                  formReportdata.estimate.expense
                                    .less_address
                                }
                              </td>
                            ) : (
                              undefined
                            )}
                          </tr>

                          <tr>
                            <td className="font-weight-bold">
                              Canel Expenses :
                            </td>

                            {formReportdata &&
                            formReportdata.estimate &&
                            formReportdata.estimate.expense &&
                            formReportdata.estimate.expense ? (
                              <td className="text-right">
                                {
                                  formReportdata.estimate.expense
                                    .cancel_expense
                                }
                              </td>
                            ) : (
                              undefined
                            )}
                          </tr>

                          <tr>
                            <td className="font-weight-bold">
                              Port Expenses :
                            </td>
                            {formReportdata &&
                            formReportdata.estimate &&
                            formReportdata.estimate.expense &&
                            formReportdata.estimate.expense.portexpenses ? (
                              <td className="text-right">
                                {
                                  formReportdata.estimate.expense
                                    .portexpenses
                                }
                              </td>
                            ) : (
                              undefined
                            )}
                          </tr>

                          <tr>
                            <td className="font-weight-bold">
                              Bunker Expenses :
                            </td>

                            <td className="text-right">
                              {
                                formReportdata["estimate"]["expense"][
                                  "totalSeaConsumption_totalPortConsumption"
                                ]
                              }
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">
                              Misc Expenses :
                            </td>
                            {formReportdata &&
                            formReportdata.estimate &&
                            formReportdata.estimate.expense &&
                            formReportdata.estimate.expense ? (
                              <td className="text-right">
                                {formReportdata.estimate.expense.mis_cost}
                              </td>
                            ) : (
                              undefined
                            )}
                          </tr>

                          <tr>
                            <td className="font-weight-bold">Less Rebills :</td>
                            <td className="text-right">{formReportdata.reb}</td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">
                              Total Expenses :
                            </td>

                            {formReportdata &&
                            formReportdata.estimate &&
                            formReportdata.estimate.expense &&
                            formReportdata.estimate.expense ? (
                              <td className="text-right">
                                {
                                  formReportdata.estimate.expense
                                    .totalExpenses
                                }
                              </td>
                            ) : (
                              undefined
                            )}
                          </tr>
                        </tbody>
                      </table>
                      <table className="table border-table  mt-2 pltable">
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">Profit :</td>
                            <td className="text-right">
                              {formReportdata && formReportdata.estimate
                                ? formReportdata.estimate.totalvoyage
                                    .total_profit
                                : ""}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">Daily Profit :</td>
                            <td className="text-right">
                              {formReportdata.daily_profit}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">Breakeven :</td>
                            <td className="text-right">
                              {formReportdata.breakeven}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">
                              Freight Rate(USD/Mt) :
                            </td>
                            <td className="text-right">
                              {formReportdata &&
                              formReportdata.estimate &&
                              formReportdata.estimate.revenue
                                ? formReportdata.estimate.revenue.extrarevs
                                : ""}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">
                              TC Equiv(USD/Day) :
                            </td>
                            <td className="text-right">
                              {formReportdata.tco_equi}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">Gross TCE :</td>
                            <td className="text-right">
                              {formReportdata && formReportdata.estimate
                                ? formReportdata.estimate.totalvoyage
                                    .gross_revenue
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table border-table  mt-2 pltable">
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">Commenced On :</td>
                            <td className="text-right">
                              {formReportdata.commence_date}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">Complete On :</td>
                            <td className="text-right">
                              {formReportdata.completing_date}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-weight-bold">Voyage Days :</td>
                            <td className="text-right">
                              {formReportdata &&
                              formReportdata.estimate &&
                              formReportdata.estimate.totalvoyage
                                ? formReportdata.estimate.totalvoyage
                                    .totalVoyageDays
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
              </td>
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
});

const TcovEstimateDetailReport = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mailTitlePayload, setMailTitlePayload] = useState({});

  const componentRef = useRef(null);

  useEffect(() => {
    setUserInput(props.data);
  }, []);

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      // Use the blob as needed (e.g., send it to the server, create a download link, etc.)
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // this.setState({ loading: false });
      // Handle errors here
    }
  };

  const printDocument = () => {
    const quotes = document.getElementById("divToPrint");
    // alert("tco");
    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      // const link = document.createElement("a");
      // link.download = "html-to-img.png";
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm");
      let position = 8;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      const imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 10,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("TCOVEstimateDetails.pdf");
    });
  };

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection" />
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li onClick={sendEmailReportModal}>Send Email</li>
                    <li onClick={printDocument}>Download</li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined />
                            Print
                          </span>
                        )}
                        content={() => componentRef.current}
                        onBeforeGetContent={handleBeforePrint}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint ref={componentRef} data={props.data} />
          </div>
        </div>
      </article>

      {emailModal && (
        <Modal
          title="New Message"
          open={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              title={window.corrector(
                `TCOV Estimate Detail Report  ${userInput.estimate_id} ||${userInput.vessel_name}||${userInput.charterer_name}||${userInput.company_lob_name}||${userInput.tco_status_name}`
              )}
            />
          )}
        </Modal>
      )}

      {loading && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default TcovEstimateDetailReport;
