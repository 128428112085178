import React, { lazy, Suspense } from "react";
import { useLocation, useNavigate, useParams, Route } from "react-router-dom";
import loadable from "react-loadable";
import LoadingComponent from "../../../components/Loading";
import RightBARCONFIGURATION from "../../../constants/rightbar-configuration.js";
import { Button , Layout} from "antd";
//import MapIntellegence from "../../../routes/port-to-port/MapIntellegence";
import "../../../styles/antd.less";
import "../../../styles/bootstrap/bootstrap.scss";
// custom
import "../../../styles/layout.scss";
import "../../../styles/theme.scss";
import "../../../styles/ui.scss";
//import "../../../styles/vendors.scss"
import "../../../styles/custom.scss";
import "../../../styles/context-menu.scss";
import { TourProvider } from "@reactour/tour";
import { components } from "@reactour/tour";
import {
  tcovSteps,
  tctoSteps,
  afterLoginSteps,
} from "../../../routes/chartering/routes/tcov/Steps.js";
import RippleComponent from "../../../routes/NotFoundPage/index.js";
import SentList from "../../../routes/mail-folder/SentList.js";
import DraftList from "../../../routes/mail-folder/DraftList.js";
import ImportantList from "../../../routes/mail-folder/ImportantList.js";
import SpamList from "../../../routes/mail-folder/SpamList.js";
import ArchivedList from "../../../routes/mail-folder/ArchivedList.js";
import BinList from "../../../routes/mail-folder/BinList.js";
import DashboardNotification from "../../../routes/mail-folder/DashboardNotification.js";
import MailDashboard from "../../../routes/mail-folder/MailDashboard.js";
import AllTonnageList from "../../../routes/mail-folder/AllTonnageList.js";
import AllCargoList from "../../../routes/mail-folder/AllCargoList.js";
import GlobalTonnageOrder from "../../../routes/mail-folder/GlobalTonnageOrder.js";
import GlobalCargoOrder from "../../../routes/mail-folder/GlobalCargoOrder.js";
import LabelList from "../../../routes/mail-folder/LabelList.jsx";
import FolderList from "../../../routes/mail-folder/FolderList.jsx";
import EmailTemplate from "../../email-templates";
const { Content } = Layout;

class AppContent extends React.Component {
  render() {
    const { match } = this.props;
    return <>hh</>;
  }
}
const withLocation = (Component) => (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  return <Component {...props} router={{ location, navigate, params }} />;
};

export default withLocation(AppContent);

// Start setup menu content according to requirement - 22/03/2021 - 23/03/2021

let AsyncChartering = lazy(() => import("../../../routes/chartering"));

let AsyncCargoList = lazy(() => import("../../../routes/cargo-list/"));

let AsyncEstimates = lazy(() => import("../../../routes/estimates/"));

let AsyncFixtureList = lazy(() => import("../../../routes/fixture-list/"));

let AsyncCargoSchedule = lazy(() => import("../../../routes/cargo-schedule/"));

//let AsyncMatching = lazy(() => import('../../../routes/matching/'))

let AsyncScheduling = lazy(() => import("../../../routes/scheduling/"));

let AsyncTcInList = lazy(() => import("../../../routes/tc-in-list/"));

let AsyncOpenPositions = lazy(() => import("../../../routes/open-positions/"));

let AsyncCargoBook = lazy(() => import("../../../routes/cargo-book/"));

let AsyncOperationFreight = lazy(() =>
  import("../../../routes/operation/freight/")
);

let AsyncOperationDemurrage = lazy(() =>
  import("../../../routes/operation/demurrage/")
);

let AsyncOperationTimeChartererIn = lazy(() =>
  import("../../../routes/operation/time-charterer-in/")
);

let AsyncOperationTimeChartererOut = lazy(() =>
  import("../../../routes/operation/time-charterer-out/")
);

let AsyncOperationBunkers = lazy(() =>
  import("../../../routes/operation/bunkers/")
);

let AsyncOperationMyPortcalls = lazy(() =>
  import("../../../routes/operation/my-portcalls/")
);

let AsyncOperationVSOP = lazy(() => import("../../../routes/operation/vsop/"));

let AsyncOperationPerformanceReport = lazy(() =>
  import("../../../routes/operation/performance-report/")
);

let AsyncOperationSetup = lazy(() =>
  import("../../../routes/operation/setup/")
);

let AsyncDataCenter = lazy(() => import("../../../routes/data-center/"));

let AsyncVesselForm = lazy(() => import("../../../components/vessel-form/"));

let AsyncVoyage = lazy(() => import("../../../routes/voyage-list/"));

let AsyncVoyageOperation = lazy(() =>
  import("../../../routes/voyage-list/components/VoyageOperation")
);

let AsyncMastersList = lazy(() => import("../../../routes/masters/"));

let AsyncVesselList = lazy(() =>
  import("../../../routes/vessel-list/index.js")
);

let AsyncAddressList = lazy(() => import("../../../routes/address-list/"));

let AsyncRemittenceList = lazy(() =>
  import("../../../routes/agent-login-panel/RemittanceBankList")
);

let AsyncAddAddressList = lazy(() =>
  import("../../../components/AddAddressBook")
);

let PortForm = lazy(() => import("../../../components/PortForm"));

let AsyncRevenueExpenses = lazy(() =>
  import("../../../routes/operation/revenue-expenses/")
);

let AsyncVesselDataReportingSystem = lazy(() =>
  import("../../../routes/vessel-data-reporting-system/")
);

let AsyncVesselSpeedPredictiveMatrix = lazy(() =>
  import("../../../routes/vessel-speed-predictive-matrix/")
);

let AsyncTransaction = lazy(() => import("../../../routes/transactions/"));

let AsyncCargoName = lazy(() => import("../../../routes/cargo-name"));

let AsyncAddCargoName = lazy(() =>
  import("../../../routes/cargo-name/add-cargo-name")
);

let AsyncAccountSummary = lazy(() =>
  import("../../../routes/account-summary/")
);

let AsyncPL = lazy(() => import("../../../routes/pl/"));

let AsyncPopup = lazy(() => import("../../../routes/popup/"));

let AsyncVoyageManager = lazy(() => import("../../../routes/voyage-manager/"));

let AsyncTcov = lazy(() => import("../../../routes/chartering/routes/tcov"));

let AsyncGenerateCargoEnquiry = lazy(() =>
  import(
    "../../../routes/chartering/routes/tcov/modals/GenerateCargoEnquiry.js"
  )
);
let AsyncTcto = lazy(() => import("../../../routes/chartering/routes/tcto"));

let AsyncTco = lazy(() => import("../../../routes/chartering/routes/tco"));

let AsyncVCIVCO = lazy(() =>
  import("../../../routes/chartering/routes/vci-vco-est")
);

let AsyncCargo = lazy(() =>
  import("../../../routes/chartering/routes/voyage-cargo-in")
);

let AsyncCargoContract = lazy(() =>
  import("../../../routes/chartering/routes/cargo-contract")
);

let AsyncAddTci = lazy(() => import("../../../routes/chartering/routes/tci"));

let AsyncCoaVci = lazy(() =>
  import("../../../routes/chartering/routes/coa-vci")
);

let AsyncTCOVList = lazy(() => import("../../../routes/tcov-list/"));

let AsynvCiiDynamics = lazy(() =>
  import("../../../routes/dynamic-vspm/vesselCi/index.js")
);

let AsyncPortsList = lazy(() => import("../../../routes/ports-list/"));

let AsyncCountriesList = lazy(() =>
  import("../../../routes/countries-list/index.js")
);

let AsyncCOASList = lazy(() => import("../../../routes/coas-list/"));

let AsyncTCTOList = lazy(() => import("../../../routes/tcto-list/"));

let AsyncTCTOFULL = lazy(() =>
  import("../../../routes/chartering/routes/tcto/FullEstimate")
);

let AsyncVoyageFixList = lazy(() => import("../../../routes/voyage-fix-list/"));

let AsyncVoyageManagerList = lazy(() =>
  import("../../../routes/voyage-manager-list/")
);

let AsyncVoyageCargoList = lazy(() =>
  import("../../../routes/voyage-cargo-list/")
);

let AsyncTCOManagerList = lazy(() =>
  import("../../../routes/tco-manager-list")
);

let AsyncCOAContract = lazy(() => import("../../../routes/coa-contract/"));

let AsyncVoyageFixtureForm = lazy(() =>
  import("../../../routes/voyage-fixture-form/")
);

let AsyncOperationPositionReport = lazy(() =>
  import("../../../routes/operation/position-report/")
);

let AsyncPortActivityDetails = lazy(() =>
  import("../../../routes/port-activity-details/")
);

let AsyncListCoaVci = lazy(() => import("../../../routes/list-coa-vci/"));

let AsyncCpDesk = lazy(() => import("../../../routes/cpdesk/Cpdesk.js"));

let AsyncPort = lazy(() => import("../../../routes/port/"));

let AsyncPortInformation = lazy(() =>
  import("../../../routes/port-information/")
);

let AsyncMyportcalldashboard = lazy(() =>
  import("../../../routes/operation/my-portcalls/my-portcall-dashboard")
);

let AsyncMyportcallList = lazy(() =>
  import(
    "../../../routes/operation/my-portcalls/my-portcall-dashboard/PortcallList"
  )
);

let AsyncVoyageBunkerPlan = lazy(() =>
  import("../../../routes/voyage-bunker-plan")
);

let AsyncCargoEnquiryTemplates = lazy(() =>
  import("../../../routes/enquire-templates/CargoEnquiryTemplates.js")
);

let AsyncCargoEnquirySendTemplates = lazy(() =>
  import("../../../routes/enquire-templates/CargoEnquirySendList.js")
);

let AsyncTonnageEnquireTemplates = lazy(() =>
  import("../../../routes/enquire-templates/TonnageEnquiryTemplates.js")
);

let AsyncTonnageEnquireSendTemplates = lazy(() =>
  import("../../../routes/enquire-templates/TonnageEnquirySendList.js")
);

let AsyncBusinessRuleAccounting = lazy(() =>
  import("../../../routes/business-rule-accounting")
);

let AsyncBankNames = lazy(() => import("../../../routes/bank-names"));

let AsyncNewLaytime = lazy(() =>
  import("../../../routes/voyage-manager/NewLaytime")
);

let AsyncPortExpense = lazy(() => import("../../../routes/port-expense/"));

let AsyncLaytimeSummary = lazy(() =>
  import("../../../routes/voyage-manager/LaytimeSummary")
);

let AsyncBunkerRequirement = lazy(() =>
  import("../../../routes/operation/bunkers/bunker-requirements")
);

let AsyncBunkerPurchasedOrder = lazy(() =>
  import("../../../routes/operation/bunkers/bunker-purchased-order")
);

let AsyncBunkerInvoice = lazy(() =>
  import("../../../routes/operation/bunkers/bunker-invoice")
);

let AsyncInstructionSet = lazy(() =>
  import("../../../routes/operation/setting/InstructionSet")
);

let AsyncBunkerInvoiceListing = lazy(() =>
  import(
    "../../../routes/operation/bunkers/bunker-listing/BunkerInvoiceListing"
  )
);

let AsyncBunkerRequirementListing = lazy(() =>
  import(
    "../../../routes/operation/bunkers/bunker-listing/BunkerRequirementListing"
  )
);

let AsyncBunkerPurchaseListing = lazy(() =>
  import(
    "../../../routes/operation/bunkers/bunker-listing/BunkerPurchaseListing"
  )
);

let AsyncDynamicVspm = lazy(() =>
  import("../../../routes/dynamic-vspm/DynamicVspm")
);

let AsyncTrackNoon = lazy(() =>
  import("../../../routes/dynamic-vspm/vesselDetails/index.js")
);

let AsyncVoyageOptimization = lazy(() =>
  import("../../../routes/voyage-optimization/VoyageOptimization")
);

let AsyncVesselAcivateList = lazy(() =>
  import("../../../routes/vessel-activate-list/VesselActivateList")
);

let AsyncReportingForm = lazy(() =>
  import("../../../routes/reporting-form/ReportingForm")
);

let AsyncOverallPerformanceAnalysis = lazy(() =>
  import(
    "../../../routes/overall-performance-analysis/OverallPerformanceAnalysis"
  )
);

// let AsyncFuelConsAnalysisVessel = loadable({
//   loader: () => import('../../../routes/fuel-analysis-vessel/FuelConsAnalysisVessel'),
//   loading: LoadingComponent,
// });
let AsyncVesselHistoricalData = lazy(() =>
  import("../../../routes/vessel-historical-data/VesselHistoricalData")
);

let AsyncCashflowReport = lazy(() =>
  import("../../../routes/cashflow-report/index.js")
);

let AsyncVendorTransactionReport = lazy(() =>
  import("../../../routes/vendor-transaction-report/index.js")
);

let AsyncTrialBalanceReport = lazy(() =>
  import("../../../routes/trial-balance-report/index.js")
);

let AsyncAccountPaybleReport = lazy(() =>
  import("../../../routes/account-payable-report/index.js")
);

let AsyncAccountReceivableReport = lazy(() =>
  import("../../../routes/account-receivable-report/index.js")
);

let AsyncVoyageReport = lazy(() =>
  import("../../../routes/voyage-report/index.js")
);

let AsyncNoonVerification = lazy(() =>
  import("../../../routes/noon-verification/NoonVerification")
);

let AsyncMasterNoonVerification = lazy(() =>
  import("../../../routes/noon-verification/MasterNoonVerification")
);

let AsyncActivateVoyageListed = lazy(() =>
  import("../../../routes/noon-verification/ActivateVoyageListed")
);

let AsyncArrivaleasp = lazy(() =>
  import("../../../routes/reporting-form/components/Arrival")
);

let AsyncBunkerHandling = lazy(() =>
  import("../../../routes/reporting-form/components/BunkerHandling")
);

let AsyncDelayStopatSea = lazy(() =>
  import("../../../routes/reporting-form/components/DelayStopatSea")
);

let AsyncSof = lazy(() =>
  import("../../../routes/reporting-form/components/Sof")
);

let AsyncInPortCargoHandling = lazy(() =>
  import("../../../routes/reporting-form/components/InPortCargoHandling")
);

let AsyncNoonReport = lazy(() =>
  import("../../../routes/reporting-form/components/NoonReport")
);

let AsyncVesselSchedule = lazy(() =>
  import("../../../routes/vessel-schedule/VesselSchedule")
);

let AsyncPortSchedule = lazy(() =>
  import("../../../routes/port-shedule/PortShedule")
);

let AsyncMakePayment = lazy(() =>
  import("../../../routes/chartering/routes/make-payment/MakePayment")
);

// let AsyncCharteringDashboard = lazy(() =>
//   import("../../../routes/chartring-dashboard/")
// );
let AsyncCharteringDashboard = lazy(() =>
  import("../../../routes/chartring-dashboard/Chatring-Dashboard-List.js")
);

let AsyncCargoAnalyticDashboard = lazy(() =>
  import("../../../routes/cargo-dashboard/cargo-dashboard.js")
);
let AsyncBunkerAnalyticDashboard = lazy(() =>
  import("../../../routes/bunker-dashboard/Bunker-Dashboard.js")
);
let AsyncCharteringDashboardlist = lazy(() =>
  import("../../../routes/chartring-dashboard/Chatring-Dashboard-List.js")
);

let AsyncUserDashboard = lazy(() => import("../../../routes/UserDashboard/"));

let AsyncServiceDesk = lazy(() => import("../../../routes/service-desk/"));

let AsyncTags = lazy(() => import("../../../routes/tag-list/index.js"));

let AsyncUserList = lazy(() =>
  import("../../../routes/UserDashboard/Userlist")
);

// let AsyncNoonReport = lazy(() =>{
//   import
// })

let AsyncGroupList = lazy(() =>
  import("../../../routes/UserDashboard/Grouplist")
);

let AsyncAccessRight = lazy(() =>
  import("../../../routes/UserDashboard/AccessRight")
);

let AsyncSubscriptionList = lazy(() =>
  import("../../../routes/UserDashboard/Subscriptionlist")
);

let AsyncOrganisation = lazy(() =>
  import("../../../routes/UserDashboard/Organisation")
);

let AsyncVesselOpenSchedule = lazy(() =>
  import("../../../routes/vessel-open-schedule/VesselOpenSchedule")
);

let AsyncPortCongestion = lazy(() => import("../../../routes/port-congestion"));

let AsyncCargoTermDetails = lazy(() =>
  import("../../../routes/chartering/routes/cargo/cargo-child")
);

let AsyncInitialFreightInvoiceSummary = lazy(() =>
  import(
    "../../../routes/operation/freight/initial-freight-invoice/components/InitialFreightInvoiceSummary"
  )
);

let AsyncFreightInvoice = lazy(() =>
  import(
    "../../../routes/operation/freight/initial-freight-invoice/components/InitialFreightInvoice"
  )
);

let AsynFreightCommission = lazy(() =>
  import(
    "../../../routes/operation/freight/commission-invoice/components/CommissionInvoice"
  )
);

let AsynCommissionSummary = lazy(() =>
  import(
    "../../../routes/operation/freight/commission-invoice/components/CommissionInvoiceSummary"
  )
);

let AsynOtherExpense = lazy(() =>
  import(
    "../../../routes/operation/revenue-expenses/components/RevenueExpenses"
  )
);

let AsynOtherRevenue = lazy(() =>
  import(
    "../../../routes/operation/revenue-expenses/components/RevenueExpenses"
  )
);

let AsynOtherInvoice = lazy(() =>
  import(
    "../../../routes/operation/revenue-expenses/components/OtherExpenseModal"
  )
);

let AsynVoyageEfficiency = lazy(() =>
  import(
    "../../../routes/chartering/routes/beta_voyage_efficiency/VoyageEfficiencyList.js"
  )
);

let AsynOtherExpenseList = lazy(() =>
  import(
    "../../../routes/operation/revenue-expenses/components/OtherExpenseList"
  )
);

let AsynOtherRevenueList = lazy(() =>
  import(
    "../../../routes/operation/revenue-expenses/components/OtherRevenueList"
  )
);

let AsynHireScheduleList = lazy(() =>
  import("../../../routes/hire-schedule/HireScheduleList")
);

let AsynHireSchedule = lazy(() =>
  import("../../../routes/hire-schedule/HireSchedule")
);

let AsynHireIssueBillList = lazy(() =>
  import("../../../routes/hire-schedule/right-panel/HireIssueBillList")
);

let AsynHireIssueBillTrxnList = lazy(() =>
  import("../../../routes/hire-trxn/right-panel/HireIssueBillTrxnList.js")
);
let AsynHirePaymentTrxnList = lazy(() =>
  import("../../../routes/hire-trxn/right-panel/HirePaymentTrxnList.js")
);

let AsynHirePaymentList = lazy(() =>
  import("../../../routes/hire-schedule/right-panel/HirePaymentList")
);

let AsynAnalyticalDashboard = lazy(() =>
  import("../../../routes/AnalyticalDashboard/AnalyticalDashboard")
);

let AsynPdaList = lazy(() => import("../../../routes/pda-list/PdaList"));

let AsynFdaList = lazy(() => import("../../../routes/fda-list/FdaList"));

let AsynVoyageReletFull = lazy(() =>
  import(
    "../../../routes/chartering/routes/voy-relet/fullestimate/VoyageReletFullEstimate.js"
  )
);

let AsynVoyReletList = lazy(() =>
  import("../../../routes/voy-relet-list/VoyReletList.js")
);

let AsyncReportDesign = lazy(() =>
  import("../../../routes/report-design/index.js")
);

let AsynPortcallDetails = lazy(() =>
  import("../../../routes/portcall-detail/PortcallDetails")
);

let AsynTransactionSummary = lazy(() =>
  import("../../../routes/transaction-summary/TransactionSummary")
);

let AsynVendorTransaction = lazy(() =>
  import("../../../routes/vendor-transaction/VendorTransaction")
);

let AsynVerifiedInvoice = lazy(() =>
  import("../../../routes/verified-invoice/VerifiedInvoice")
);

let AsynDeleteInvoice = lazy(() =>
  import("../../../routes/delete-invoice/DeleteInvoice")
);

let AsynApprovedInvoice = lazy(() =>
  import("../../../routes/approved-invoice/ApprovedInvoice")
);

let AsynPreparedInvoice = lazy(() =>
  import("../../../routes/prepared-invoice/PreparedInvoice")
);

let AsynPostedInvoice = lazy(() =>
  import("../../../routes/posted-invoice/PostedInvoice")
);

let AsynReportList = lazy(() =>
  import("../../../routes/dynamic-vspm/ReportList.js")
);

let AsynBunkerVendorTransactionList = lazy(() =>
  import(
    "../../../routes/bunker-vendor-transaction-list/BunkerVendorTransactionList"
  )
);

let AsynAgentTransactionList = lazy(() =>
  import("../../../routes/agent-transaction-list/AgentTransactionList")
);

let AsynOtherVendorTransactionList = lazy(() =>
  import(
    "../../../routes/other-vendor-transaction-list/OtherVendorTransactionList"
  )
);

let AsynAgentDashboard = lazy(() =>
  import("../../../routes/agent-login-panel/AgentDashboard")
);

let AsynCompanyRegistration = lazy(() =>
  import("../../../routes/agent-login-panel/CompanyRegistration")
);

let AsynBankAccount = lazy(() =>
  import("../../../routes/agent-login-panel/BankAccount")
);

let AsynUserPassword = lazy(() =>
  import("../../../routes/agent-login-panel/UserPassword")
);

let AsynArchivesList = lazy(() =>
  import("../../../routes/agent-login-panel/ArchivesList")
);

let AsynAgentList = lazy(() =>
  import("../../../routes/agent-login-panel/AgentList")
);

let AsynFinanceDashboard = lazy(() =>
  import("../../../routes/finance-dashboard/FinanceDashboard")
);
let AsynFinanceDashboardlist = lazy(() =>
  import("../../../routes/finance-dashboard")
);
let AsyncRiskManagement = lazy(() =>
  import("../../../routes/risk-management/index.js")
);
let AsynQuickTcovList = lazy(() =>
  import("../../../routes/estimatequick/List")
);

let AsynNotification = lazy(() =>
  import("../../../routes/notification/Notification")
);

let AsynClaimInvoice = lazy(() =>
  import("../../../routes/claim-invoice/ClaimInvoice")
);

let AsynClaimListing = lazy(() =>
  import("../../../routes/claim-invoice/ClaimListing")
);

let AsynLayTimeInvoiceListing = lazy(() =>
  import("../../../routes/voyage-manager/NewLaytime")
);

let AsynLayTimeListing = lazy(() =>
  import("../../../routes/voyage-manager/LaytimeListing")
);

let AsynDisputeClaimListing = lazy(() =>
  import("../../../routes/claim-invoice/ClaimDisputeListing")
);

let AsynPortSelection = lazy(() =>
  import("../../../routes/port-selection/PortSelection")
);

let AsynSuperPanel = lazy(() =>
  import("../../../routes/super-panel/SuperPanel")
);

let AsynMySubscriber = lazy(() =>
  import("../../../routes/super-panel/MySubscriber")
);

let AsynProfile = lazy(() => import("../../../routes/profile/Profile"));
let AsynFolder = lazy(() => import("../../../routes/folders/Folder"));

let AsyncPortInformationList = lazy(() =>
  import("../../../routes/port-information/PortInformationList")
);

let AsyncAdvancePaymentReceiptList = lazy(() =>
  import("../../../routes/advancepayment/AdvancePaymentReceiptList.js")
);

let AsyncVoyageTaskAlert = lazy(() =>
  import("../../../routes/voyage-task-alert/VoyageTaskAlert")
);

let AsyncTcCommissionInvoiceList = lazy(() =>
  import("../../../routes/tc-commission-invoice-list/TcCommissionInvoiceList")
);

let AsyncAddressCommissionInvoiceList = lazy(() =>
  import(
    "../../../routes/address-commision-invoice-list/AddressCommisionInvoicelist"
  )
);

let AsyncReportAnalytics = lazy(() =>
  import("../../../routes/report-analytics/ReportAnalytics")
);

// let AsynPortToPortDistanse = lazy(() =>
//   import("../../../routes/port-to-port/PortAnalytics")
// );
let AsyncMapIntellegence = lazy(() =>
  import("../../../routes/port-to-port/MapIntellegence")
);

let AsynSpotPrice = lazy(() =>
  import("../../../routes/port-to-port/SpotPrice")
);

let AsynInvoice = lazy(() => import("../../../routes/invocies/invoices.js"));

let AsynPortCost = lazy(() => import("../../../routes/port-cost/"));

let AsynPortData = lazy(() => import("../../../routes/port-data"));

let AsynVesselData = lazy(() => import("../../../routes/vessel-data"));

let AsynTrackVessel = lazy(() => import("../../../routes/track-vessel"));

let AsynShowingAllVesselDetails = lazy(() =>
  import("../../../routes/track-vessel/showing-all-vessel-details")
);

let AsynDocshare = lazy(() => import("../../../routes/doc-share"));

let AsynTrackMyFleet = lazy(() => import("../../../routes/track-my-fleet"));

let AsynCargoTonnage = lazy(() => import("../../../routes/cargo-tonnage"));

let AsynFleetPerformance = lazy(() =>
  import("../../../routes/fleet-performance")
);

// let AsynCiiDashboard = lazy(() => import("../../../routes/cii-dashboard"));
let AsyncPerformanceDashboard = lazy(() =>
  import("../../../routes/performancedashboard/index.js")
);
let AsyncPortPerformance = lazy(() =>
  import(
    "../../../routes/dashboard/portperformancedashboard/PortPerformance.js"
  )
);

let AsynCii = lazy(() =>
  import("../../../routes/dynamic-vspm/vesselCi/index.js")
);
let AsynOceannAI = lazy(() => import("../../../routes/oceannAI/index.js"));

let AsyncSmartmail = lazy(() => import("../../../routes/smartMail"));

let AsynOpenVessel = lazy(() => import("../../../routes/openVessel"));

let AsyncbunkeringPerformance = lazy(() =>
  import(
    "../../../routes/operationsMenu/bunkeringPerformance/BunkeringPerformanceReport"
  )
);

let AsyncbunkeringtimePerformance = lazy(() =>
  import(
    "../../../routes/operationsMenu/bunkeringtimePerformance/BunkeringTimePerformance"
  )
);

let AsyncvoyageOperationPerformance = lazy(() =>
  import(
    "../../../routes/operationsMenu/voyageOperationPerformance/VoyageOperationPerformance"
  )
);

let AsynccargoPerformance = lazy(() =>
  import("../../../routes/operationsMenu/cargoPerformance/CargoPerformance")
);

let AsyncpoolingdistributionList = lazy(() =>
  import(
    "../../../routes/operationsMenu/poolingDistribution-list/PoolingdistributionList.js"
  )
);

let AsyncAccessControl = lazy(() =>
  import("../../../routes/access-control/AccessControl.js")
);

let AsyncOffHireDeviation = lazy(() =>
  import("../../../routes/offhire-deviation/OffHireDeviation.js")
);

let AsyncOffHireDeviationList = lazy(() =>
  import("../../../routes/offhire-deviation/OffHireDeviationList.js")
);

let AsyncNotificationsDetail = lazy(() =>
  import("../../../routes/layout/routes/header/components/NotificationsDetail")
);

let AsyncVesselFile = lazy(() =>
  import("../../../routes/vesselfile/VesselFile.js")
);

let AsyncVesselFileList = lazy(() =>
  import("../../../routes/vesselfile/VesselFileList")
);

let AsyncVoyageEstimateQuick = lazy(() =>
  import("../../../routes/estimatequick/VoyageEstimateQuick.js")
);
let AsyncTCEstimateQuick = lazy(() =>
  import("../../../routes/estimatequick/TCEstimateQuick.js")
);
let AsyncVoyageReletQuick = lazy(() =>
  import("../../../routes/estimatequick/VoyageReletQuick.js")
);

let AsyncAccountLedger = lazy(() =>
  import("../../../routes/accounting-terms/AccountLedger.js")
);
let AsyncBusinessRule = lazy(() =>
  import("../../../routes/accounting-terms//BusinessRule.js")
);
let AsyncBusinessRuleList = lazy(() =>
  import("../../../routes/accounting-terms/BusinessRuleList.js")
);
let AsyncAccountPeriod = lazy(() =>
  import("../../../routes/accounting-terms/AccountPeriod.js")
);

let CalendarView = lazy(() => import("../../../routes/CalendarView/index.js"));

let AsynAdvancePayment = lazy(() =>
  import("../../../routes/advancepayment/AdvancePayment.js")
);

let AsynAdvancePaymentList = lazy(() =>
  import("../../../routes/advancepayment/AdvancePaymentList.js")
);

let AsynAdvancePaymentReceipt = lazy(() =>
  import("../../../routes/advancepayment/AdvancePaymentReceipt.js")
);
let VoyageRejectedList = lazy(() =>
  import("../../../routes/noon-verification/VoyageRejectedList.js")
);
let MakePayment = lazy(() =>
  import("../../../components/MakePayment/index.js")
);
let TCOMakePayment = lazy(() =>
  import("../../../components/MakePayment/TcoMakepayment.js")
);

let MailLayout = lazy(() =>
  import("../../../routes/mail-folder/MailLayout.js")
);
let GroupMailLayout = lazy(() =>
  import("../../../routes/MailGroup/GroupMailLayout.js")
);
let MailGroup = lazy(() =>
  import("../../../routes/MailGroup/MailGroups.js")
);
const AsyncInbox=lazy(()=>import('../../../routes/inbox/Inbox.jsx'))



const Arrow = ({ children, style }) => (
  <div style={{ ...style, position: "relative" }}>
    <div className="arrow" />
    <div>{children}</div>
  </div>
);

// End setup menu content according to requirement - 22/03/2021 - 23/03/2021

export const AppContentRoute = [
  // Profile Setup
  // {
  //   path: "cii-dashboard",
  //   element: (
  //     <Suspense fallback={<LoadingComponent />}>
  //       <AsynCiiDashboard />
  //     </Suspense>
  //   ),
  // },
  // User Profile
  {
    path: "docshare",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynDocshare />
      </Suspense>
    ),
  },
  {
    path: "inbox",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncInbox />
      </Suspense>
    ),
  },
  {
    path: "make-payment",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <MakePayment />
      </Suspense>
    ),
  },
  {
    path: "make-payment/:id/",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <MakePayment />
      </Suspense>
    ),
  },
  {
    path: "issue-bill",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <TCOMakePayment />
      </Suspense>
    ),
  },
  {
    path: "issue-bill/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <TCOMakePayment />
      </Suspense>
    ),
  },
  {
    path: "track-my-fleet",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynTrackMyFleet />
      </Suspense>
    ),
  },
  // Company Profile
  {
    path: "cargo-tonnage",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynCargoTonnage />
      </Suspense>
    ),
  },
  // Chartering Menu
  {
    path: "fleet-performance",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynFleetPerformance />
      </Suspense>
    ),
  },

  {
    path: "open-vessel",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOpenVessel />
      </Suspense>
    ),
  },

  {
    path: "live-vessel",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynTrackVessel />
      </Suspense>
    ),
  },
  {
    path: "show-all-vessel-details",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynShowingAllVesselDetails />
      </Suspense>
    ),
  },

  {
    path: "vessel-data",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynVesselData />
      </Suspense>
    ),
  },

  {
    path: "advance-payment",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynAdvancePayment />
      </Suspense>
    ),
  },

  {
    path: "advance-payment-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynAdvancePaymentList />
      </Suspense>
    ),
  },
  {
    path: "advance-payment-receipt",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynAdvancePaymentReceipt />
      </Suspense>
    ),
  },

  {
    path: "port-data",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynPortData />
      </Suspense>
    ),
  },

  {
    path: "chartering",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncChartering />
      </Suspense>
    ),
  },

  {
    path: "cargo-contract-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoList />
      </Suspense>
    ),
  },

  {
    path: "estimates",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncEstimates />
      </Suspense>
    ),
  },

  {
    path: "quick-estimate",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageEstimateQuick />
      </Suspense>
    ),
  },

  {
    path: "edit-quick-estimate/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageEstimateQuick />
      </Suspense>
    ),
  },

  {
    path: "add-TC-estimate",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTCEstimateQuick />
      </Suspense>
    ),
  },

  {
    path: "edit-tc-estimate/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTCEstimateQuick />
      </Suspense>
    ),
  },

  {
    path: "voy-relet",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageReletQuick />
      </Suspense>
    ),
  },

  {
    path: "edit-voy-relet/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageReletQuick />
      </Suspense>
    ),
  },

  {
    path: "fixture-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncFixtureList />
      </Suspense>
    ),
  },

  {
    path: "cargo-schedule",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoSchedule />
      </Suspense>
    ),
  },
  // Chartering Dashboard
  // {
  //   path: "matching",
  //   element: <Suspense fallback={<LoadingComponent/>}><AsyncMatching /></Suspense>,
  // },

  {
    path: "scheduling",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncScheduling />
      </Suspense>
    ),
  },

  {
    path: "TC-IN-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTcInList />
      </Suspense>
    ),
  },

  {
    path: "open-positions",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOpenPositions />
      </Suspense>
    ),
  },
  // vessel file
  {
    path: "cargo-book",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoBook />
      </Suspense>
    ),
  },

  {
    path: "operation/freight",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationFreight />
      </Suspense>
    ),
  },

  {
    path: "performance-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPerformanceDashboard />
      </Suspense>
    ),
  },
  {
    path: "port-performance",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortPerformance />
      </Suspense>
    ),
  },

  {
    path: "operation/demurrage",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationDemurrage />
      </Suspense>
    ),
  },

  {
    path: "operation/time-charterer-in",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationTimeChartererIn />
      </Suspense>
    ),
  },

  {
    path: "operation/time-charterer-out",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationTimeChartererOut />
      </Suspense>
    ),
  },

  {
    path: "my-portcall",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMyportcalldashboard />
      </Suspense>
    ),
  },

  {
    path: "operation/bunkers",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationBunkers />
      </Suspense>
    ),
  },

  {
    path: "operation/my-portcalls",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationMyPortcalls />
      </Suspense>
    ),
  },

  {
    path: "operation/my-portcall/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMyportcalldashboard />
      </Suspense>
    ),
  },

  {
    path: "operation/vsop",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationVSOP />
      </Suspense>
    ),
  },

  {
    path: "operation/performance-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationPerformanceReport />
      </Suspense>
    ),
  },

  {
    path: "operation/setup",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationSetup />
      </Suspense>
    ),
  },

  {
    path: "vessel-form",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselForm />
      </Suspense>
    ),
  },

  {
    path: "vessel-form/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselForm />
      </Suspense>
    ),
  },

  {
    path: "voyage-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyage />
      </Suspense>
    ),
  },

  {
    path: "add-voyager-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageOperation />
      </Suspense>
    ),
  },

  {
    path: "vessel-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselList />
      </Suspense>
    ),
  },

  {
    path: "address-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddressList />
      </Suspense>
    ),
  },

  {
    path: "remittance-bank",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncRemittenceList />
      </Suspense>
    ),
  },

  {
    path: "company",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddressList />
      </Suspense>
    ),
  },

  {
    path: "vendor",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddressList />
      </Suspense>
    ),
  },

  {
    path: "add-address-form",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddAddressList />
      </Suspense>
    ),
  },

  {
    path: "add-company",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddAddressList />
      </Suspense>
    ),
  },

  {
    path: "add-vendor",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddAddressList />
      </Suspense>
    ),
  },

  {
    path: "operation/revenue-expenses",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncRevenueExpenses />
      </Suspense>
    ),
  },

  {
    path: "vessel-data-reporting-system",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselDataReportingSystem />
      </Suspense>
    ),
  },

  {
    path: "vessel-speed-predictive-matrix",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselSpeedPredictiveMatrix />
      </Suspense>
    ),
  },

  {
    path: "transactions",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTransaction />
      </Suspense>
    ),
  },

  {
    path: "port-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMastersList />
      </Suspense>
    ),
  },

  {
    path: "port-form",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <PortForm />
      </Suspense>
    ),
  },

  {
    path: "cargos",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoName />
      </Suspense>
    ),
  },

  {
    path: "add-cargo",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddCargoName />
      </Suspense>
    ),
  },

  {
    path: "country-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMastersList />
      </Suspense>
    ),
  },

  {
    path: "account-summary",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccountSummary />
      </Suspense>
    ),
  },

  {
    path: "pl",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPL />
      </Suspense>
    ),
  },

  {
    path: "popup-vessel-form",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPopup />
      </Suspense>
    ),
  },

  {
    path: "voyage-manager/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageManager />
      </Suspense>
    ),
  },

  {
    path: "voyage-manager",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageManager />
      </Suspense>
    ),
  },

  {
    path: "add-voyage-estimate",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTcov />
      </Suspense>
    ),
  },

  {
    path: "GenerateCargoEnquiry",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncGenerateCargoEnquiry />
      </Suspense>
    ),
  },

  {
    path: "edit-voyage-estimate/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTcov />
      </Suspense>
    ),
  },

  {
    path: "Voyage-Estimate-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTCOVList />
      </Suspense>
    ),
  },

  {
    path: "Cii-Dynamics",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynvCiiDynamics />
      </Suspense>
    ),
  },

  {
    path: "port-congestion",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortCongestion />
      </Suspense>
    ),
  },

  {
    path: "ports-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortsList />
      </Suspense>
    ),
  },

  {
    path: "countries-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCountriesList />
      </Suspense>
    ),
  },

  {
    path: "edit-ports-list/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTcov />
      </Suspense>
    ),
  },

  {
    path: "coa-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCOASList />
      </Suspense>
    ),
  },

  {
    path: "TC-Estimate-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTCTOList />
      </Suspense>
    ),
  },

  {
    path: "tc-est-fullestimate",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTCTOFULL />
      </Suspense>
    ),
  },

  {
    path: "edit-tc-est-fullestimate/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTCTOFULL />
      </Suspense>
    ),
  },

  {
    path: "add-vci-vco",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVCIVCO />
      </Suspense>
    ),
  },

  {
    path: "add-TC-IN",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddTci />
      </Suspense>
    ),
  },

  {
    path: "TC-IN/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddTci />
      </Suspense>
    ),
    errorElement: <RippleComponent />,
  },

  {
    path: "add-TC-Out",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTco />
      </Suspense>
    ),
  },

  {
    path: "TC-Out/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTco />
      </Suspense>
    ),
  },

  {
    path: "edit-tco/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTco />
      </Suspense>
    ),
  },

  {
    path: "add-cargo-contract",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoContract />
      </Suspense>
    ),
  },

  {
    path: "edit-cargo-contract/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoContract />
      </Suspense>
    ),
  },

  {
    path: "add-coa-vci",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCoaVci />
      </Suspense>
    ),
  },

  {
    path: "edit-coa-vci/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCoaVci />
      </Suspense>
    ),
  },

  {
    path: "add-voyage-cargo",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargo />
      </Suspense>
    ),
  },

  {
    path: "/edit-voyage-cargo/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargo />
      </Suspense>
    ),
  },
  {
    path: "voyage-fix-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageFixList />
      </Suspense>
    ),
  },

  {
    path: "voyage-manager-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageManagerList />
      </Suspense>
    ),
  },

  {
    path: "voyage-cargo-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageCargoList />
      </Suspense>
    ),
  },

  {
    path: "TC-OUT-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTCOManagerList />
      </Suspense>
    ),
  },

  {
    path: "add-coa-contract",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCOAContract />
      </Suspense>
    ),
  },

  {
    path: "edit-coa-contract/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCOAContract />
      </Suspense>
    ),
  },

  {
    path: "edit-bunker-requirement/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerRequirement />
      </Suspense>
    ),
  },

  {
    path: "voyage-fixture",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageFixtureForm />
      </Suspense>
    ),
  },

  {
    path: "operation/position-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOperationPositionReport />
      </Suspense>
    ),
  },

  {
    path: "port-bunker-and-activity-details",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortActivityDetails />
      </Suspense>
    ),
  },

  {
    path: "list-coa-vci",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncListCoaVci />
      </Suspense>
    ),
  },

  {
    path: "cpdesk",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCpDesk />
      </Suspense>
    ),
  },

  {
    path: "port",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPort />
      </Suspense>
    ),
  },

  {
    path: "port-information",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortInformation />
      </Suspense>
    ),
  },

  {
    path: "business-rule-accounting",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBusinessRuleAccounting />
      </Suspense>
    ),
  },

  {
    path: "bank-names",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBankNames />
      </Suspense>
    ),
  },

  {
    path: "voyage-bunker-plan",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageBunkerPlan />
      </Suspense>
    ),
  },

  {
    path: "cargo-enquiry-templates",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoEnquiryTemplates />
      </Suspense>
    ),
  },

  {
    path: "cargo-enquiry-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoEnquirySendTemplates />
      </Suspense>
    ),
  },

  {
    path: "tonnage-enquiry-templates",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTonnageEnquireTemplates />
      </Suspense>
    ),
  },

  {
    path: "tonnage-enquiry-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTonnageEnquireSendTemplates />
      </Suspense>
    ),
  },

  {
    path: "voyage/new-laytime",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncNewLaytime />
      </Suspense>
    ),
  },

  {
    path: "port-expense",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortExpense />
      </Suspense>
    ),
  },

  {
    path: "laytime-summary",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncLaytimeSummary />
      </Suspense>
    ),
  },

  {
    path: "bunker-requirement",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerRequirement />
      </Suspense>
    ),
  },

  {
    path: "bunker-requirement/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerRequirement />
      </Suspense>
    ),
  },

  {
    path: "bunker-purchased-order",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerPurchasedOrder />
      </Suspense>
    ),
  },

  {
    // path: "bunker-invoice",
    path: "bunker-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        {/* <AsyncBunkerInvoice /> */}
        <AsyncBunkerInvoiceListing />
      </Suspense>
    ),
  },

  {
    path: "instruction-set",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncInstructionSet />
      </Suspense>
    ),
  },

  {
    path: "bunker-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerInvoiceListing />
      </Suspense>
    ),
  },

  {
    path: "bunker-requirement-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerRequirementListing />
      </Suspense>
    ),
  },

  {
    path: "bunker-purchase-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerPurchaseListing />
      </Suspense>
    ),
  },
  // AsyncTrackNoon
  {
    path: "track-by-noon",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTrackNoon />
      </Suspense>
    ),
  },

  {
    path: "dynamic-vspm", //report-list
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncDynamicVspm />
      </Suspense>
    ),
  },

  {
    path: "voyage-optimization",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageOptimization />
      </Suspense>
    ),
  },

  {
    path: "vessel-activate-link",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselAcivateList />
      </Suspense>
    ),
  },

  {
    path: "voyage-rejected-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <VoyageRejectedList />
      </Suspense>
    ),
  },

  {
    path: "reporting-form",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncReportingForm />
      </Suspense>
    ),
  },

  {
    path: "overall-performance-analysis",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOverallPerformanceAnalysis />
      </Suspense>
    ),
  },

  {
    path: "voyage-history-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselHistoricalData />
      </Suspense>
    ),
  },

  {
    path: "cashflow-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCashflowReport />
      </Suspense>
    ),
  },

  {
    path: "vendor-transaction",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVendorTransactionReport />
      </Suspense>
    ),
  },

  {
    path: "trial-balance-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTrialBalanceReport />
      </Suspense>
    ),
  },

  {
    path: "account-payable-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccountPaybleReport />
      </Suspense>
    ),
  },

  {
    path: "account-receivable-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccountReceivableReport />
      </Suspense>
    ),
  },

  {
    path: "voyage-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageReport />
      </Suspense>
    ),
  },

  {
    path: "noon-verification",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncNoonVerification />
      </Suspense>
    ),
  },

  {
    path: "arrival-eosp",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncArrivaleasp />
      </Suspense>
    ),
  },

  {
    path: "bunker-handling",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerHandling />
      </Suspense>
    ),
  },

  {
    path: "delay-stop-at-sea",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncDelayStopatSea />
      </Suspense>
    ),
  },

  {
    path: "sof",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncSof />
      </Suspense>
    ),
  },

  {
    path: "inport-cargo-handling",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncInPortCargoHandling />
      </Suspense>
    ),
  },

  {
    path: "noon-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncNoonReport />
      </Suspense>
    ),
  },

  {
    path: "update-noon-report/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncNoonReport />
      </Suspense>
    ),
  },

  {
    path: "vessel-shedule",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselSchedule />
      </Suspense>
    ),
  },

  {
    path: "port-shedule",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortSchedule />
      </Suspense>
    ),
  },

  {
    path: "chartering-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCharteringDashboard />
      </Suspense>
    ),
  },
  {
    path: "cargo-analytical-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoAnalyticDashboard />
      </Suspense>
    ),
  },
  {
    path: "bunker-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBunkerAnalyticDashboard />
      </Suspense>
    ),
  },
  {
    path: "charteringdashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCharteringDashboardlist />
      </Suspense>
    ),
  },
  {
    path: "vessel-open-schedule",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselOpenSchedule />
      </Suspense>
    ),
  },

  {
    path: "cargo-term-details",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncCargoTermDetails />
      </Suspense>
    ),
  },

  {
    path: "initial-freight-invoice-summary",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncInitialFreightInvoiceSummary />
      </Suspense>
    ),
  },

  //------------------------------------------------------------

  {
    path: "freight-invoice",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncFreightInvoice />
      </Suspense>
    ),
  },

  {
    path: "freight-commission",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynFreightCommission />
      </Suspense>
    ),
  },

  {
    path: "freight-commission-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynCommissionSummary />
      </Suspense>
    ),
  },

  {
    path: "portcall-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMyportcallList />
      </Suspense>
    ),
  },

  {
    path: "user-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncUserDashboard />
      </Suspense>
    ),
  },

  {
    path: "service-desk",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncServiceDesk />
      </Suspense>
    ),
  },

  {
    path: "tags",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTags />
      </Suspense>
    ),
  },

  {
    path: "user-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncUserList />
      </Suspense>
    ),
  },

  {
    path: "group-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncGroupList />
      </Suspense>
    ),
  },

  {
    path: "access-right",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccessRight />
      </Suspense>
    ),
  },

  {
    path: "company-subscription",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncSubscriptionList />
      </Suspense>
    ),
  },

  {
    path: "organization",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOrganisation />
      </Suspense>
    ),
  },

  {
    path: "other-revenue-expense",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOtherExpense />
      </Suspense>
    ),
  },

  {
    path: "other-revenue",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOtherRevenue />
      </Suspense>
    ),
  },

  {
    path: "other-invoice",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOtherInvoice />
      </Suspense>
    ),
  },

  {
    path: "voyage-efficiency-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynVoyageEfficiency />
      </Suspense>
    ),
  },

  {
    path: "other-expense-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOtherExpenseList />
      </Suspense>
    ),
  },

  {
    path: "other-revenue-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOtherRevenueList />
      </Suspense>
    ),
  },

  {
    path: "hire-schedule-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynHireScheduleList />
      </Suspense>
    ),
  },

  {
    path: "hire-schedule",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynHireSchedule />
      </Suspense>
    ),
  },

  {
    path: "hire-receivable-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynHireIssueBillList />
      </Suspense>
    ),
  },

  {
    path: "hire-receivable-trxn-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynHireIssueBillTrxnList />
      </Suspense>
    ),
  },
  {
    path: "hire-payment-trxn-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynHirePaymentTrxnList />
      </Suspense>
    ),
  },

  // AsynHireIssueBillTrxnList AsynHirePaymentTrxnList

  {
    path: "hire-payable-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynHirePaymentList />
      </Suspense>
    ),
  },
  {
    path: "analytical-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynAnalyticalDashboard />
      </Suspense>
    ),
  },

  {
    path: "PDA-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynPdaList />
      </Suspense>
    ),
  },

  {
    path: "FDA-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynFdaList />
      </Suspense>
    ),
  },

  {
    path: "voy-relet-full-estimate",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynVoyageReletFull />
      </Suspense>
    ),
  },

  {
    path: "voy-relet-full-estimate-edit/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynVoyageReletFull />
      </Suspense>
    ),
  },

  {
    path: "voy-relet-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynVoyReletList />
      </Suspense>
    ),
  },

  {
    path: "report-analytic",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncReportDesign />
      </Suspense>
    ),
  },

  {
    path: "portcall-details/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynPortcallDetails />
      </Suspense>
    ),
  },

  {
    path: "edit-portcall-details/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynPortcallDetails />
      </Suspense>
    ),
  },

  {
    path: "transaction-summary-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynTransactionSummary />
      </Suspense>
    ),
  },

  {
    path: "vendor-transaction-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynVendorTransaction />
      </Suspense>
    ),
  },

  {
    path: "verified-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynVerifiedInvoice />
      </Suspense>
    ),
  },

  {
    path: "delete-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynDeleteInvoice />
      </Suspense>
    ),
  },
  {
    path: "approved-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynApprovedInvoice />
      </Suspense>
    ),
  },

  {
    path: "prepared-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynPreparedInvoice />
      </Suspense>
    ),
  },

  {
    path: "posted-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynPostedInvoice />
      </Suspense>
    ),
  },

  {
    path: "report-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynReportList />
      </Suspense>
    ),
  },

  {
    path: "bunker-vendor-transaction-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynBunkerVendorTransactionList />
      </Suspense>
    ),
  },
  {
    path: "agent-transaction-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynAgentTransactionList />
      </Suspense>
    ),
  },

  {
    path: "other-vendor-transaction-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOtherVendorTransactionList />
      </Suspense>
    ),
  },

  {
    path: "agent-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynAgentDashboard />
      </Suspense>
    ),
  },

  {
    path: "company-registration",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynCompanyRegistration />
      </Suspense>
    ),
  },

  {
    path: "bank-account-management",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynBankAccount />
      </Suspense>
    ),
  },
  {
    path: "user-password-management",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynUserPassword />
      </Suspense>
    ),
  },

  {
    path: "archives-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynArchivesList />
      </Suspense>
    ),
  },

  {
    path: "risk-management",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncRiskManagement />
      </Suspense>
    ),
  },

  {
    path: "finance-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        {/* <AsynFinanceDashboard /> */}
        <AsynFinanceDashboardlist />
      </Suspense>
    ),
  },
  {
    path: "financedashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynFinanceDashboardlist />
      </Suspense>
    ),
  },
  {
    path: "edit-quick-estimate/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageEstimateQuick />
      </Suspense>
    ),
  },

  {
    path: "quick-estimate-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynQuickTcovList />
      </Suspense>
    ),
  },
  {
    path: "notification",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynNotification />
      </Suspense>
    ),
  },
  {
    path: "claim-invoice",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynClaimInvoice />
      </Suspense>
    ),
  },

  {
    path: "claim-listing",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynClaimListing />
      </Suspense>
    ),
  },

  {
    path: "port-selection",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynPortSelection />
      </Suspense>
    ),
  },

  {
    path: "super-panel",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynSuperPanel />
      </Suspense>
    ),
  },

  {
    path: "agent-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynAgentList />
      </Suspense>
    ),
  },

  {
    path: "my-subscriber",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynMySubscriber />
      </Suspense>
    ),
  },

  {
    path: "edit-subscriber/:id",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynMySubscriber />
      </Suspense>
    ),
  },

  {
    path: "my-profile",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynProfile />
      </Suspense>
    ),
  },
  {
    path: "folders",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynFolder />
      </Suspense>
    ),
  },

  {
    path: "port-information-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortInformationList />
      </Suspense>
    ),
  },

  {
    path: "advance-payment-recipt-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAdvancePaymentReceiptList />
      </Suspense>
    ),
  },

  {
    path: "voyage-task-and-alert",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVoyageTaskAlert />
      </Suspense>
    ),
  },
  {
    path: "tc-commission-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncTcCommissionInvoiceList />
      </Suspense>
    ),
  },

  {
    path: "address-commission-invoice-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAddressCommissionInvoiceList />
      </Suspense>
    ),
  },

  {
    path: "report-analytics",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncReportAnalytics />
      </Suspense>
    ),
  },

  {
    path: "laytime-invoice",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynLayTimeInvoiceListing />
      </Suspense>
    ),
  },
  {
    path: "laytime-listing",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynLayTimeListing />
      </Suspense>
    ),
  },

  {
    path: "dispute-claim-listing",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynDisputeClaimListing />
      </Suspense>
    ),
  },

  {
    path: "map-intelligence",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMapIntellegence />
      </Suspense>
    ),
  },

  {
    path: "spot-price",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynSpotPrice />
      </Suspense>
    ),
  },
  {
    path: "invoice",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynInvoice />
      </Suspense>
    ),
  },

  {
    path: "port-cost",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncPortPerformance />
      </Suspense>
    ),
  },

  {
    path: "master-noon-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMasterNoonVerification />
      </Suspense>
    ),
  },

  {
    path: "activate-voyage-listed",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncActivateVoyageListed />
      </Suspense>
    ),
  },

  {
    path: "smart-mail",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncSmartmail />
      </Suspense>
    ),
  },

  {
    path: "bunkerperformance-report",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncbunkeringPerformance />
      </Suspense>
    ),
  },

  {
    path: "bunkertime-performance",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncbunkeringtimePerformance />
      </Suspense>
    ),
  },

  {
    path: "voyageoperation-performance",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncvoyageOperationPerformance />
      </Suspense>
    ),
  },

  {
    path: "cargo-performance",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynccargoPerformance />
      </Suspense>
    ),
  },

  {
    path: "pooling-distribution",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncpoolingdistributionList />
      </Suspense>
    ),
  },

  {
    path: "access-control",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccessControl />
      </Suspense>
    ),
  },

  {
    path: "OffHire-Deviation",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOffHireDeviation />
      </Suspense>
    ),
  },

  {
    path: "OffHire-Deviation-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncOffHireDeviationList />
      </Suspense>
    ),
  },

  {
    path: "notifications-detail",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncNotificationsDetail />
      </Suspense>
    ),
  },

  {
    path: "vessel-file",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselFile />
      </Suspense>
    ),
  },

  {
    path: "vessel-file-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncVesselFileList />
      </Suspense>
    ),
  },

  {
    path: "data-center/:routeKey/:slug",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncMastersList />
      </Suspense>
    ),
  },

  {
    path: "account-ledger",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccountLedger />
      </Suspense>
    ),
  },
  {
    path: "business-rule",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBusinessRule />
      </Suspense>
    ),
  },
  {
    path: "business-rule-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncBusinessRuleList />
      </Suspense>
    ),
  },
  {
    path: "account-period",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccountPeriod />
      </Suspense>
    ),
  },
  {
    path: "calendar-view",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <CalendarView />
      </Suspense>
    ),
  },

  {
    path: "cii-dashboard",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynCii />
      </Suspense>
    ),
  },
  {
    path: "mails",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <MailLayout />
      </Suspense>
    ),
  },
  {
    path: `mails/:imported_mail_identifier/inbox`,
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <MailLayout />
      </Suspense>
    ),
  },
  
  {
    path: `mails/groups/:groupName`,
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <GroupMailLayout />
      </Suspense>
    ),
  },
  {
    path: "oceannai",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynOceannAI />
      </Suspense>
    ),
  },
  {
    path: "mails/groups",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <MailGroup />
      </Suspense>
    ),
  },

  {
    path: "mails/sent",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <SentList />
      </Suspense>
    ),
  },

  {
    path: "mails/draft",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <DraftList />
      </Suspense>
    ),
  },

  {
    path: "mails/important",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <ImportantList />
      </Suspense>
    ),
  },

  {
    path: "mails/spam",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <SpamList />
      </Suspense>
    ),
  },

  {
    path: "mails/archived",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <ArchivedList />
      </Suspense>
    ),
  },

  {
    path: "mails/bin",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <BinList />
      </Suspense>
    ),
  },
  {
    path: "mails/labels/:label_name",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <LabelList />
      </Suspense>
    ),
  },
  {
    path: "mails/folders/:folder_name",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <FolderList />
      </Suspense>
    ),
  },
  {
    path: "mails/templates",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <EmailTemplate />
      </Suspense>
    ),
  },

  {
    path: "mails/analytics",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <MailDashboard />
      </Suspense>
    ),
  },

  {
    path: "mails/all-tonnage-list/:tonnage_name",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AllTonnageList />
      </Suspense>
    ),
  },

  {
    path: "mails/all-tonnage-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AllTonnageList />
      </Suspense>
    ),
  },

  {
    path: "mails/all-cargo-list/:cargo_name",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AllCargoList />
      </Suspense>
    ),
  },

  {
    path: "mails/all-cargo-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AllCargoList />
      </Suspense>
    ),
  },

  {
    path: "mails/global-tonnage-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <GlobalTonnageOrder />
      </Suspense>
    ),
  },

  {
    path: "mails/global-cargo-list",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <GlobalCargoOrder />
      </Suspense>
    ),
  },

  {
    path: "*",
    element: <RippleComponent />,
  },
];