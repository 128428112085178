import {
  DeleteOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Col,
  Drawer,
  Layout,
  Modal,
  notification,
  Row
} from "antd";
import { Button } from "antd/lib/radio";
import _ from "lodash";
import * as moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import ReactToPrint from "react-to-print";
import Cookies from "universal-cookie";
import { toggleCollapsedNav } from "../../../../actions/settingsActions.js";
import Email from "../../../../components/Email/index.js";
import CustomLoader from "../../../../components/Loading/CustomLoader.js";
import RightBarUI from "../../../../components/RightBarUI";
import VoyageManagerInfoTciTco from "../../../../components/vessel-form/VoyageManagerInfoTci-Tco.js";
import URL_WITH_VERSION, {
  apiDeleteCall,
  awaitPostAPICall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  URL_WITHOUT_VERSION,
  useStateCallback
} from "../../../../shared";
import ModalAlertBox from "../../../../shared/ModalAlertBox";
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
import {
  deleteAttachment,
  getAttachments,
  uploadAttachment,
} from "../../../../shared/attachments";
import AttachmentFile from "../../../../shared/components/Attachment";
import CommissionPayment from "../../../../shared/components/CommissionPayment";
import CommissionSummary from "../../../../shared/components/CommissionSummary";
import HireStatement from "../../../../shared/components/HireStatement";
import RippleComponent from "../../../NotFoundPage/index.js";
import TciReports from "../../../form-reports/TciReports";
import MailSpiltView from "../../../inbox/Inbox.jsx";
import ParsedInfo from "../../../voyage-list/components/ParsedInfo.js";
import EstimateSummary from "../tcto/right-panel/EstimateSummary.js";
import StatementofAccount from "./modals/StatementofAccount";
import StatementofAccountRecap from "./modals/StatementofAccountRecap";

const cookies = new Cookies();
const { Content } = Layout;

const openNotification = (keyName, description = undefined) => {
  if (description === undefined) {
    notification.info({
      message: `Can't Open ${keyName}`,
      description: `Please save TCI Form first  and fix then click on ${keyName}`,
      placement: "topRight",
    });
  } else {
    notification.info({
      message: `Can't Open ${keyName}`,
      description: `${description}`,
      placement: "topRight",
    });
  }
};

const newFormData = {
  ".": {
    delivery_date: "",
    d_notification: "",
  },
  "-": {
    redelivery_date: "",
    r_notification: "",
  },

  "..": [
    {
      notice_type: "25 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "15 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "10 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "7 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "5 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "4 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "3 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "1 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
  ],
  "...": [
    {
      notice_type: "25 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
    {
      notice_type: "15 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
    {
      notice_type: "10 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
    {
      notice_type: "7 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
    {
      notice_type: "5 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
    {
      notice_type: "4 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
    {
      notice_type: "3 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
    {
      notice_type: "1 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_r_delivary: "",
    },
  ],
  id: 0,
  cp_date_fixed_by: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
  config_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
  deliveryterm: [
    {
      d_t_name: "Delivery",
      port_name: "Select Port",
      min_days: 0.0,
      max_days: 0.0,
      editable: true,
      index: 0,
      ID: -9e6,
    },
    {
      d_t_name: "Redelivery",
      port_name: "Select Port",
      min_days: 0.0,
      max_days: 0.0,
      editable: true,
      index: 1,
      ID: -9e6 + 1,
    },
  ],
  hireterm: [
    {
      hire_rate: 0,
      period: 1,
      rate_type: 1,
      editable: true,
      index: 0,
      ID: -9e6,
    },
  ],
  otherterm: [
    {
      short_code: "TCIBB",
      description: 1,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6,
      rate_type: 1,
      commission: true,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCIGRA",
      description: 2,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 1,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCIVIC",
      description: 3,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 2,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCIREA",
      description: 4,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 3,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCILAST",
      description: 5,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 4,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCIILOHC",
      description: 6,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 5,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCIIHC",
      description: 7,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 6,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCIOTHEXP",
      description: 8,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 7,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCIOTHREV",
      description: 9,
      amount: 0,
      editable: true,
      index: 0,
      ID: -9e6 + 7,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
  ],
  commissionterm: [
    {
      commission: 10,
      rate_figure: 0,
      editable: true,
      index: 0,
      ID: -9e6,
      payment_method: 3,
      rate_type: 1,
      from_gmt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      to_gmt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    },
  ],
  bunkers: [
    {
      short_code: "TCIBAD",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 3,
    },
    {
      short_code: "TCIBED",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 2,
    },
    {
      short_code: "TCIBDD",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 4,
    },

    {
      short_code: "TCIBOR",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 6,
    },
  ],
  tcbunkeradjustment: [
    {
      bod: "Delivery Value IFO",
      bod_value: "0.00",
      bor: "Redelivery Value IFO",
      bor_value: "0.00",
      net_bunker: "Net IFO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value LSMGO",
      bod_value: "0.00",
      bor: "Redelivery Value LSMGO",
      bor_value: "0.00",
      net_bunker: "Net LSMGO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value ULSFO",
      bod_value: "0.00",
      bor: "Redelivery Value ULSFO",
      bor_value: "0.00",
      net_bunker: "Net ULSFO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value VLSFO",
      bod_value: "0.00",
      bor: "Redelivery Value VLSFO",
      bor_value: "0.00",
      net_bunker: "Net VLSFO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value MGO",
      bod_value: "0.00",
      bor: "Redelivery Value MGO",
      bor_value: "0.00",
      net_bunker: "Net MGO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      // bod : "Delivery Value MGO",
      // "bod_value": "0.00",
      // "bor": "Redelivery Value MGO",
      // "bor_value": "0.00",
      net_bunker: "Net Bunker Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
  ],
  bareboat: [
    {
      beneficiary: "Select Value",
      code: 0,
      editable: true,
      index: 0,
      ID: -9e6,
    },
  ],

  "seaspdconsp.tableperday": [
    { con_type: 0, con_g: "", con_unit: 1, editable: true, index: 0, ID: -9e6 },
  ],
  "portconsp.tableperday": [
    {
      con_type: "Select Fuel type",
      con_g: "Select Grade",
      con_unit: 2,
      editable: true,
      index: 0,
      ID: -9e6,
    },
  ],
};

const TCI = (props) => {
  const _formData = _.cloneDeep(newFormData);
  const [state, setState] = useStateCallback({
    frmName: "time_charter_in_form",
    responseData: { frm: [], tabs: [], active_tab: {} },
    formData: Object.assign(_formData, props.formData || {}),
    formReportdata: Object.assign(_formData, props.reportFormData || {}),
    visibleSummary: false,
    visibleMakePayment: false,
    tciID: _formData.id,
    paymentData: {},
    commissionEntry: {},
    visiblehirePayment: false,
    visibleStatementAccount: false,
    hmPay: {},
    popupFroms: props.popupFroms || {},
    visibleDrawer: false,
    title: undefined,
    loadComponent: undefined,
    width: 1200,
    isShowSearchTci: false,
    isShowSearchTco: false,
    isShowTciReports: false,
    VoyageManagerInfoModal: false,
    vmInfoData: null,
    isDoSchedule: false,
    rightMenuButtons: [],
    reportFormData: {
      portitinerary: [
        {
          port_id: "4444",
          port: "2944",
          funct: 3,
          s_type: 1,
          wf_per: 10,
          miles: "0.00",
          speed: "0.00",
          eff_speed: "0.00",
          gsd: "0.00",
          tsd: "0.00",
          xsd: "0.00",
          l_d_qty: "0.00",
          l_d_rate: "0.00",
          l_d_rate1: "0.00",
          turn_time: "0.00",
          days: "0.00",
          xpd: "0.00",
          p_exp: "0.00",
          t_port_days: "0.00",
          l_d_term: 1,
          editable: true,
          index: 0,
          ID: -9e6,
        },
        {
          port_id: "6297",
          port: "5530",
          s_type: 1,
          wf_per: 10,
          miles: "0.00",
          speed: "0.00",
          eff_speed: "0.00",
          gsd: "0.00",
          tsd: "0.00",
          xsd: "0.00",
          l_d_qty: "0.00",
          l_d_rate: "0.00",
          l_d_rate1: "0.00",
          turn_time: "0.00",
          days: "0.00",
          xpd: "0.00",
          p_exp: "0.00",
          t_port_days: "0.00",
          l_d_term: 1,
          editable: true,
          index: 1,
          ID: -9e6 + 1,
        },
      ],
    },
    frmVisible: true,
    showSideListBar: false,
    selectedID: null,
    makePaymentData: null,
    isOpenSoa: false,
    isOpenSoar: false,
    marineData: [],
  });

  const [isVoyageManagerInfo, setIsVoyageManagerInfo] = useState(false);
  const [sharebtn, setSharebtn] = useState();
  const [shareLink, setShareLink] = useState();
  const [vesselData, setVesselData] = useState([]);
  const idRef = useRef(null);
  const responseDataState = useRef({ frm: [], tabs: [], active_tab: {} });
  const navigate = useNavigate();
  const statementRef = useRef(null);
  const location = useLocation();
  const params = useParams();

  const storeData = useSelector((store) => store.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (props?.formData?.id) {
        idRef.current = props.formData.tci_id;
      }

      const _id = window?.state?.data?.["tc_code"];
      const localdata = JSON.parse(localStorage.getItem("tci_id"));
      const paramid = params?.id;
      let id = paramid || localdata?.tc_code || _id;

      if (
        id &&
        paramid &&
        !(
          location.pathname.includes("/edit-tc-est-fullestimate") ||
          location.pathname.includes("/edit-voyage-estimate") ||
          location.pathname.includes("/voyage-manager")
        )
      ) {
        _onLeftSideListClick(id);
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [props?.formData?.id, window?.state?.data]);

  const reFreshForm = async () => {
    if (idRef.current !== null) {
      _onLeftSideListClick(idRef.current);
    } else {
      _onCreateFormData();
    }
  };

  const _onCreateFormData = () => {
    const _formData = _.cloneDeep(newFormData);
    // const rightMenuButtons = _.cloneDeep(newRightMenuButtons);
    idRef.current = null;
    localStorage.removeItem("fixedvessel");
    setState(
      (prevState) => ({
        ...prevState,
        formData: _formData,
        id: 0,
        is_fixed: 0,
        is_schedule: 0,
        showSideListBar: false,
        selectedID: null,
        frmVisible: false,
      }),
      () => {
        setState((prevState) => ({ ...prevState, frmVisible: true }));
        navigate("/add-TC-IN");
        localStorage.removeItem("tci_id");
      }
    );
  };

  useEffect(() => {
    getFormData();
  }, []);
  const handlePrint = () => {
    document.getElementById("print-button").click();
  };

  const Opensoa = (boolval) => {
    const { formData } = state;
    if (
      formData &&
      formData.hasOwnProperty("id") &&
      formData["id"] > 0 &&
      formData["is_schedule"] === 1
    ) {
      let deliveryterm = formData["deliveryterm"];
      let tciID = {
        vessel_id: formData.vessel_id,
        tc_id: formData.tc_code,
        tci_id: formData.id,
        cp_date: formData.cp_date_fixed_by,
        tc_code: formData.tc_code,
        chartered_from: formData.company_fixed_with_name,
        // chartrer_id: formData.owner,
        delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
        redelivery_date: deliveryterm[1]
          ? deliveryterm[1].actual_gmt
          : undefined,
      };

      setState((prevState) => ({
        ...prevState,
        tciID: tciID,
        isOpenSoa: boolval,
      }));
    } else {
      openNotification(
        "Statements",
        `Please  ${
          formData["id"] ? "" : "Save TCI Form first,"
        }Fix and Schedule it and then Click on Statements.`
      );
    }
  };

  const Opensoar = (boolval) => {
    const { formData } = state;
    if (
      formData &&
      formData.hasOwnProperty("id") &&
      formData["id"] > 0 &&
      formData["is_schedule"] === 1
    ) {
      let deliveryterm = formData["deliveryterm"];
      let tciID = {
        vessel_id: formData.vessel_id,
        tc_id: formData.id,
        cp_date: formData.cp_date_fixed_by,
        tc_code: formData.tc_code,
        // chartered_from:formData.beneficiary_name1,

        chartrer_id: formData.owner,
        delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
        redelivery_date: deliveryterm[1]
          ? deliveryterm[1].actual_gmt
          : undefined,
      };
      setState((pre) => ({
        ...pre,
        isOpenSoar: boolval,
        tciID: tciID,
      }));
    } else {
      openNotification(
        "Statements",
        `Please  ${
          formData["id"] ? "" : "Save TCI Form first,"
        }Fix and Schedule it and then Click on Statements.`
      );
    }

    // if (boolval) {

    // } else {
    //   setState((pre) => ({
    //     ...pre,
    //     isOpenSoar: boolval,
    //   }));
    // }
  };

  const onCloseDrawer = () =>
    setState((prevState) => ({
      ...prevState,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    }));

  const _onDeleteFormData = (postData) => {
    // let user_type = cookies.get('user_type') ? cookies.get('user_type') : null
    if (postData && postData.id <= 0) {
      openNotificationWithIcon(
        "error",
        "Cargo Id is empty. Kindly check it again!"
      );
    } else if (
      postData["is_fixed"] &&
      postData["is_fixed"] === 1 &&
      postData["is_schedule"] &&
      postData["is_schedule"] !== 1
    ) {
      Modal.confirm({
        title: "Confirm",
        content:
          "Are you sure to delete this TC/HF contract?, you don’t want to schedule.",
        onOk: () => _onDelete(postData),
      });
    } else {
      Modal.confirm({
        title: "Confirm",
        content: "Are you sure, you want to delete it?",
        onOk: () => _onDelete(postData),
      });
    }
  };

  const _onDelete = (postData) => {
    let _url = `${URL_WITH_VERSION}/tci/delete`;
    apiDeleteCall(_url, { id: postData.id }, (response) => {
      if (response && response.data) {
        openNotificationWithIcon("success", response.message);
        window.emitNotification({
          n_type: "TCI Deleted ",
          userId: cookies.get("user_id") || "",
          msg: window.notificationMessageCorrector(
            `TCI is Deleted, for vessel(${postData.vessel_code}), by ${window.userName}`
          ),
        });
        _onCreateFormData();
      } else {
        openNotificationWithIcon("error", response.message);
      }
    });
  };

  const onClickRightMenu = async (key, options) => {
    const { formData } = state;

    if (!storeData.collapsedNav.collapsedNav) {
      dispatch(toggleCollapsedNav(true));
    }
    onCloseDrawer();
    let loadComponent = undefined;
    let marinedata = {};
    switch (key) {
      case "summary":
        loadComponent = (
          <EstimateSummary
            tciID={formData.id}
            type={"TCI"}
            datatci={formData}
          />
        );
        break;
      // case 'properties':
      //   loadComponent = <Properties />;
      //   break;
      // case 'pl-summary':
      //   loadComponent = <TciPlSummary />;
      //   break;
      // case 'summary':
      //   loadComponent = <EstimateSummary tciID={formData['id']} />;
      //   break;
      case "sideMap": {
        const { formData, marineData } = state;

        if (marineData["SHIPNAME"]) {
          marinedata = marineData;
        } else {
          marinedata = await getMarineVessel(formData.vessel_name);
        }
        if (!marinedata?.SHIPNAME) {
          openNotificationWithIcon("info", "Vessel data not found");
          return;
        }
        loadComponent = <ParsedInfo marineData={marinedata} />;
        break;
      }
      case "attachment":
        const { id } = formData;
        if (id) {
          const attachments = await getAttachments(id, "EST");
          const callback = (fileArr) =>
            uploadAttachment(fileArr, id, "EST", "tci");
          loadComponent = (
            <AttachmentFile
              uploadType="Estimates"
              attachments={attachments}
              onCloseUploadFileArray={callback}
              deleteAttachment={(file) =>
                deleteAttachment(file.url, file.name, "EST", "tci")
              }
              tableId={0}
            />
          );
        } else {
          openNotificationWithIcon(
            "info",
            "Attachment will open only after save"
          );
        }
        break;
      case "vm":
        if (state.formData.id) {
          setIsVoyageManagerInfo(!isVoyageManagerInfo);
        } else {
          openNotificationWithIcon("info", "Please select vessel from list");
        }
        break;

      case "mail": {
        loadComponent = <MailSpiltView />;
        break;
      }

      default:
        break;
    }
    setState((prevState) => ({
      ...prevState,
      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      marineData: marinedata,
      width: options.width && options.width > 0 ? options.width : 1200,
    }));
  };

  const getFormData = async () => {
    const { formData } = state;
    const response = await getAPICall(
      `${URL_WITHOUT_VERSION}get/${state.frmName}`
    );
    const data = await response["data"];

    responseDataState.current = { ...data };
  };

  const saveFormData = (postData, innerCB) => {
    let tcbunkeradjustment = [];

    if (
      postData &&
      postData.hasOwnProperty("tcbunkeradjustment") &&
      postData["tcbunkeradjustment"]
    ) {
      let deliveryData = null;
      let reDeliveryData = null;
      postData?.bunkers?.forEach((val, indx) => {
        let {
          f_IFO,
          f_IFO_p,
          f_LSMGO,
          f_LSMGO_p,
          f_MGO,
          f_MGO_p,
          f_ULSFO,
          f_ULSFO_p,
          f_VLSFO,
          f_VLSFO_p,
        } = val;

        let ifo_cal = f_IFO && f_IFO_p ? +f_IFO * +f_IFO_p : 0;
        let lsmgo_cal = f_LSMGO && f_LSMGO_p ? +f_LSMGO * +f_LSMGO_p : 0;
        let mgo_cal = f_MGO && f_MGO_p ? +f_MGO * +f_MGO_p : 0;
        let ulsfo_cal = f_ULSFO && f_ULSFO_p ? +f_ULSFO * +f_ULSFO_p : 0;
        let vlsfo_cal = f_VLSFO && f_VLSFO_p ? +f_VLSFO * +f_VLSFO_p : 0;

        if (indx === 0) {
          deliveryData = [ifo_cal, lsmgo_cal, ulsfo_cal, vlsfo_cal, mgo_cal];
        } else if (indx === postData.bunkers.length - 1) {
          reDeliveryData = [ifo_cal, lsmgo_cal, ulsfo_cal, vlsfo_cal, mgo_cal];
        }
      });

      let sum = 0;
      postData?.tcbunkeradjustment?.forEach((val, indx) => {
        let bod_value = deliveryData[indx];
        let bor_value = reDeliveryData[indx];
        let net_bunker_value = bor_value - bod_value;
        // let voyage_number = 0;
        if (postData.tcbunkeradjustment.length - 1 !== indx) {
          let all_bunker_amount =
            (Number(val.per_allowed) * net_bunker_value) / 100;
          tcbunkeradjustment.push({
            ...val,
            bod_value,
            bor_value,
            net_bunker_value,
            all_bunker_amount,
          });
          sum += net_bunker_value;
        } else {
          let all_bunker_amount = (Number(val.per_allowed) * sum) / 100;
          tcbunkeradjustment.push({
            val,
            net_bunker_value: sum,
            all_bunker_amount,
            id: val.id,
          });
        }
      });
    }
    const { frmName, popupFroms, formData } = state;
    const _postData = _.cloneDeep(postData);
    let _url = "save";
    let _method = "post";
    let params = "";

    try {
      setState((prevState) => ({ ...prevState, frmVisible: false }));
      if (postData.hasOwnProperty("id")) {
        if (Array.isArray(postData.hireterm)) {
          postData.hireterm.forEach((ele) => {
            if (ele.hire_rate === "") {
              ele.hire_rate = 0;
            }
          });
        }
        _url = "update";
        _method = "put";
      }

      if (state.formData.tci_status === 111 && postData["tci_status"] === 125) {
        let msg = `You cannot change Draft after fix status.`;
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />,
          5
        );
        setState({ ...state, frmVisible: true });
        return false;
      }
      if (postData.hasOwnProperty("otherterm")) {
        postData["otherterm"].forEach((item) => {
          if (item.commission === undefined) {
            return (item.commission = 0);
          }
        });
      }

      let tci_id = postData["tc_code"];
      if (
        postData["-"] &&
        postData["-"]["redelivery_date"] == "0000-00-00 00:00:00"
      ) {
        delete postData["-"]["redelivery_date"];
      }
      if (
        postData["."] &&
        postData["."]["delivery_date"] == "0000-00-00 00:00:00"
      ) {
        delete postData["."]["delivery_date"];
      }
      if (
        _url == "update" &&
        postData["voyagehistory"] &&
        postData["voyagehistory"].length > 0
      ) {
        delete postData["voyagehistory"];
      }

      if (postData.hasOwnProperty("vessel_name") && postData["vessel_name"]) {
        delete postData["vessel_name"];
      }

      postAPICall(
        `${URL_WITH_VERSION}/tci/${_url}?frm=${frmName}${params}`,
        postData,
        _method,
        (data) => {
          if (data.data) {
            openNotificationWithIcon("success", data.message);

            const createMessage = {
              n_type: "TCI Created",
              userId: cookies.get("user_id") || "",
              msg: `TCI Created by ${
                cookies.get("username")
                  ? cookies.get("username").charAt(0).toUpperCase() +
                    cookies.get("username").slice(1)
                  : "User"
              } for Vessel ${postData.vessel_code || ""}`,
            };

            const updateMessage = {
              n_type: "TCI Updated",
              userId: cookies.get("user_id") || "",
              msg: `TCI updated by ${
                cookies.get("username")
                  ? cookies.get("username").charAt(0).toUpperCase() +
                    cookies.get("username").slice(1)
                  : "User"
              } for Vessel ${postData.vessel_code || ""}`,
            };

            window.socket.emit(
              "postNotification",
              _url === "save" ? createMessage : updateMessage
            );

            if (_url === "update") {
              _onLeftSideListClick(tci_id);
            }

            if (_url === "save") {
              _onLeftSideListClick(data.row.tc_code);
            }

            if (
              props.modalCloseEvent &&
              typeof props.modalCloseEvent === "function"
            ) {
              props.modalCloseEvent(data["row"]);
            }

            if (innerCB && typeof innerCB === "function") {
              innerCB();
            }
          } else {
            let dataMessage = data.message;
            let msg = "<div className='row'>";

            if (typeof dataMessage !== "string") {
              Object.keys(dataMessage).map(
                (i) =>
                  (msg +=
                    "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
              );
            } else {
              msg += dataMessage;
            }

            msg += "</div>";
            openNotificationWithIcon(
              "info",
              <div dangerouslySetInnerHTML={{ __html: msg }} />
            );

            setState((prevState) => ({
              ...prevState,
              frmVisible: true,
              formData: _postData,
            }));

            // if(typeof(props.refresh) === 'function'){
            //   props.refresh()
            // }
          }
        }
      );
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        frmVisible: true,
        formData: _postData,
      }));
      openNotificationWithIcon("error", "Something went wrong.");
    }
  };

  const openTciReports = async (showTciReports, tciID) => {
    // for report Api
    const responseReport = await getAPICall(
      `${URL_WITH_VERSION}/tci/report?e=${tciID}`
    );
    const respDataReport = await responseReport["data"];

    if (responseReport) {
      setState((prevState) => ({
        ...prevState,
        reportFormData: respDataReport,
        isShowTciReports: showTciReports,
      }));
    } else {
      openNotificationWithIcon("error", "Unable to show report", 5);
    }
  };

  const onCancelMakePayment = () =>
    setState((prevState) => ({ ...prevState, visibleMakePayment: false }));

  const onCancelhirestatement = () =>
    setState((prevState) => ({ ...prevState, visiblehirePayment: false }));

  const onCancelModal = () =>
    setState((prevState) => ({
      ...prevState,
      hmPay: {},
      modalStatus: false,
      modalBody: null,
      modalHeader: null,
      modalFooter: [null],
    }));

  const scheduleTCI = (boolVal) => {
    const { formData } = state;
    if (
      formData &&
      formData.hasOwnProperty("id") &&
      formData["id"] > 0 &&
      formData["is_fixed"] == 1
    ) {
      setState((prevState) => ({ ...prevState, isDoSchedule: boolVal }));
    } else {
      openNotification("Schedule");
    }
  };

  useEffect(() => {
    if (isVoyageManagerInfo === true) {
      getTableData();
    }
  }, [isVoyageManagerInfo]);

  const getTableData = async () => {
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/voyage-manager/info/tci/${state.formData.id}`
      );
      const respData = await response["data"];
      if (respData.length > 0) {
        setState((prevState) => ({
          ...prevState,
          vmInfoData: respData,
          VoyageManagerInfoModal: true,
        }));
      } else {
        openNotificationWithIcon("info", "No VM is Linked with this TCI");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const onSubMenuOptionClick = async (inCall, formData) => {
    const responseData = responseDataState.current;

    setState((prevState) => ({ ...prevState, visibleMakePayment: false }));
    let payObj = {};
    let hirePaymentData = { id: 0, "-": [] };
    let makePaymentData = { id: 0, "-": [], actualID: formData["id"] };
    if (
      responseData &&
      responseData.hasOwnProperty("frm") &&
      responseData.frm.length > 0 &&
      formData
    ) {
      let response = await getAPICall(
        `${URL_WITH_VERSION}/make_payment/list?l=0`,
        {
          id: {
            where:
              "`vessel_id`= " +
              formData.vessel_id +
              " AND `tc_id`='" +
              formData.tc_code +
              "'",
          },
        }
      );
      let respData = await response["data"];

      if (respData && respData.length > 0) {
        let paymentForm = moment(respData[respData.length - 1]["period_form"]);
        makePaymentData["period_form"] = paymentForm.format("YYYY-MM-DDTHH:mm");
      } else if (
        formData &&
        formData.hasOwnProperty("deliveryterm") &&
        formData["deliveryterm"].length > 0 &&
        formData["deliveryterm"][0].hasOwnProperty("est_gmt") &&
        formData["deliveryterm"][0]["est_gmt"] !== ""
      ) {
        makePaymentData["period_form"] = moment(
          formData["deliveryterm"][0]["actual_gmt"]
        ).format("YYYY-MM-DDTHH:mm");
      }

      responseData.frm.forEach((e) => {
        if (e.f_name === "vessel_id") {
          hirePaymentData[e.f_name] = formData[e.f_name] * 1;
          makePaymentData[e.f_name] = formData[e.f_name] * 1;
        } else if (e.f_name === "config_date") {
          hirePaymentData["cp_date"] = formData[e.f_name];
          makePaymentData["tc_date"] = formData[e.f_name];
        } else if (e.f_name === "tc_code") {
          hirePaymentData["tc_code"] = formData[e.f_name];
          makePaymentData["tc_id"] = formData[e.f_name];
        } else if (e.f_name === "currency_exhange_rate") {
          makePaymentData["exchange_rates"] = formData[e.f_name];
        } else if (e.f_name === "payment_term") {
          makePaymentData["terms"] = formData[e.f_name];
        } else if (e.f_name === "trade_area") {
          makePaymentData["currency"] = 205; // USD
        } else if (e.f_name === "owner") {
          makePaymentData["charterer_from"] = formData["owner"];
          makePaymentData["my_company"] = formData["company_fixed_with"];
          makePaymentData["lob"] = formData["company_lob"];
        }
      });

      if (formData && formData.hasOwnProperty("cp_date_fixed_by")) {
        hirePaymentData["chartered_from"] = formData["owner"];
      }

      if (
        formData &&
        formData.hasOwnProperty("deliveryterm") &&
        formData["deliveryterm"].length > 0
      ) {
        formData["deliveryterm"].forEach((e) => {
          if (
            e["d_t_name"] === "Delivery" &&
            e["actual_gmt"] &&
            e["actual_gmt"] !== ""
          ) {
            hirePaymentData["delivery_date"] = e["actual_gmt"];
            makePaymentData["delivery"] = e["actual_gmt"];
          } else if (
            e["d_t_name"] === "Redelivery" &&
            e["actual_gmt"] &&
            e["actual_gmt"] !== ""
          ) {
            hirePaymentData["redelivery_date"] = e["actual_gmt"];
            makePaymentData["re_delivery"] = e["actual_gmt"];
          }
        });
      }

      if (
        formData &&
        formData.hasOwnProperty("hireterm") &&
        formData["hireterm"].length > 0
      ) {
        // debugger
        if (!makePaymentData.hasOwnProperty("amount")) {
          makePaymentData["amount"] = 0;
          makePaymentData["actual_amount"] = 0;
          makePaymentData["daily_rates"] = 0;
          makePaymentData['invoice_total'] = 0;
        }
        formData["hireterm"].forEach((e) => {
          makePaymentData["amount"] =
            makePaymentData["amount"] +
            e["hire_rate"].toString().replaceAll(",", "") * 1;
          makePaymentData["actual_amount"] = makePaymentData["amount"];
          makePaymentData["daily_rates"] = makePaymentData["amount"];
          makePaymentData['invoice_total'] = makePaymentData['amount']
        });
      }
    }

    if (
      formData &&
      formData.hasOwnProperty("bunkers") &&
      formData["bunkers"].length > 0
    ) {
      let bunker = [
        { dr_name: "Delivery Cost" },
        { dr_name: "Adj on delivery" },
        { dr_name: "Redelivery Cost" },
        { dr_name: "Adj on redelivery" },
      ];

      formData["bunkers"].map((e) => {
        if (e.description === 3 || e.description === 6) {
          let index = e.description === 3 ? 0 : 2;
          let cDesc = JSON.parse(e.c_fields_values);
          let item = { ifo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0, mgo: 0 };
          let iv = {
            IFO: "ifo",
            VLSFO: "vlsfo",
            ULSFO: "ulsfo",
            MGO: "mgo",
            LSMGO: "lsmgo",
          };
          cDesc.map(
            (_e) => (item[iv[_e.name]] = _e.consumption + " X " + _e.price)
          );
          bunker[index] = Object.assign(item, bunker[index]);
        }
      });
      makePaymentData["bunkerdeliveryredeliveryterm"] = bunker;
    }

    payObj = { hpay: hirePaymentData, mpay: makePaymentData };
    setState((prevState) => ({ ...prevState, hmPay: payObj }));
    return inCall(payObj);
  };

  const onHirePayment = async (key, formData) => {
    //const { formData } = state;

    if (
      formData &&
      formData.hasOwnProperty("id") &&
      formData["id"] > 0 &&
      formData["is_schedule"] === 1
    ) {
      let deliveryterm = formData["deliveryterm"];

      let tciID = {
        vessel_id: formData.vessel_id,
        tc_id: formData.tc_code,
        tci_id: formData.id,
        chartrer_id: formData.owner,
        delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
        redelivery_date: deliveryterm[1]
          ? deliveryterm[1].actual_gmt
          : undefined,
      };

      onSubMenuOptionClick((hmPay) => {
        setState((prevState) => ({
          ...prevState,
          tciID: tciID,
          paymentData: hmPay.hpay,
          paymentFormData: hmPay.mpay,
          visiblehirePayment: true,
        }));
      }, formData);
    } else {
      openNotification(
        "Statements",
        `Please  ${
          formData["id"] ? "" : "Save TCI Form first,"
        }Fix and Schedule it and then Click on Statements.`
      );
    }
  };

  const closeModal = () => {
    setState((prevState) => ({ ...prevState, visiblehirePayment: false }));
  };

  const onInvoiceClick = async (_formData) => {
    await onSubMenuOptionClick((hmPay) => {
      setState((prevState) => ({
        ...prevState,
        paymentData: hmPay.mpay,
        visibleMakePayment: true,
      }));
    }, _formData);
  };

  useEffect(() => {
    let newWindow = {};
    if (state.visibleMakePayment) {
      newWindow = window.open("#/make-payment", "_blank");
      newWindow.state = {
        formData: state.hmPay.mpay,
      };

      localStorage.setItem("make-payment", JSON.stringify(newWindow.state));
    }
  }, [state.visibleMakePayment]);

  const onMakePayment = async (key, data) => {
    console.log('data....', data);
    
    return data &&
      data.hasOwnProperty("id") &&
      data["id"] > 0 &&
      data["is_schedule"] === 1
      ? onInvoiceClick(data)
      : openNotification(
          "Make Payment",
          `Please ${
            data["id"] ? "" : "Save TCI Form first,"
          }Fix and Schedule it and then Click on Make Payment`
        );
  };

  const onClickCommission = async (key, formData) => {
    // const { responseData, formData } = state;
    try {
      const responseData = responseDataState.current;

      let response = await getAPICall(
        `${URL_WITH_VERSION}/make_payment/list?l=0`,
        {
          id: {
            where:
              "`vessel_id`= " +
              formData.vessel_id +
              " AND `tc_id`='" +
              formData.tc_code +
              "'",
          },
        }
      );
      let respData = await response["data"];
      if (respData.length == 0) {
        openNotificationWithIcon(
          "info",
          "Please generate an Make Payment first.",
          3
        );
        return;
      }

      if (
        formData &&
        formData.hasOwnProperty("id") &&
        formData["id"] > 0 &&
        formData["is_schedule"] === 1
      ) {
        let makeCommissionData = { id: 0 },
          makeCommissionEntry = { id: 0 };
        let modalBody = null,
          Commission = undefined,
          modalHeader = null,
          modalFooter = [null];
        if (
          responseData &&
          responseData.hasOwnProperty("frm") &&
          responseData.frm.length > 0
        ) {
          responseData.frm.forEach((e) => {
            if (
              e.f_name === "vessel_id" &&
              e.f_dyc_extras &&
              e.f_dyc_extras.options &&
              e.f_dyc_extras.options.length > 0
            ) {
              makeCommissionData["vessel_name"] = e.f_dyc_extras.options.filter(
                (o) => o.id * 1 === formData["vessel_id"] * 1
              )[0]["name"];
              makeCommissionEntry["vessel_id"] = e.f_dyc_extras.options.filter(
                (o) => o.id * 1 === formData["vessel_id"] * 1
              )[0]["id"];
            } else if (e.f_name === "tc_code") {
              makeCommissionData["tc_code"] = formData["tc_code"];
              makeCommissionEntry["tc_code"] = makeCommissionData["tc_code"];
            } else if (e.f_name === "cp_date_fixed_by") {
              makeCommissionData["chartered_form"] =
                formData["cp_date_fixed_by"];
            } else if (e.f_name === "currency_exhange_rate") {
              makeCommissionData["currency"] =
                formData["currency_exhange_rate"];
            }
          });

          let response = await getAPICall(
            `${URL_WITH_VERSION}/accounts/borker/commission?t=tci&e=${formData["id"]}`
          );
          let respData = await response["data"];
          makeCommissionEntry["tci_id"] = formData["id"];
          makeCommissionEntry["payment_terms"] = formData["payment_term"];
          makeCommissionEntry["payment_terms2"] =
            respData["ptd"]["description"];

          if (key === "tc_commission") {
            Commission = () => (
              <CommissionPayment
                commissionData={makeCommissionEntry}
                frmOptions={[
                  { key: "broker", data: respData["brokers"] },
                  { key: "remmitance_bank", data: respData["banks"] },
                ]}
                key={makeCommissionEntry?.tci_id}
              />
            );
            modalBody = Commission;
            modalHeader = "TC Commission Payment";
          } else if (key === "claim_commission") {
            makeCommissionData["tci_id"] = formData["id"];

            Commission = () => (
              <CommissionSummary
                frmCode="tc_commission_summary"
                commissionData={makeCommissionEntry}
                formData={makeCommissionData}
                frmOptions={[{ key: "broker", data: respData["brokers"] }]}
                oldFromData={formData}
                key={makeCommissionEntry?.tci_id}
              />
            );
            modalBody = Commission;
            modalHeader = "TC Commission Summary";
          }
          setState((prevState) => ({
            ...prevState,
            commissionEntry: makeCommissionEntry,
            modalBody,
            modalStatus: true,
            modalHeader,
            modalFooter,
          }));
        }
      } else {
        openNotification(
          "TC Commission",
          `Please ${
            formData["id"] ? "" : "Save TCI Form first and"
          } fix and Schedule it then Click On TC Commission`
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const performFix = async (data) => {
    const { formData } = state;
    let tciId = formData["id"];

    if (formData && idRef.current) {
      let _url = `${URL_WITH_VERSION}/tci/fix`,
        data = { tci: tciId };
      let response = await awaitPostAPICall(_url, data);
      let respData = await response;
      if (respData["data"] !== false) {
        openNotificationWithIcon("success", respData.message);

        window.emitNotification({
          n_type: "TCI Fixed",
          msg: window.notificationMessageCorrector(
            `TCI(${idRef.current}) is fixed, for vessel(${formData.vessel_code}), by ${window.userName}`
          ),
        });
        _onLeftSideListClick(idRef.current);
      } else {
        let dataMessage = respData.message;
        let msg = "<div className='row'>";
        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }
        msg += "</div>";
        openNotificationWithIcon(
          "info",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
      }
    } else {
      openNotificationWithIcon(
        "info",
        `Please Save the TCI Form First,then Click On Fix.`
      );
    }
  };

  const scheduleTCIWithoutTrade = async (key, formData) => {
    // const { formData } = state;
    if (formData && formData.hasOwnProperty("id") && formData["id"] > 0) {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/tci/schedule/${formData["id"]}`
      );
      const respData = await response;

      if (respData["data"] === true) {
        openNotificationWithIcon("success", respData["message"]);

        window.emitNotification({
          n_type: "TCI Scheduled",
          msg: window.notificationMessageCorrector(
            `TCI is scheduled, for vessel(${formData.vessel_code}), by ${window.userName}`
          ),
        });
        if (formData && formData.id) {
          _onLeftSideListClick(formData.tc_code);
        }
        // if (props.modalCloseEvent && typeof props.modalCloseEvent === 'function') {
        //   props.modalCloseEvent();
        // }
      } else if (respData["data"] === false) {
        let dataMessage = respData["message"];
        let msg = "<div className='row'>";

        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }

        msg += "</div>";
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
      }
    } else {
      openNotificationWithIcon(
        "info",
        `Please Save TCI Form first and Fixed then Click on Schedule.`
      );
    }
  };

  const getMarineVessel = async (vesselname) => {
    try {
      let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${vesselname}`;
      let marineData = await getAPICall(url);
      let marinVessel = marineData.filter(
        (item) => item.SHIPNAME.toLowerCase() === vesselname.toLowerCase()
      );
      if (marinVessel.length) {
        marinVessel = marinVessel[0];
      }
      return marinVessel;
    } catch (err) {
      console.log("err", err);
    }
  };

  const getTCIDelayList = async (id) => {
    const response2 = await getAPICall(
      `${URL_WITH_VERSION}/tci/delay-list?e=${id}`
    );
    return response2;
  };

  const getVoyageHistoryList = async (id) => {
    let headers = { order_by: { id: "desc" } };
    const response1 = await getAPICall(
      `${URL_WITH_VERSION}/tci/voyage-history-list?e=${id}`,
      headers
    );
    return response1;
  };

  const _onLeftSideListClick = async (tciId) => {
    try {
      if (tciId) {
        idRef.current = tciId;
        const { formData } = state;
        let marinVessel = [];
        let voyage_history_data = [];
        let Offhiredelay = [];
        let delivery_date;
        let redelivery_date;
        setState((prevState) => ({ ...prevState, frmVisible: false }));
        const response = await getAPICall(
          `${URL_WITH_VERSION}/tci/edit?e=${tciId}`
        );
        const data = await response["data"];
        setState((prevState) => ({
          ...prevState,
          frmVisible: true,
          formData: data,
          showSideListBar: false,
          selectedID: tciId,
          marineData: marinVessel,
        }));

        if (data) {
          if (data["is_fixed"] == 1 || data["is_schedule"] == 1) {
            localStorage.setItem("fixedvessel", JSON.stringify(true));
          }

          if (data && data.hasOwnProperty("..") && data[".."].length < 8)
            data[".."] = [
              {
                notice_type: "25 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
              {
                notice_type: "15 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
              {
                notice_type: "10 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
              {
                notice_type: "7 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
              {
                notice_type: "5 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
              {
                notice_type: "4 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
              {
                notice_type: "3 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
              {
                notice_type: "1 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_delivary: "",
              },
            ];
          if (data && data.hasOwnProperty("...") && data["..."].length < 8)
            data["..."] = [
              {
                notice_type: "25 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
              {
                notice_type: "15 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
              {
                notice_type: "10 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
              {
                notice_type: "7 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
              {
                notice_type: "5 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
              {
                notice_type: "4 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
              {
                notice_type: "3 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
              {
                notice_type: "1 DAYS",
                email_body: "",
                email_content: "",
                notice_sent: 0,
                project_r_delivary: "",
              },
            ];

          if (data) {
            const response2 = getTCIDelayList(data.id);

            response2
              .then((res) => {
                const offHireDelayData = res["data"];

                if (offHireDelayData && offHireDelayData.length > 0) {
                  offHireDelayData.map((ohr) => {
                    let delayData = {
                      voyage_no: ohr.voyage_number,
                      //pnl_status:ohr.,
                      activity: ohr.activity_name,
                      reason: ohr.reason_name,
                      delay_from: ohr.delay_from,
                      timezone: ohr.timezone,
                      delay_to: ohr.delay_to,
                      timezone2: ohr.timezone2,
                      hours: ohr.hours,
                      ded_adj_tci: ohr.ded_adj_tci,
                      miles: ohr.miles,
                      hfo_qty: ohr.hfo_qty,
                      hfo_rob: ohr.hfo_rob,
                      mdo_qty: ohr.mdo_qty,
                      mdo_rob: ohr.mdo_rob,
                      mgo_qty: ohr.mgo_qty,
                      mgo_rob: ohr.mgo_rob,
                      tci_per: ohr.tci_per,
                      tci_daily_cost: ohr.tci_daily_cost,
                      tci_l: ohr.tci_l,
                      tci_lumpsum: ohr.tci_lumpsum,
                      tci_stmt: ohr.tci_stmt,
                      remarks: ohr.remarks,
                      last_updated: ohr.last_updated,
                      last_updated_by: ohr.last_updated_by,
                    };
                    Offhiredelay.push(delayData);
                  });
                }
              })
              .catch((err) =>
                console.log("Error in fetching data from getTCIDelayList:", err)
              );

            const response1 = getVoyageHistoryList(data.id);

            response1.then((res) => {
              const voyageHistoryData = res["data"];

              if (voyageHistoryData && voyageHistoryData.length > 0) {
                voyageHistoryData.map((vh) => {
                  let historyData = {
                    voyage_no: vh.vessel_id,
                    voyage_type: vh.ops_type_name,
                    code: vh.vessel_code,
                    voyage_result: vh.vm_status,
                    gross_income: vh.profit_loss,
                    gross_expenses: vh.grossexpense,
                    voyage_days: vh.total_days,
                  };
                  voyage_history_data.push(historyData);
                });
              }
            });

            if (data.deliveryterm && data.deliveryterm.length > 0) {
              data.deliveryterm.map((dt) => {
                if (dt.d_t_name == "Delivery")
                  delivery_date = dt.d_t_name == "Delivery" && dt.actual_gmt;
                redelivery_date = dt.d_t_name == "Redelivery" && dt.actual_gmt;
              });
            }
          }

          if (data && data.hasOwnProperty("tcbunkeradjustment")) {
            let deliveryData = [];
            let reDeliveryData = [];
            data?.bunkers?.forEach((val, indx) => {
              let parsed_c_fields_values = JSON.parse(val?.c_fields_values);
              if (indx === 0) {
                for (let item of parsed_c_fields_values) {
                  deliveryData.push(+item.consumption * +item.price);
                }
              } else if (indx === data?.bunkers?.length - 1) {
                for (let item of parsed_c_fields_values) {
                  reDeliveryData.push(+item.consumption * +item.price);
                }
              }
            });

            data?.tcbunkeradjustment.forEach((item, indx) => {
              item.bod_value = deliveryData[indx];
            });

            let netBunkerVal = 0;
            data?.tcbunkeradjustment.forEach((item, indx) => {
              item.bor_value = reDeliveryData[indx];
              item.net_bunker_value =
                item.bor_value - data?.tcbunkeradjustment[indx].bod_value;
              netBunkerVal += item.net_bunker_value;
              if (indx === data?.tcbunkeradjustment?.length - 1) {
                item.net_bunker_value = netBunkerVal;
                // delete respData?.tcbunkeradjustment[respData?.tcbunkeradjustment - 1]['bod_value']
                // delete respData?.tcbunkeradjustment[respData?.tcbunkeradjustment - 1]['bor_value']
                // delete respData?.tcbunkeradjustment[respData?.tcbunkeradjustment - 1]['bod']
                // delete respData?.tcbunkeradjustment[respData?.tcbunkeradjustment - 1]['bor']
              }
            });
          }
        }

        let setData = _.cloneDeep(Object.assign({}, newFormData, data));
        if (Offhiredelay && Offhiredelay.length > 0) {
          setData["Offhiredelay"] = Offhiredelay;
        }
        if (voyage_history_data && voyage_history_data.length > 0) {
          setData["voyagehistory"] = voyage_history_data;
        } else {
          setData["voyagehistory"] = [
            {
              disablefield: [
                "voyage_no",
                "voyage_type",
                "code",
                "voyage_result",
                "gross_income",
                "gross_expenses",
                "voyage_days",
              ],
            },
          ];
        }
        if (setData["."] && setData["."].length > 0) {
          setData["."] = setData["."][0];
          if (
            setData["."] &&
            setData["."]["delivery_date"] == "0000-00-00 00:00:00"
          ) {
            delete setData["."];
            setData["."] = { delivery_date: delivery_date };
          }
        } else if (!setData["."] && delivery_date) {
          setData["."] = { delivery_date: delivery_date };
        }
        if (setData["-"] && setData["-"].length > 0) {
          setData["-"] = setData["-"][0];
          if (
            setData["-"] &&
            setData["-"]["redelivery_date"] == "0000-00-00 00:00:00"
          ) {
            delete setData["-"];
            setData["-"] = { redelivery_date: redelivery_date };
          }
        } else if (!setData["-"] && redelivery_date) {
          setData["-"] = { redelivery_date: redelivery_date };
        }
        if (setData[".."] && setData[".."].length > 0) {
          let dl_date = setData["."]["delivery_date"];
          let myPastDate = new Date(dl_date);
          setData[".."].map((dl) => {
            if (
              dl.project_delivary &&
              (dl["email_content"] == "" || dl["email_content"] == "NaN")
            ) {
              dl["email_content"] = myPastDate.getDate() - dl.project_delivary;
            }
          });
        }
        if (setData["..."] && setData["..."].length > 0) {
          let rdl_date = setData["-"]["redelivery_date"];
          let myPastDate1 = new Date(rdl_date);
          setData["..."].map((rdl) => {
            if (
              rdl.project_r_delivary &&
              (rdl["email_content"] == "" || rdl["email_content"] == "NaN")
            ) {
              rdl["email_content"] =
                myPastDate1.getDate() - rdl.project_r_delivary;
            }
          });
        }

        if (setData["...."] && setData["...."].length > 0) {
          setData["...."] = setData["...."][0];
        }
        if (setData["......."] && setData["......."].length > 0) {
          setData["......."] = setData["......."][0];
        }

        //let rightMenuButtons = _.cloneDeep(newRightMenuButtons);

        if (setData.hasOwnProperty("is_fixed") && setData["is_fixed"] === 1) {
          //delete rightMenuButtons[2];
          setData["disablefield"] = [
            "tci_status",
            "vessel_id",
            "tc_code",
            "company_fixed_with",
            "owner",
            "config_date",
            "cp_date_fixed_by",
            "ownership_type",
            "contract_type",
          ];
        } else if (
          setData.hasOwnProperty("is_fixed") &&
          setData["is_fixed"] === 0
        ) {
          //delete rightMenuButtons[3];
        }
        if (
          setData.hasOwnProperty("is_schedule") &&
          setData["is_schedule"] === 1
        ) {
          //delete rightMenuButtons[2];
          //delete rightMenuButtons[3];
          setData["disablefield"] = [
            "tci_status",
            "vessel_id",
            "tc_code",
            "company_fixed_with",
            "owner",
            "config_date",
            "cp_date_fixed_by",
            "ownership_type",
            "contract_type",
          ];
        }

        setTimeout(() => {
          setState(
            (prevState) => ({
              ...prevState,
              frmVisible: true,
              formData: setData,
              showSideListBar: false,

              selectedID: tciId,
            }),
            () => {
              setState((prevState) => ({ ...prevState, frmVisible: true }));
              localStorage.setItem(
                "tci_id",
                JSON.stringify({
                  id: setData["id"],
                  tc_code: setData["tc_code"],
                })
              );

              if (!(typeof props.modalCloseEvent === "function")) {
                navigate("/TC-IN/" + setData["tc_code"]);
              }
            }
          );
        }, 500);
      }
    } catch (err) {
      console.log("err", err);
      setState((prevState) => ({
        ...prevState,
        frmVisible: true,
        showSideListBar: false,
        // rightMenuButtons: rightMenuButtons,
      }));
    }
  };

  const onClickExtraIcon = async (action, data) => {
    let delete_id = data && data.id;
    let groupKey = action["gKey"];
    let frm_code = "";
    if (groupKey == "Hire Term") {
      groupKey = "hireterm";
      frm_code = "tab_time_charter_in_pricing_form";
    }
    if (groupKey == "Commission Term") {
      groupKey = "commissionterm";
      frm_code = "tab_time_charter_in_pricing_form";
    }
    if (groupKey == "Bareboat") {
      groupKey = "bareboat";
      frm_code = "tab_time_charter_in_bareboat_form";
    }
    //vessel hire delete
    if (groupKey == "Vessel OffHire Delay") {
      groupKey = "vesseloffhiredelay";
      frm_code = "tab_time_charter_in_vessel_offhire_form";
    }
    //END vessel hire delete
    if (groupKey == "....") {
      frm_code = "tab_time_charter_in_purchase_form";
    }
    if (groupKey == ".....") {
      frm_code = "tab_time_charter_in_purchase_form";
    }
    if (groupKey == "......") {
      frm_code = "tab_time_charter_in_purchase_form";
    }
    if (groupKey == ".......") {
      frm_code = "tab_time_charter_in_sales_form";
    }
    if (groupKey == "........") {
      frm_code = "tab_time_charter_in_sales_form";
    }
    if (groupKey == "..") {
      frm_code = "tab_time_charter_in_alerts_form";
    }
    if (groupKey == "...") {
      frm_code = "tab_time_charter_in_alerts_form";
    }

    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
     

      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey,
      };
      let url = `${URL_WITH_VERSION}/tr-delete`;
      if (groupKey == "hireterm") {
        data1 = {
          id: delete_id,
        };
        url = `${URL_WITH_VERSION}/tci/hire-delete`;
      }
      // replacing tr delete with custom delete api
      if (groupKey == "vesseloffhiredelay") {
        data1 = {
          id: delete_id,
        };
        url = `${URL_WITH_VERSION}/tci/vessel-offhire-delete`;
      }
      //
      postAPICall(url, data1, "delete", (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
        } else {
          openNotificationWithIcon("error", response.message);
        }
      });
    }
  };

  const showShareEmail = () => {
    try {
      const pageUrl = window.location.href;
      const linkBlob = new Blob([pageUrl], { type: "text/plain" });

      const emailContent = pageUrl;

      setState((prev) => ({ ...prev, isShowShare: true }));
      setSharebtn(linkBlob);
      setShareLink(emailContent);
    } catch (err) {
      console.log("err", err);
    }
  };

  const onclose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const onok = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const {
    isDoSchedule,
    loadComponent,
    title,
    visibleDrawer,
    isShowTciReports,
    frmName,
    formData,
    modalStatus,
    modalHeader,
    modalBody,
    modalFooter,
    paymentData,
    rightMenuButtons,
    formReportdata,
    frmVisible,
    showSideListBar,
    isSetsLeftBtn,
    reportFormData,
    selectedID,
  } = state;

  // const isSetsLeftBtnArr = isSetsLeftBtn.filter(
  //   (item) =>
  //     // !(
  //     //   formData &&
  //     //   formData.hasOwnProperty("id") &&
  //     //   formData["id"] === 0 &&
  //     //   item.key == "delete"
  //     // )
  //     !(
  //       (formData && formData.hasOwnProperty("id") && formData["id"] === 0 && item.key === "delete") ||
  //       (formData && formData.hasOwnProperty("is_schedule") && formData["is_schedule"] === 1 && item.key === "save")
  //     )
  // );

  if (params.id === "undefined") {
    return <RippleComponent />;
  }

  return (
    <div className="wrap-rightbar full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {frmVisible ? (
                      <article className="article">
                        <div className="box box-default">
                          <div className="box-body">
                            <NormalFormIndex
                              key={"key_" + frmName + "_0"}
                              formClass="label-min-height"
                              formData={formData}
                              showForm={true}
                              frmCode={frmName}
                              addForm={true}
                              showToolbar={[
                                {
                                  isLeftBtn: [
                                    {
                                      key: "s1",

                                      isSets: [
                                        {
                                          id: "7",
                                          key: "menu-fold",
                                          type: <MenuFoldOutlined />,
                                          withText: "List",
                                          event: "menu-fold",
                                          showToolTip: true,
                                        },
                                        {
                                          id: "5",
                                          key: "plus",
                                          type: <PlusOutlined />,
                                          withText: "Add New",
                                          showToolTip: true,
                                          event: (key, data) =>
                                            _onCreateFormData(),
                                        },

                                        {
                                          id: "2",
                                          key: "save",
                                          type: <SaveOutlined />,
                                          withText: "Save",
                                          showToolTip: true,
                                          event: (key, data) =>
                                            saveFormData(data),
                                        },

                                        ...(state?.formData?.id !== 0 &&
                                        state?.formData?.id !== undefined
                                          ? [
                                              {
                                                id: "6",
                                                key: "delete",
                                                type: <DeleteOutlined />,
                                                withText: "Delete",
                                                showToolTip: true,
                                                event: (key, data) =>
                                                  _onDeleteFormData(data),
                                              },
                                            ]
                                          : []),
                                        {
                                          id: "20",
                                          key: "refresh",
                                          type: <SyncOutlined />,
                                          withText: "Refresh",
                                          showToolTip: true,
                                          event: () => {
                                            reFreshForm();
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                  isRightBtn: [
                                    {
                                      key: "s2",
                                      isSets: [
                                        {
                                          key: "share",
                                          isDropdown: 0,
                                          withText: "To Share This TCI ",
                                          type: "Share",
                                          showToolTip: true,
                                          menus: null,
                                          modalKey: "Share",
                                          event: (key, data) => {
                                            if (data["tc_code"]) {
                                              showShareEmail(data);
                                            } else {
                                              openNotificationWithIcon(
                                                "info",
                                                "Please save the form first",
                                                3
                                              );
                                            }
                                          },
                                        },

                                        {
                                          key: "make_payment",
                                          isDropdown: 0,
                                          withText:
                                            "To Generate Hire Payable Invoice",
                                          type: "Make Payment",
                                          showToolTip: true,
                                          menus: null,
                                          event: (key, data) => {
                                            onMakePayment(key, data);
                                          },
                                        },

                                        {
                                          key: "hire_payment",
                                          isDropdown: 1,
                                          withText: "To Check Statement",
                                          showToolTip: true,
                                          type: "Statements",

                                          menus: [
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Hire Statement",
                                              modalKey: null,
                                              event: (key, data) =>
                                                onHirePayment(true, data),
                                            },
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Statement of Account",
                                              modalKey: null,
                                              event: (key, data) =>
                                                Opensoa(true, data),
                                            },
                                            // {
                                            //   href: null,
                                            //   icon: null,
                                            //   label:
                                            //     "Statement of Account-Recap",
                                            //   modalKey: null,
                                            //   event: (key, data) =>
                                            //     Opensoar(true, data),
                                            // },
                                          ],
                                          // menus: null,
                                          event: (key, data) => {
                                            onHirePayment(key, data);
                                          },
                                        },
                                        ...(state?.formData?.is_fixed !== 1
                                          ? [
                                              {
                                                key: "fix",
                                                isDropdown: 0,
                                                withText:
                                                  "To Fix the Time Charter In Contract",
                                                type: "Fix",
                                                showToolTip: true,
                                                menus: null,
                                                event: (key, data) => {
                                                  data &&
                                                  data.hasOwnProperty("id") &&
                                                  data["id"] > 0
                                                    ? Modal.confirm({
                                                        title: "Confirm",
                                                        content:
                                                          "Are you sure, you want to Fix it?",
                                                        onOk: () =>
                                                          performFix(data),
                                                      })
                                                    : openNotificationWithIcon(
                                                        "info",
                                                        "Please save Tci Form first, then click on fix."
                                                      );
                                                },
                                              },
                                            ]
                                          : []),
                                        ...(state?.formData?.is_schedule !== 1
                                          ? [
                                              {
                                                key: "schedule",
                                                isDropdown: 0,
                                                withText:
                                                  "After Fix To Scheduled your TCI",
                                                type: "Schedule",
                                                showToolTip: true,
                                                menus: null,
                                                event: (key, data) => {
                                                  data &&
                                                  data.hasOwnProperty("id") &&
                                                  data["id"] > 0
                                                    ? //? scheduleTCIWithoutTrade()
                                                      Modal.confirm({
                                                        title: "Confirm",
                                                        content:
                                                          "Are you sure, you want to Schedule it?",
                                                        onOk: () =>
                                                          scheduleTCIWithoutTrade(
                                                            key,
                                                            data
                                                          ),
                                                      })
                                                    : openNotification(
                                                        "Schedule"
                                                      );
                                                },
                                              },
                                            ]
                                          : []),

                                        {
                                          key: "commission",
                                          isDropdown: 1,
                                          withText:
                                            "To Generate Commission Invoice",
                                          type: "Commission",
                                          showToolTip: true,
                                          menus: [
                                            {
                                              href: null,
                                              icon: +null,
                                              label: "TC Commission",
                                              modalKey: "tc_commission",
                                              event: (key, data) => {
                                                onClickCommission(key, data);
                                              },
                                            },
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Commission Summary",
                                              modalKey: "claim_commission",
                                              event: (key, data) => {
                                                onClickCommission(key, data);
                                              },
                                            },
                                          ],
                                        },

                                        {
                                          key: "report",
                                          isDropdown: 0,
                                          withText:
                                            "To View And Generate Report In Pdf",
                                          showToolTip: true,
                                          type: "Report",
                                          menus: null,
                                          event: (key, data) =>
                                            data && data.id
                                              ? openTciReports(true, data.id)
                                              : openNotificationWithIcon(
                                                  "info",
                                                  "Please Save the TCI Form First."
                                                ),
                                        },
                                      ],
                                    },
                                  ],
                                  isResetOption: false,
                                },
                              ]}
                              inlineLayout={true}
                              sideList={{
                                selectedID: selectedID,
                                showList: true,
                                title: "TCI List",
                                uri: "/tci/list?l=0",
                                columns: [
                                  ["tc_code", "tci_status_name"],
                                  "vessel_name",
                                  "owner_name",
                                ],
                                icon: true,
                                searchString:
                                  "TCI Code,TCI Status,Vessel Name,Owner Name",
                                rowClickEvent: (evt) =>
                                  _onLeftSideListClick(evt.tc_code),
                              }}
                              isShowFixedColumn={[
                                "Delivery Terms",
                                ".....",
                                "......",
                                "........",
                                ".........",
                                "Commission Term",
                                "Other Term",
                                "Bareboat",
                                "purchase",
                                "OffHire Delay",
                                "Voyage History",
                                "Bunkers",
                                "Port Consp. Table (Per Day)",
                                "Sea Spd Consp. Table (Per Day)",
                                "Vessel OffHire Delay",
                              ]}
                              showSideListBar={showSideListBar}
                              tableRowDeleteAction={(action, data) =>
                                onClickExtraIcon(action, data)
                              }
                              tabEvents={[
                                {
                                  tabName: "TC Bunker Adjustment",
                                  event: {
                                    from: "Bunkers",
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </article>
                    ) : (
                      <CustomLoader />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>

        <RightBarUI
          pageTitle="tci-righttoolbar"
          callback={(data, options) => onClickRightMenu(data, options)}
        />
        {loadComponent !== undefined &&
        title !== undefined &&
        visibleDrawer === true ? (
          <Drawer
            title={state.title}
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={state.visibleDrawer}
            getContainer={false}
            style={{ position: "absolute" }}
            width={state.width}
            maskClosable={false}
            className="drawer-wrapper-container"
          >
            <div className="tcov-wrapper">
              <div className="layout-wrapper scrollHeight">
                <div className="content-wrapper noHeight">
                  {state.loadComponent}
                </div>
              </div>
            </div>
          </Drawer>
        ) : undefined}
      </Layout>

      {/*       
      {state.visibleMakePayment === true ? (
        <Modal
          title="Make Payment "
          open={state.visibleMakePayment}
          width="80%"
          onCancel={onCancelMakePayment}
          style={{
            maxHeight: 790,
            overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          footer={null}
        >
          <MakePayment
            modalCloseEvent={onCancelMakePayment}
            formData={state.hmPay.mpay}
          />
        </Modal>
      ) : undefined} */}

      {state.isOpenSoa === true ? (
        <Modal
          title="Statement of Account"
          open={state.isOpenSoa}
          width="80%"
          onCancel={() => Opensoa(false)}
          style={{
            maxHeight: 790,
            overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          footer={null}
        >
          <StatementofAccount
            data={state.tciID}
            handlePrint={handlePrint}
            ref={statementRef}
          />
        </Modal>
      ) : undefined}

      {state.isOpenSoar === true ? (
        <Modal
          title="Statement of Account Recap"
          open={state.isOpenSoar}
          width="80%"
          onCancel={() => Opensoar(false)}
          style={{
            macHeight: 790,
            overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          footer={null}
        >
          <StatementofAccountRecap
            data={state.tciID}
            handlePrint={handlePrint}
            ref={statementRef}
          />
        </Modal>
      ) : undefined}

      {state.visiblehirePayment === true ? (
        <Modal
          title="Hire statement"
          open={state.visiblehirePayment}
          width="100%"
          onCancel={onCancelhirestatement}
          style={{
            maxHeight: 790,
            overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          footer={null}
        >
          <HireStatement
            modalCloseEvent={onCancelhirestatement}
            tciID={state.tciID}
            paymentData={state.hmPay.hpay}
            paymentFormData={state.hmPay.mpay}
            handlePrint={handlePrint}
            closeModal={closeModal}
            ref={statementRef}
          />
        </Modal>
      ) : undefined}

      {state.VoyageManagerInfoModal && state.vmInfoData.length ? (
        <Modal
          style={{ top: "2%" }}
          title="Voyage Manager Info"
          open={state.VoyageManagerInfoModal}
          onCancel={() =>
            setState((pre) => ({ ...pre, VoyageManagerInfoModal: false }))
          }
          width="95%"
          bodyStyle={{ overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <VoyageManagerInfoTciTco vmInfoData={state.vmInfoData} />
        </Modal>
      ) : undefined}

      {state.isShowShare && (
        <Modal
          title="New Message"
          open={true}
          onCancel={() => onclose()}
          onOk={() => onok()}
          footer={null}
        >
          <Email
            title={window.corrector(`TCI  || ${state.formData.tc_code}`)}
            attachmentFile={sharebtn}
            // link={shareLink}
            showpage={true}
            condition={false}
            shareLink={shareLink}
            handleClose={handleClose}
          />
        </Modal>
      )}

      {isShowTciReports ? (
        <Modal
          style={{ top: "2%" }}
          title="Reports"
          open={isShowTciReports}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isShowTciReports: false }))
          }
          width="95%"
          footer={null}
        >
          <TciReports data={reportFormData} />
        </Modal>
      ) : undefined}
      <ModalAlertBox
        modalStatus={modalStatus}
        modalHeader={modalHeader}
        modalBody={modalBody}
        modalFooter={modalFooter}
        onCancelFunc={() => onCancelModal()}
      />

      <ReactToPrint
        trigger={() => (
          <Button style={{ display: "none" }} id="print-button">
            Print
          </Button>
        )}
        content={() => statementRef.current}
      />
    </div>
  );
};

export default TCI;
