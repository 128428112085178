import React, { useEffect, useRef, useCallback, useState } from "react";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";

import URL_WITH_VERSION, {
  getAPICall,
  URL_WITHOUT_VERSION,
  postAPICall,
  awaitPostAPICall,
  openNotificationWithIcon,
  sanitize,
  apiDeleteCall,
  useStateCallback,
} from "../../shared";
import { Modal, Drawer, Layout, Form, Input } from "antd";
import PortServicedForm from "../PortServicedForm";
// import WrappedBarForm from './BarForm';
import Attachment from "../../shared/components/Attachment";
import NewCompanyReport from "../../routes/data-center-reports/NewCompanyReport";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../shared/attachments";
import { v4 as uuidv4 } from "uuid";
import {
  DeleteOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  UploadOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const FormItem = Form.Item;

const AddAddressBook = (props) => {
  let bankatcharr =
    props &&
    props.formDataValues &&
    props.formDataValues["bank&accountdetails"] &&
    props.formDataValues["bank&accountdetails"].map((el) => {
      return el.sharelink;
    });

  let conatcharr =
    props &&
    props.formDataValues &&
    props.formDataValues["contacts"] &&
    props.formDataValues["contacts"].map((el) => {
      return el.sharelink;
    });

  const [isFormSaved, setIsFromSaved] = useState(false);
  const [state, setState] = useStateCallback({
    frmName: "address_form",
    responseData: { frm: [], tabs: [], active_tab: {} },
    isVisible: false,
    isPDMSenabled: true,
    visibleProperties: false,
    formData: props?.formDataValues ||
      props?.formData || {
        companytype: { disablefield: ["BC"] },
      },
    visibleBankAttachment: false,
    visibleContactAttachment: false,
    isShowNewCompanyReport: false,
    isShowLetterHead: false,
    frmVisible: true,
    letterHead: null,
    name: "",
    address: "",
    img: "",
    // showSideListBar:props?.showSideListBar === false ? props?.showSideListBar : true,
    showSideListBar: false,
    addressId: props.formDataValues
      ? props.formDataValues.id
      : props.formData
      ? props.formData.id
      : "",
    selectedID: null,
    formDataValues: props.formDataValues || props.formDataValues || {},
    attachments: [],
    bankAttachments:
      bankatcharr && bankatcharr.length > 0 ? [...bankatcharr] : [],
    contactAttachments:
      conatcharr && conatcharr.length > 0 ? [...conatcharr] : [],
    bankIndex: 0,
    contactIndex: 0,
    isHideSaveBtn: false,
  });

  let formref = useRef(null);
  const reFreshForm = () => {
    setState(
      (prevState) => ({
        ...prevState,
        formData: { ...formref.current },
        frmVisible: false,
      }),
      () => {
        setState({ ...state, frmVisible: true });
      }
    );
  };

  useEffect(() => {
    formref.current = Object.assign({}, state.formData);
  }, []);

  const _onCreateFormData = async () => {
    // alert(5);
    const response = await getAPICall(
      `${URL_WITHOUT_VERSION}get/${state.frmName}`
    );
    const data = await response["data"];
    setState(
      (prevState) => ({
        ...prevState,
        frmVisible: false,
        formData: { companytype: { disablefield: ["BC"] } },
        responseData: data,
        addressId: "",
        letterHead: null,
        attachments: [],
        bankAttachments: [],
        contactAttachments: [],
        name: "",
        address: "",
        img: "",
      }),
      () => setState((prevState) => ({ ...prevState, frmVisible: true }))
    );
  };

  const validateFormData = (data) => {
    if (data.full_name && data.full_name.startsWith(" ")) {
      return {
        isValid: false,
        message: "Company Name can't start with a space",
      };
    }

    return { isValid: true };
  };

  const saveFormData = async (data, innerCB) => {
    const { frmName } = state;
    const { bankAttachments, contactAttachments } = state;

    const validation = validateFormData(data);
    if (!validation.isValid) {
      openNotificationWithIcon("info", validation.message, 3);
      return;
    }

    let suURL = `${URL_WITH_VERSION}/address/save?frm=${frmName}`;
    let suMethod = "POST";

    // sanitize(data);
    // don't uncomment this line. no use of it.
    // it only removes the keys with empty or undefind value but causes issue when we delete the value and save , previous value is coming.
    // so we need to create a function

    const userInput = data;

    if (data && data.hasOwnProperty("id")) {
      suURL = `${URL_WITH_VERSION}/address/update?frm=${frmName}`;
      suMethod = "PUT";
      data["id"] = data["id"];
    }
    if (data.companytype) {
      if (data.companytype.disablefield) {
        delete data.companytype.disablefield;
      }
    }

    let bankarr = [];
    if (bankAttachments && bankAttachments.length > 0) {
      data &&
        data["bank&accountdetails"] &&
        data["bank&accountdetails"].length > 0 &&
        data["bank&accountdetails"].map((el, index) => {
          let obj1 = {};
          obj1.account_no = el["account_no"];
          obj1.address_id = el["address_id"];
          obj1.b_country = el["b_country"];
          obj1.benificiary_bank = el["benificiary_bank"];
          obj1.benificiary_name = el["benificiary_name"];
          obj1.branch = el["branch"];
          obj1.cb_swift_code = el["cb_swift_code"];
          obj1.correspondent_bank = el["correspondent_bank"];
          obj1.created_on = el["created_on"];
          obj1.ib_name = el["ib_name"];
          obj1.iban = el["iban"];
          obj1.status = el["status"];
          obj1.swift_code = el["swift_code"];
          obj1.sharelink = bankAttachments[index];
          obj1.id = el["id"];
          bankarr.push(obj1);
          return true;
        });

      delete data["bank&accountdetails"];
      data["bank&accountdetails"] = Object.assign([], bankarr);
    } else {
      data &&
        data["bank&accountdetails"] &&
        data["bank&accountdetails"].length > 0 &&
        data["bank&accountdetails"].map(
          (el, index) => delete el["bank&accountdetails"]
        );
    }

    let contactarr = [];
    if (contactAttachments && contactAttachments.length > 0) {
      data &&
        data["contacts"] &&
        data["contacts"].length > 0 &&
        data["contacts"].map((el, index) => {
          let obj1 = {};
          obj1.company_name = el["company_name"];
          obj1.director_no = el["director_no"];
          obj1.fax_no = el["fax_no"];
          obj1.home_no = el["home_no"];
          obj1.mobile_no = el["mobile_no"];
          obj1.sharelink = contactAttachments[index];
          obj1.id = el["id"];
          contactarr.push(obj1);
          return true;
        });
      delete data["contacts"];
      data["contacts"] = Object.assign([], contactarr);
    } else {
      data &&
        data["contacts"] &&
        data["contacts"].length > 0 &&
        data["contacts"].map((el, index) => delete el["contacts"]);
    }

    data &&
      data["accountinformation"] &&
      data["accountinformation"].length > 0 &&
      data["accountinformation"].map(
        (el, index) => delete el["accountinformation"]
      );

    data &&
      data["additionalinformation"] &&
      data["additionalinformation"].length > 0 &&
      data["additionalinformation"].map(
        (el, index) => delete el["additionalinformation"]
      );

    data &&
      data["subcompany"] &&
      data["subcompany"].length > 0 &&
      data["subcompany"].map((el, index) => delete el["subcompany"]);

    // if (data.email == "") {
    //   data.email = "";
    // }

    await postAPICall(suURL, data, suMethod, async (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message, 3);
        await _onLeftSideListClick(data.row.rid);
        if (suURL.includes("save")) {
          window.emitNotification({
            n_type: "New Company Added",
            msg: window.notificationMessageCorrector(
              `A new Company,(${userInput.full_name}), is added by ${window.userName}`
            ),
          });
        } else {
          window.emitNotification({
            n_type: "Company Updated",
            msg: window.notificationMessageCorrector(
              `Company ,(${userInput.full_name}), is updated by ${window.userName}`
            ),
          });
        }

        // if(data.row.rid){
        //   _onLeftSideListClick(data.row.rid);
        // }

        /*
           this part is comented because as per requirement, after save or update it should remain there not to redirect to list page.
        if (
          props.hasOwnProperty("modalCloseEvent") &&  props.modalCloseEvent &&
          typeof props.modalCloseEvent === "function"
        ) {
         props.modalCloseEvent(data["data"]);
        } else {
          _onLeftSideListClick(data.row.rid);
          setTimeout(() => {
            window.location.href = "#/address-list";
          }, 2);
        }
        if (innerCB && typeof innerCB === "function") {
          innerCB();
        }


        */
      } else {
        let dataMessage = data.message;
        let msg = "<div className='row'>";

        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }

        msg += "</div>";
        openNotificationWithIcon(
          "info",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
      }
    });
  };

  const _onDeleteFormData = (data) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => onAddressDeletedClick(data),
    });
  };

  const onAddressDeletedClick = (data) => {
    if (data.id) {
      let _url = `${URL_WITH_VERSION}/address/delete`;
      apiDeleteCall(_url, { id: data.id }, (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
          setState(
            (prevState) => ({
              ...prevState,
              formData: {},
              showSideListBar: false,
              // selectedID: addressId,
              frmVisible: false,
              // addressId: addressId,
            }),
            () => setState((prevState) => ({ ...prevState, frmVisible: true }))
          );

          if (
            props?.modalCloseEvent &&
            typeof props?.modalCloseEvent === "function"
          ) {
            props.modalCloseEvent(data["data"]);
          }
        } else {
          openNotificationWithIcon("error", response.message);
        }
      });
    }
  };

  const onPopupPDMSClick = () => {
    if (formData.id) {
      setState((pre) => ({
        ...pre,
        isVisible: true,
      }));
    }
  };

  const LetterHead = async (showLetterHead) => {
    const response = await getAPICall(
      `${URL_WITH_VERSION}/address/lh/edit?e=${state.formData.id}`
    ); //865
    const respData = await response["data"];

    let attachments = [];
    if (state.addressId) {
      attachments = await getAttachments(state.addressId, "ADD");
    }

    if (respData === false) {
      setState({
        ...state,
        isShowLetterHead: showLetterHead,
        letterHead: null,
        attachments,
      });
    } else {
      setState({
        ...state,
        isShowLetterHead: showLetterHead,
        attachments,
        letterHead: respData,
        name: respData.full_name,
        address: respData.address,
      });
    }
  };

  const isBankAttachmentOk = () => {
    setState((prev) => ({ ...prev, loading: true }));
    setTimeout(() => {
      setState({ ...state, loading: false, visibleBankAttachment: false });
    }, 3000);
  };

  const isContactAttachmentOk = () => {
    setState({ ...state, loading: true });
    setTimeout(() => {
      setState({ ...state, loading: false, visibleContactAttachment: false });
    }, 3000);
  };

  const uploadedFiles = (type, data) => {
    const { bankAttachments, contactAttachments, bankIndex, contactIndex } =
      state;
    const _data = {
      attachment: data.fileName,
      share_link: data.url,
      id: uuidv4(),
    };

    if (type === "bankattachment") {
      setState((prevState) => {
        const updatedBankAttachments = [...prevState.bankAttachments];

        // Initialize the array at bankIndex if it does not exist
        if (!updatedBankAttachments[bankIndex]) {
          updatedBankAttachments[bankIndex] = [];
        }

        // Append new data to the array at bankIndex
        updatedBankAttachments[bankIndex] = [
          ...updatedBankAttachments[bankIndex],
          _data,
        ];

        return { ...prevState, bankAttachments: updatedBankAttachments };
      });
    } else if (type == "contactattachment") {
      setState((prevState) => {
        const updatedcontctattachments = [...prevState.contactAttachments];

        if (!updatedcontctattachments[contactIndex]) {
          updatedcontctattachments[contactIndex] = [];
        }

        updatedcontctattachments[contactIndex] = [
          ...updatedcontctattachments[contactIndex],
          _data,
        ];

        return { ...prevState, contactAttachments: updatedcontctattachments };
      });
      // let _contactAttachments = contactAttachments;
      // if (
      //   _contactAttachments[contactIndex] &&
      //   _contactAttachments[contactIndex].length > 0
      // ) {
      //   _contactAttachments[contactIndex] = [
      //     ..._contactAttachments[contactIndex],
      //     _data,
      //   ];
      // } else {
      //   _contactAttachments[contactIndex] = [_data];
      // }

      // setState({ ...state, contactAttachments: _contactAttachments });
    }
  };

  const onCancel = () => {
    setState({ ...state, isVisible: false });
  };

  const _onLeftSideListClick = async (addressId) => {
    setState({ ...state, frmVisible: false });

    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/address/edit?ae=${addressId}`
      );
      const respData = await response;

      // const attachments = await getAttachments(addressId, "ADD");
      setState((prevState) => ({
        ...prevState,
        formData: respData["data"],
        showSideListBar: false,
        selectedID: addressId,
        // attachments,
        frmVisible: true,
        addressId: addressId,
        isPDMSenabled: respData["data"].companytype.hasOwnProperty("PA"),
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onUploadDetails = (data) => {
    if (data.gKey === "Bank & Account Details") {
      showAttachementModal("bankdetails", data.ID, data.index);
    } else if (data.gKey === "Contacts") {
      showAttachementModal("contactdetails", data.ID, data.index);
    }
  };

  const showAttachementModal = (type, Id, Index) => {
    if (type == "bankdetails") {
      setState((prevState) => ({
        ...prevState,
        bankIndex: Index,
        rowId: Id,
        visibleBankAttachment: true,
      }));
    } else if (type == "contactdetails") {
      setState((prevState) => ({
        ...prevState,
        contactIndex: Index,
        rowId: Id,
        visibleContactAttachment: true,
      }));
    }
  };

  const onClickExtraIcon = async (action, data) => {
    const { bankAttachments } = state;

    let delete_id = data && data.id;
    let groupKey = action["gKey"];
    let frm_code = "";
    if (groupKey == "Bank & Account Details") {
      groupKey = "bank&accountdetails";
      frm_code = "tab_address_form_info";
      // let _bankAttachments = [...bankAttachments];
      // _bankAttachments.splice(action.index, 1);
      // setState((prevState) => ({
      //   ...prevState,
      //   bankAttachments: _bankAttachments,
      // }));

      setState((prevState) => {
        const updatedBankAttachments = prevState.bankAttachments.filter(
          (_, idx) => idx !== action.index
        );

        return { ...prevState, bankAttachments: updatedBankAttachments };
      });
    }
    if (groupKey == "Contacts") {
      groupKey = "contacts";
      frm_code = "tab_address_form_contact";
      // let _contactAttachments = contactAttachments;
      // _contactAttachments.splice(action.index, 1);
      // setState((prevState) => ({
      //   ...prevState,
      //   contactAttachments: _contactAttachments,
      // }));

      setState((prevState) => {
        const updatedcontctattachments = prevState.contactAttachments.filter(
          (_, idx) => idx !== action.index
        );
        return { ...prevState, contactAttachments: updatedcontctattachments };
      });
    }

    if (groupKey == "Additional Information") {
      groupKey = "additionalinformation";
      frm_code = "address_form_addition_information";
    }

    if (groupKey == "Sub Company") {
      groupKey = "subcompany";
      frm_code = "address_form_sub_company";
    }

    if (groupKey == "Account Information") {
      groupKey = "accountinformation";
      frm_code = "address_form_account_information";
    }

    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey,
        key: data.key,
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  const onCloseDrawer = () => setState({ ...state, visibleProperties: false });

  const saveLetterHead = async () => {
    const { name, address, letterHead, formData, img } = state;
    let data = {};
    let _url = "save";
    let _method = "post";

    if (name === "" || address === "") {
      openNotificationWithIcon(
        "info",
        "Please enter address and name fields !"
      );
      return;
    }

    if (letterHead == null) {
      data = {
        address: address,
        address_id: formData.id,
        full_name: name,
        logo: img,
      };
    } else {
      _url = "update";
      _method = "put";
      let data1 = { ...letterHead };
      data1["address"] = address;
      data1["full_name"] = name;
      data = data1;
    }
    postAPICall(
      `${URL_WITH_VERSION}/address/lh/${_url}`,
      data,
      _method,
      (data) => {
        if (data.data) {
          openNotificationWithIcon("success", data.message);
          setIsFromSaved(true);
          setState({ ...state, isShowLetterHead: false });
        } else {
          openNotificationWithIcon("error", data.message);
          setState({ ...state, isShowLetterHead: false });
        }
      }
    );
  };

  const isBankAttachmentCancel = () =>
    setState({ ...state, visibleBankAttachment: false });
  const isContactAttachmentCancel = () =>
    setState({ ...state, visibleContactAttachment: false });

  // const ondeleteAttachment = async (
  //   fileUrl,
  //   fileName,
  //   addressId,
  //   type,
  //   bucket
  // ) => {
  //   console.log("hello")
  //   const { letterHead } = state;
  //   setState({ ...state, isHideSaveBtn: true });
  //   try {
  //     const url = `${URL_WITH_VERSION}/importexport/delete?url=${fileUrl}&type=${type}&file-name=${fileName}&bucket=${bucket}&address_id=${addressId}`;
  //     const response = await awaitPostAPICall(url);

  //     const _letterHead = { ...letterHead, logo: "" };
  //     if (response.data) {
  //       setState({
  //         ...state,
  //         letterHead: _letterHead,
  //         isHideSaveBtn: false,
  //       });
  //     } else {
  //       setState({ ...state, isHideSaveBtn: false });
  //     }
  //   } catch (error) {
  //     openNotificationWithIcon("error", "Something Went wrong.", 2);
  //     setState({ ...state, isHideSaveBtn: false });
  //   }
  // };

  const handleReportClose = () => {
    setState({ ...state, isShowNewCompanyReport: false });
  };

  const showCompanyReport = async (showNewCompanyReport, addressId) => {
    if (addressId) {
      const responseReport = await getAPICall(
        `${URL_WITH_VERSION}/address/report?ae=${addressId}`
      );
      const respDataReport = await responseReport["data"];

      if (respDataReport) {
        setState((prevState) => ({
          ...prevState,
          reportFormData: respDataReport,
          isShowNewCompanyReport: showNewCompanyReport,
        }));
      } else {
        openNotificationWithIcon(
          "info",
          "Please Save the Address Form First",
          5
        );
      }
    } else {
      openNotificationWithIcon("info", "Please Save the Address Form First", 5);
    }
  };

  const {
    frmName,
    name,
    address,
    addressId,
    attachments,
    reportFormData,
    formData,
    isVisible,
    visibleBankAttachment,
    visibleContactAttachment,
    isShowNewCompanyReport,
    showSideListBar,
    frmVisible,
    isShowLetterHead,
    selectedID,
    isPDMSenabled,
    bankIndex,
    bankAttachments,
    contactAttachments,
    contactIndex,
    isHideSaveBtn,
  } = state;

  const handleFileArrayClose = useCallback(
    async (fileArr) => {
      const data = await uploadAttachment(fileArr, addressId, "ADD", "address");
      setState((prevState) => ({
        ...prevState,
        img: data?.data?.share_link,
      }));
    },
    [addressId, setState, uploadAttachment]
  );

  return (
    <div className="wrap-rightbar full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                {frmName && frmVisible ? (
                  <article className="article">
                    <div className="box box-default">
                      <div className="box-body">
                        <NormalFormIndex
                          key={"key_" + frmName + "_0"}
                          formClass="label-min-height"
                          formData={formData}
                          showForm={true}
                          frmCode={frmName}
                          addForm={true}
                          //formDataValues= {Object.assign({"companytype" : {"disablefield": ["BC","CE","M","T"]}},formDataValues)}
                          showToolbar={[
                            {
                              isLeftBtn: [
                                {
                                  key: "s1",
                                  isSets: [
                                    {
                                      id: "7",
                                      key: "menu-fold",
                                      type: <MenuFoldOutlined />,
                                      withText: "List",
                                      showToolTip: true,
                                      event: "menu-fold",
                                    },
                                    {
                                      id: "8",
                                      key: "plus",
                                      type: <PlusOutlined />,
                                      withText: "Add New",
                                      showToolTip: true,

                                      event: (key, data) => _onCreateFormData(),
                                    },
                                    {
                                      id: "9",
                                      key: "save",
                                      type: <SaveOutlined />,
                                      withText: "Save",
                                      showToolTip: true,
                                      event: (_event, data) => {
                                        saveFormData(data);
                                      },
                                    },

                                    formData &&
                                      formData["id"] && {
                                        id: "6",
                                        key: "delete",
                                        type: <DeleteOutlined />,
                                        withText: "Delete",
                                        showToolTip: true,
                                        event: (key, data) => {
                                          _onDeleteFormData(data);
                                        },
                                      },

                                    {
                                      id: "20",
                                      key: "Refresh",
                                      type: <SyncOutlined />,
                                      withText: "Refresh",
                                      showToolTip: true,
                                      event: () => {
                                        reFreshForm();
                                      },
                                    },
                                  ],
                                },
                              ],
                              isRightBtn: [
                                {
                                  key: "s1",
                                  isSets: [
                                    {
                                      id: "1",
                                      key: "pdms_setup",
                                      type: "PDMS Setup",
                                      withText:
                                        "Port Desk Management System (PDMS) configuration",
                                      showToolTip: true,
                                      isDropdown: 0,
                                      event: (e) => {
                                        onPopupPDMSClick(e);
                                      },
                                    },
                                    {
                                      id: "3",
                                      key: "letter_head",
                                      type: "Letter Head",
                                      withText:
                                        "To Create and Customize Your Company Letterhead",
                                      showToolTip: true,
                                      isDropdown: 0,
                                      event: (_key) => {
                                        if (state.formData.id) {
                                          LetterHead(true);
                                        } else {
                                          openNotificationWithIcon(
                                            "info",
                                            "Please save the form first"
                                          );
                                        }
                                      },
                                    },
                                    {
                                      id: "2",
                                      key: "reports",
                                      type: "Report",
                                      withText: "TO View And Generate Reports",
                                      showToolTip: true,
                                      isDropdown: 0,
                                      event: (_key) =>
                                        showCompanyReport(true, addressId),
                                    },
                                  ],
                                },
                              ],
                              isResetOption: false,
                            },
                          ]}
                          inlineLayout={true}
                          isShowFixedColumn={["Bank & Account Details"]}
                          sideList={{
                            selectedID: selectedID,
                            showList: true,
                            title: "Address List",
                            uri: "/address/list?l=0",
                            columns: [
                              "short_name",
                              "full_name",
                              "address_type",
                            ],
                            icon: true,
                            rowClickEvent: (evt) =>
                              _onLeftSideListClick(evt.id),
                          }}
                          extraTableButton={{
                            "Bank & Account Details": [
                              {
                                icon: <UploadOutlined />,
                                onClickAction: (action) => {
                                  onUploadDetails(action);
                                },
                              },
                            ],
                            Contacts: [
                              {
                                icon: <UploadOutlined />,
                                onClickAction: (action) => {
                                  onUploadDetails(action);
                                },
                              },
                            ],
                          }}
                          tableRowDeleteAction={(action, data) =>
                            onClickExtraIcon(action, data)
                          }
                          showSideListBar={showSideListBar}
                        />
                      </div>
                    </div>
                  </article>
                ) : undefined}
              </div>
            </div>
          </Content>
        </Layout>
        {/*  comment (20-04-2022) this section by amar will open when confierm from team

          <RightBarUI
            pageTitle="address-form-righttoolbar"
            callback={data => onClickRightMenu(data)}
          /> */}
        <Drawer
          title="Properties"
          placement="right"
          closable={true}
          onClose={onCloseDrawer}
          open={state.visibleProperties}
          getContainer={false}
          style={{ position: "absolute" }}
          width={500}
          maskClosable={false}
        >
          {/* <WrappedBarForm /> */}
        </Drawer>
      </Layout>
      {isVisible ? (
        <Modal
          title={"PDMS Setup Form"}
          open={isVisible}
          width="70%"
          onCancel={onCancel}
          style={{ top: "10px" }}
          bodyStyle={{ height: 600, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <div className="body-wrapper">
            <article className="article">
              <div className="box box-default" style={{ padding: "15px" }}>
                {
                  <PortServicedForm
                    oldFrmData={formData}
                    modalCloseEvent={onCancel}
                  />
                }
              </div>
            </article>
          </div>
        </Modal>
      ) : undefined}

      {visibleBankAttachment ? (
        <Modal
          open={visibleBankAttachment}
          title="Upload Attachment ( Bank & Account Details )"
          onOk={isBankAttachmentOk}
          onCancel={isBankAttachmentCancel}
          footer={null}
          width={1000}
          maskClosable={false}
        >
          <Attachment
            uploadType="bankattachment"
            attachments={
              bankAttachments[bankIndex] &&
              bankAttachments[bankIndex].length > 0
                ? bankAttachments[bankIndex]
                : []
            }
            singleSelection={false}
            onCloseUploadFileArray={(fileArr) =>
              uploadedFiles("bankattachment", fileArr)
            }
            deleteAttachment={async (file) => {
              try {
                const datadelete = await deleteAttachment(
                  file.url,
                  file.name,
                  // addressId,
                  "EST",
                  "address"
                );
                if (datadelete.data) {
                  // Filter out the deleted attachment from all indexes of bankAttachments
                  const updatedBankAttachments = state.bankAttachments.map(
                    (attachments) =>
                      attachments.filter((el) => el.share_link !== file.url)
                  );
                  // Update the state with the filtered attachments for all indexes
                  setState({
                    ...state,
                    bankAttachments: updatedBankAttachments,
                  });
                }
              } catch (error) {
                console.error("Error deleting attachment:", error);
              }
            }}
            tableId={bankIndex}
          />
        </Modal>
      ) : undefined}

      {visibleContactAttachment ? (
        <Modal
          open={visibleContactAttachment}
          title="Upload Attachment ( Upload Contact Details )"
          onOk={isContactAttachmentOk}
          onCancel={isContactAttachmentCancel}
          footer={null}
          width={1000}
          maskClosable={false}
        >
          <Attachment
            uploadType="contactattachment"
            // directory={formData["estimate_id"]}
            attachments={
              contactAttachments[contactIndex] &&
              contactAttachments[contactIndex].length > 0
                ? contactAttachments[contactIndex]
                : []
            }
            singleSelection={false}
            onCloseUploadFileArray={(fileArr) =>
              uploadedFiles("contactattachment", fileArr)
            }
            deleteAttachment={async (file) => {
              try {
                const datadelete = await deleteAttachment(
                  file.url,
                  file.name,
                  //  addressId,
                  "EST",
                  "address"
                );
                if (datadelete.data) {
                  const updatedcontctattachments = state.contactAttachments.map(
                    (attachments) =>
                      attachments.filter((el) => el.share_link !== file.url)
                  );
                  setState({
                    ...state,
                    contactAttachments: updatedcontctattachments,
                  });
                }
              } catch (error) {
                console.error("Error deleting attachment:", error);
              }
            }}
            tableId={contactIndex}
          />
        </Modal>
      ) : undefined}

      {isShowNewCompanyReport ? (
        <Modal
          style={{ top: "2%" }}
          title="Report"
          open={isShowNewCompanyReport}
          //  onOk={handleOk}
          onCancel={handleReportClose}
          width="95%"
          footer={null}
        >
          <NewCompanyReport data={reportFormData} />
        </Modal>
      ) : undefined}

      {isShowLetterHead ? (
        <Modal
          style={{ top: "2%" }}
          title="Letter Head"
          open={isShowLetterHead}
          //  onOk={handleOk}
          onCancel={() => setState({ ...state, isShowLetterHead: false })}
          width="50%"
          footer={null}
        >
          <article className="article">
            <div className="box box-default">
              <div className="box-body">
                <Form>
                  <FormItem label="Full Name">
                    <Input
                      value={name}
                      onChange={(e) => {
                        setState({ ...state, name: e.target.value });
                      }}
                      size="default"
                      placeholder="full name"
                    />
                  </FormItem>

                  <FormItem label="Address">
                    <Input
                      value={address}
                      onChange={(e) =>
                        setState({ ...state, address: e.target.value })
                      }
                      size="default"
                      placeholder="address"
                    />
                  </FormItem>

                  <FormItem label="Logo">
                    <Attachment
                      uploadType="Letter Head"
                      attachments={attachments}
                      singleSelection={true}
                      directory={formData["estimate_id"]}
                      deleteAttachment={(file) =>
                        deleteAttachment(
                          file.url,
                          file.name,
                          addressId,
                          "DELETE",
                          "address"
                        )
                      }
                      onCloseUploadFileArray={handleFileArrayClose}
                    />
                    <span className="letterhead">
                      ( *Image Should be 800x150 pixel for better report )
                    </span>
                  </FormItem>
                  <button
                    disabled={isHideSaveBtn}
                    onClick={() => saveLetterHead()}
                    className="btn ant-btn-primary btn-md pr-5 pl-5 mt-3"
                  >
                    Save
                  </button>
                </Form>
              </div>
            </div>
          </article>
        </Modal>
      ) : undefined}
    </div>
  );
};

export default AddAddressBook;
