// import React, { useState } from "react";
// import {
//   Spin,
//   Tooltip,
//   Collapse,
//   Button,
//   Modal,
//   Form,
//   Col,
//   Input,
//   Row,
//   Typography,
// } from "antd";
// import {
//   CloseOutlined,
//   DownOutlined,
//   FileSearchOutlined,
//   StarOutlined,
// } from "@ant-design/icons";
// import moment from "moment";
// import URL_WITH_VERSION, { IMAGE_PATH } from "../../shared";
// import { useLocation, useNavigate } from "react-router-dom";

// const { Panel } = Collapse;
// const SelectedVesselView = ({
//   isLiveDetailLoading,
//   vesselDetails = {},
//   onClose,
//   addmyfleet,
//   vesselPositionDetails,
//   liveSearchList
// }) => {
//   const [open, setOpen] = useState(false);

//   const navigate = useNavigate();
//   const showDetails = () => {
//     navigate("/show-all-vessel-details", { state: { vesselPositionDetails, liveSearchList} });
//   };

//   const applyCss = {
//     background: "#003e78",
//     color: "#ffffff",
//     padding: "8px",
//     borderRadius: "4px",
//   };

//   const getStatus = (status) => {
//     let info = "";
//     switch (status) {
//       case "0":
//         info = "under way using engine";
//         break;
//       case "1":
//         info = "at anchor";
//         break;
//       case "2":
//         info = "not under command ";
//         break;
//       case "3":
//         info = "restricted maneuverability";
//         break;
//       case "4":
//         info = "constrained by her draught";
//         break;
//       case "5":
//         info = "moored";
//         break;
//       case "6":
//         info = "aground";
//         break;
//       case "7":
//         info = "engaged in fishing";
//         break;

//       case "8":
//         info = "under way sailing";
//         break;
//       case "9":
//         info =
//           "reserved for future amendment of navigational status for ships carrying DG, HS, or MP, or IMO hazard or pollutant category C, high-speed craft (HSC)";
//         break;
//       case "10":
//         info =
//           "reserved for future amendment of navigational status for ships carrying dangerous goods (DG), harmful substances (HS) or marine pollutants (MP), or IMO hazard or pollutant category A, wing in ground (WIG)";
//         break;
//       case "11":
//         info = "power-driven vessel towing astern (regional use)";
//         break;
//       case "12":
//         info =
//           "power-driven vessel pushing ahead or towing alongside (regional use)";
//         break;
//       case "13":
//         info = "reserved for future use";
//         break;
//       case "14":
//         info = "AIS-SART (active), MOB-AIS, EPIRB-AIS";
//         break;
//       case "15":
//         info =
//           "undefined = default (also used by AIS-SART, MOB-AIS and EPIRB-AIS under test)";
//         break;
//     }
//     return info;
//   };

//   return (
//     <div>
//       {vesselDetails && (
//         <div
//           className="filter-search-list selected-vessel"
//           style={{ width: "520px" }}
//         >
//           {isLiveDetailLoading && (
//             <div className="live-data-loader">
//               <Spin />
//             </div>
//           )}
//           {typeof onClose == "function" ? (
//             <div className="close-icon-wrapper" onClick={onClose}>
//               <CloseOutlined />
//             </div>
//           ) : undefined}

//           <div className="list-data">
//             {typeof addmyfleet == "function" ? (
//               <div className="close-icon-wrapper1">
//                 <Tooltip title="Add this vessel into my fleet">
//                   <StarOutlined
//                     //onClick={() => addmyfleet(vesselDetails.IMO)}
//                     onClick={() => addmyfleet(vesselDetails)}
//                   />
//                 </Tooltip>
//               </div>
//             ) : undefined}

//             {
//               <div>
//                 <Button
//                   type="primary"
//                   shape="round"
//                   icon={<FileSearchOutlined />}
//                   size="middle"
//                   onClick={showDetails}
//                 >
//                   More Details
//                 </Button>
//               </div>
//             }

//             <div className="heading-name-container">
//               <img
//                 src={IMAGE_PATH + "icons/vessel-yacht.svg"}
//                 className="heading-image"
//                 alt="ship image"
//               />
//               <div className="heading-icon-list">
//                 <div className="service-logo-container">
//                   <img src={IMAGE_PATH + "icons/speedometer.png"} alt="" />
//                   <span className="icon-data">
//                     {/* {vesselDetails.position? vesselDetails.position.SPEED: "NA"} */}
//                     {vesselDetails.position
//                       ? vesselDetails.position.speed
//                       : "NA"}
//                   </span>
//                 </div>
//                 <div className="service-logo-container">
//                   <img src={IMAGE_PATH + "icons/waves.png"} alt="" />
//                   <span className="icon-data">
//                     {vesselDetails.position
//                       ? vesselDetails.voyage.DRAUGHT
//                       : "NA"}
//                   </span>
//                 </div>
//                 <div className="service-logo-container">
//                   <img src={IMAGE_PATH + "icons/explore.png"} alt="" />
//                   <span className="icon-data">
//                     {vesselDetails.position
//                       ? vesselDetails.position.HEADING
//                       : "NA"}
//                   </span>
//                 </div>
//               </div>
//             </div>
//             <div className="list-items">
//               <div className="selected-data-wrapper">
//                 <div className="first-div">
//                   <div className="heading-logo-container">
//                     <img
//                       src={IMAGE_PATH + "icons/anchor_green.svg"}
//                       alt="location"
//                     />
//                     <div className="selected-data-name selected-vessel-name">
//                       {/* {vesselDetails.SHIPNAME} */}
//                       {vesselDetails.vessel_name}
//                     </div>
//                   </div>
//                   <div className="heading-logo-container">
//                     <img
//                       src={IMAGE_PATH + "icons/anchor_red.svg"}
//                       alt="location"
//                     />
//                     <div className="selected-data-name selected-vessel-id selected-next-port-name">
//                       {vesselDetails.NEXT_PORT_NAME
//                         ? vesselDetails.NEXT_PORT_NAME
//                         : "NA"}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="second-div">
//                   <div className="second-div">
//                     <span className="selected-data-name">IMO:</span>
//                     &nbsp;
//                     <span className="selected-data-name1">
//                       {/* {vesselDetails.IMO ? vesselDetails.IMO : "NA"} */}
//                       {vesselDetails.imo_number || "NA"}
//                     </span>
//                   </div>
//                   <div className="second-div">
//                     <span className="selected-data-name">MMSI:</span>
//                     &nbsp;
//                     <span className="selected-data-name1">
//                       {/* {vesselDetails.IMO ? vesselDetails.MMSI : "NA"} */}
//                       {vesselDetails.mmsi_number || "NA"}
//                     </span>
//                   </div>
//                 </div>
//                 <ul className="ul-lists-services">
//                   {/* <li className="list-items-services">
//                     <input type="checkbox" defaultChecked />
//                     <i />
//                     <div className="service-logo-container">
//                       <img src="assets/location-icon.png" alt="location" />
//                       <h2 className="service-name">Current Location</h2>
//                     </div>
//                     <p className="second-div">
//                       <div className="second-div">
//                         <span className="selected-data-name">location:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {vesselDetails.position
//                             ? vesselDetails.position.location_str
//                             : "NA"}
//                         </span>
//                       </div>

//                       <div className="second-div">
//                         <span className="selected-data-name">Destination:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {" "}
//                           {vesselDetails.voyage
//                             ? vesselDetails.voyage.destination
//                             : "NA"}
//                         </span>
//                       </div>
//                     </p>
//                     <p className="second-div">
//                       <div className="second-div">
//                         <span className="selected-data-name">latitude:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {vesselDetails.position
//                             ? vesselDetails.position.latitude
//                             : "NA"}
//                         </span>
//                       </div>

//                       <div className="second-div">
//                         <span className="selected-data-name">Longitude:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {" "}
//                           {vesselDetails.position
//                             ? vesselDetails.position.longitude
//                             : "NA"}
//                         </span>
//                       </div>
//                     </p>
//                   </li>

//                   <li>
//                     <input type="checkbox" />
//                     <i />
//                     <div className="service-logo-container">
//                       <img src="assets/last-port-ship.png" alt="" />
//                       <h2 className="service-name">Last Portcall</h2>
//                     </div>

//                     <p className="second-div">
//                       <div className="second-div">
//                         <span className="selected-data-name">Name:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {" "}
//                           {vesselDetails.last_port
//                             ? vesselDetails.last_port.name
//                             : "NA"}
//                         </span>
//                       </div>

//                       <div className="second-div">
//                         <span className="selected-data-name">
//                           Location-code:
//                         </span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {vesselDetails.last_port
//                             ? vesselDetails.last_port.locode
//                             : "NA"}
//                         </span>
//                       </div>
//                     </p>
//                     <p className="second-div">
//                       <div className="second-div">
//                         <span className="selected-data-name">ATA:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {vesselDetails.last_port &&
//                           vesselDetails.last_port.ata !== "NA"
//                             ? moment(vesselDetails.last_port.ata).format(
//                                 "YYYY-MM-DD HH:mm"
//                               )
//                             : "NA"}
//                         </span>
//                       </div>

//                       <div className="second-div">
//                         <span className="selected-data-name">ATD:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {vesselDetails.last_port &&
//                           vesselDetails.last_port.atd != "NA"
//                             ? moment(vesselDetails.last_port.atd).format(
//                                 "YYYY-MM-DD HH:mm"
//                               )
//                             : "NA"}
//                         </span>
//                       </div>
//                     </p>
//                   </li>

//                   <li>
//                     <input type="checkbox" defaultChecked />
//                     <i />
//                     <div className="service-logo-container">
//                       <img src="assets/port.png" alt="" />
//                       <h2 className="service-name">Next Portcall</h2>
//                     </div>

//                     <p className="second-div">
//                       <div className="second-div">
//                         <span className="selected-data-name">Name:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {vesselDetails.next_port
//                             ? vesselDetails.next_port.name
//                             : "NA"}
//                         </span>
//                       </div>

//                       <div className="second-div">
//                         <span className="selected-data-name">
//                           location-code:
//                         </span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {vesselDetails.next_port
//                             ? vesselDetails.next_port.name
//                             : "NA"}
//                         </span>
//                       </div>
//                     </p>
//                     <p className="second-div">
//                       <div className="second-div">
//                         <span className="selected-data-name">
//                           Travel Distance:
//                         </span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {" "}
//                           {vesselDetails.next_port
//                             ? vesselDetails.next_port.travel_distance_nm
//                             : "NA"}
//                         </span>
//                       </div>

//                       <div className="second-div">
//                         <span className="selected-data-name">Travel Time:</span>
//                         &nbsp;
//                         <span className="selected-data-name1">
//                           {" "}
//                           {vesselDetails.next_port
//                             ? vesselDetails.next_port.travel_time_h
//                             : "NA"}
//                         </span>
//                       </div>
//                     </p>
//                   </li>

//                   <li>
//                     <input type="checkbox" defaultChecked />
//                     <div className="service-logo-container">
//                       <img src="assets/anchor.png" alt="" />
//                       <h2 className="service-name">Vessel Particulers</h2>
//                     </div>

//                     <div className="">
//                       <span className="selected-data-name">
//                         Position Received:
//                       </span>
//                       &nbsp;
//                       <span className="selected-data-name1">
//                         {vesselDetails.position &&
//                         vesselDetails.position.received != "NA"
//                           ? moment(vesselDetails.position.received).format(
//                               "YYYY-MM-DD HH:mm"
//                             )
//                           : "NA"}
//                       </span>
//                     </div>

//                     <div className="">
//                       <span className="selected-data-name">Nav Status:</span>
//                       &nbsp;
//                       <span className="selected-data-name1">
//                         {vesselDetails.position
//                           ? vesselDetails.position.nav_status
//                           : "NA"}
//                       </span>
//                     </div>
//                   </li>  */}

//                   <li className="list-items-services roushan">
//                     <Collapse
//                       defaultActiveKey={["1", "2", "3", "4"]}
//                       expandIcon={({ isActive }) => (
//                         <DownOutlined rotate={isActive ? 180 : 0} />
//                       )}
//                       expandIconPosition="right"
//                     >
//                       <Panel
//                         header={
//                           <div
//                             className="track"
//                             style={{
//                               color: "black",
//                               display: "flex",
//                               alignItems: "center",
//                             }}
//                           >
//                             <img
//                               src={IMAGE_PATH + "icons/location-icon.png"}
//                               alt="location"
//                             />
//                             <h2
//                               className="service-name"
//                               style={{ margin: "0 0 0 4px" }}
//                             >
//                               Current Location
//                             </h2>
//                           </div>
//                         }
//                         key="1"
//                       >
//                         <>
//                           <p className="second-div">
//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 location:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {/* {vesselDetails.position ? vesselDetails.position.location_str:"NA"} */}
//                                 {vesselDetails.position
//                                   ? vesselDetails?.position?.location_str
//                                   : "NA"}
//                               </span>
//                             </div>
//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 latitude:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {/* {vesselDetails.position ? vesselDetails.position.LAT: "NA"} */}
//                                 {vesselDetails.position
//                                   ? vesselDetails.position.latitude
//                                   : "NA"}
//                               </span>
//                             </div>
//                           </p>
//                           <p className="second-div">
//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 Destination:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {" "}
//                                 {/* {vesselDetails.voyage? vesselDetails.voyage.DESTINATION: "NA"} */}
//                                 {vesselDetails.position
//                                   ? vesselDetails.voyage.destination
//                                   : "NA"}
//                               </span>
//                             </div>
//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 Longitude:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {" "}
//                                 {/* {vesselDetails.position ? vesselDetails.position.LON : "NA"} */}
//                                 {vesselDetails.position
//                                   ? vesselDetails.position.longitude
//                                   : "NA"}
//                               </span>
//                             </div>
//                           </p>
//                         </>
//                       </Panel>

//                       <Panel
//                         header={
//                           <div
//                             className="track"
//                             style={{
//                               color: "black",
//                               display: "flex",
//                               alignItems: "center",
//                             }}
//                           >
//                             <img
//                               src={IMAGE_PATH + "icons/last-port-ship.png"}
//                               alt=""
//                             />
//                             <h2
//                               className="service-name"
//                               style={{ margin: "0 0 0 4px" }}
//                             >
//                               Last Portcall
//                             </h2>
//                           </div>
//                         }
//                         key="2"
//                       >
//                         <>
//                           <p className="second-div">
//                             <div className="second-div">
//                               <span className="selected-data-name">Name:</span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {" "}
//                                 {vesselDetails.last_port
//                                   ? vesselDetails.last_port.name
//                                   : "NA"}
//                               </span>
//                             </div>

//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 Location-code:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {vesselDetails.last_port
//                                   ? vesselDetails.last_port.locode
//                                   : "NA"}
//                               </span>
//                             </div>
//                           </p>
//                           <p className="second-div">
//                             <div className="second-div">
//                               <span className="selected-data-name">ATA:</span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {vesselDetails.last_port &&
//                                 vesselDetails.last_port.ata !== "NA"
//                                   ? moment(vesselDetails.last_port.ata).format(
//                                       "YYYY-MM-DD HH:mm"
//                                     )
//                                   : "NA"}
//                               </span>
//                             </div>

//                             <div className="second-div">
//                               <span className="selected-data-name">ATD:</span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {vesselDetails.last_port &&
//                                 vesselDetails.last_port.atd != "NA"
//                                   ? moment(vesselDetails.last_port.atd).format(
//                                       "YYYY-MM-DD HH:mm"
//                                     )
//                                   : "NA"}
//                               </span>
//                             </div>
//                           </p>
//                         </>
//                       </Panel>

//                       <Panel
//                         header={
//                           <div
//                             className="track"
//                             style={{
//                               color: "black",
//                               display: "flex",
//                               alignItems: "center",
//                             }}
//                           >
//                             <img src={IMAGE_PATH + "icons/port.png"} alt="" />
//                             <h2
//                               className="service-name"
//                               style={{ margin: "0 0 0 4px" }}
//                             >
//                               Next Portcall
//                             </h2>
//                           </div>
//                         }
//                         key="3"
//                       >
//                         <>
//                           <p className="second-div">
//                             <div className="second-div">
//                               <span className="selected-data-name">Name:</span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {/* {vesselDetails.next_port ? vesselDetails.next_port.NEXT_PORT_NAME: "NA"} */}
//                                 {vesselDetails.next_port
//                                   ? vesselDetails.next_port.name
//                                   : "NA"}
//                               </span>
//                             </div>

//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 location-code:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {/* {vesselDetails.next_port ? vesselDetails.next_port.NEXT_PORT_NAME: "NA"} */}
//                                 {vesselDetails.next_port
//                                   ? vesselDetails.next_port.locode
//                                   : "NA"}
//                               </span>
//                             </div>
//                           </p>
//                           <p className="second-div">
//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 Travel Distance:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {" "}
//                                 {/* {vesselDetails.next_port ? vesselDetails.next_port.DISTANCE_TRAVELLED: "NA"} */}
//                                 {vesselPositionDetails.DISTANCE_TRAVELLED
//                                   ? vesselPositionDetails.DISTANCE_TRAVELLED
//                                   : "NA"}
//                               </span>
//                             </div>

//                             <div className="second-div">
//                               <span className="selected-data-name">
//                                 Travel Time:
//                               </span>
//                               &nbsp;
//                               <span className="selected-data-name1">
//                                 {" "}
//                                 {vesselDetails.next_port
//                                   ? vesselDetails.next_port.travel_time_h
//                                   : "NA"}
//                               </span>
//                             </div>
//                           </p>
//                         </>
//                       </Panel>

//                       <Panel
//                         header={
//                           <div
//                             className="track"
//                             style={{
//                               color: "black",
//                               display: "flex",
//                               alignItems: "center",
//                             }}
//                           >
//                             <img src={IMAGE_PATH + "icons/anchor.png"} alt="" />
//                             <h2
//                               className="service-name"
//                               style={{ margin: "0 0 0 4px" }}
//                             >
//                               Vessel Particulers
//                             </h2>
//                           </div>
//                         }
//                         key="4"
//                       >
//                         <>
//                           <div className="d-flex justify-content-between mb-2">
//                             <span className="selected-data-name">
//                               Position Received:
//                             </span>
//                             <span className="selected-data-name1">
//                               {vesselDetails.position &&
//                               vesselDetails.position.received != "NA"
//                                 ? moment(
//                                     vesselDetails.position.received
//                                   ).format("YYYY-MM-DD HH:mm")
//                                 : "NA"}
//                             </span>
//                           </div>

//                           <div className="d-flex justify-content-between">
//                             <span className="selected-data-name">
//                               Nav Status:
//                             </span>
//                             <span className="selected-data-name1">
//                               {vesselPositionDetails.STATUS
//                                 ? vesselPositionDetails.STATUS
//                                 : "NA"}
//                             </span>
//                           </div>
//                         </>
//                       </Panel>
//                     </Collapse>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {open && (
//         <Modal
//           title="Searched Vessel Details..."
//           centered
//           open={open}
//           onCancel={() => setOpen(false)}
//           width={1000}
//           footer={null}
//         >
//           <Form layout="vertical">
//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="MMSI :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.MMSI || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="IMO :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.IMO || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="SHIP ID :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.SHIP_ID || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="LATITUDE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.LAT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="LONGITUDE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.LON || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="SPEED :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.SPEED || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="HEADING :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.HEADING || "NA"}{" "}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="COURSE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.COURSE || "NA"}{" "}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="STATUS :">
//                   <div style={applyCss}>
//                     {getStatus(vesselPositionDetails.STATUS)}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="TIMESTAMP :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails.TIMESTAMP
//                       ? moment(vesselPositionDetails.TIMESTAMP).format(
//                           "YYYY-MM-DD HH:mm"
//                         )
//                       : "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="SHIP ID :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.SHIP_ID || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="DSRC :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.DSRC || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="UTC SECONDS :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.UTC_SECONDS || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="MARKET :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.MARKET || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="SHIP NAME :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.SHIPNAME || "NA"}{" "}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="SHIP TYPE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.SHIPTYPE || "NA"}{" "}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="CALL SIGN :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.CALLSIGN || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="FLAG :">
//                   <div style={applyCss}>{vesselPositionDetails.FLAG}</div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="LENGTH:">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.LENGTH || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="WIDTH :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.WIDTH || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="GRT :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.GRT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="DWT :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.DWT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="DRAUGHT:">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.DRAUGHT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="YEAR BUILT :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.YEAR_BUILT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="SHIP COUNTRY :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.SHIP_COUNTRY || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="SHIP CLASS :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.SHIP_CLASS || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="ROT :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.ROT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="TYPE_NAME :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.TYPE_NAME || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="AIS TYPE SUMMARY :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.AIS_TYPE_SUMMARY || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="DESTINATION :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.DESTINATION || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="ETA :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails.ETA
//                       ? moment(vesselPositionDetails.ETA).format(
//                           "YYYY-MM-DD HH:mm"
//                         )
//                       : "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="L FORE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.L_FORE || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="W LEFT :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.W_LEFT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="LAST PORT :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.LAST_PORT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="LAST PORT TIME :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails.LAST_PORT_TIME
//                       ? moment(vesselPositionDetails.LAST_PORT_TIME).format(
//                           "YYYY-MM-DD HH:mm"
//                         )
//                       : "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="LAST PORT ID :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.LAST_PORT_ID || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="LAST PORT UNLOCODE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.LAST_PORT_UNLOCODE || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="LAST PORT COUNTRY :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.LAST_PORT_COUNTRY || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="CURRENT PORT :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.CURRENT_PORT || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="CURRENT PORT ID :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.CURRENT_PORT_ID || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="CURRENT PORT UNLOCODE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.CURRENT_PORT_UNLOCODE || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="CURRENT PORT COUNTRY :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.CURRENT_PORT_COUNTRY || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="NEXT PORT ID :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.NEXT_PORT_ID || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="NEXT PORT UNLOCODE :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.NEXT_PORT_UNLOCODE || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="NEXT PORT NAME :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.NEXT_PORT_NAME || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="NEXT PORT COUNTRY :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.NEXT_PORT_COUNTRY || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="ETA CALC :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.ETA_CALC || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="ETA UPDATED :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.ETA_UPDATED || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>

//             <Row gutter={16}>
//               <Col span={6}>
//                 <Form.Item label="DISTANCE TO GO :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.DISTANCE_TO_GO || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="DISTANCE TRAVELLED :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.DISTANCE_TRAVELLED || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={6}>
//                 <Form.Item label="AVG SPEED :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.AVG_SPEED || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>

//               <Col span={6}>
//                 <Form.Item label="MAX SPEED :">
//                   <div style={applyCss}>
//                     {vesselPositionDetails?.MAX_SPEED || "NA"}
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Form>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default SelectedVesselView;

import React, { useState } from "react";
import {
  Spin,
  Tooltip,
  Collapse,
  Button,
  Modal,
  Form,
  Col,
  Input,
  Row,
  Typography,
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  FileSearchOutlined,
  StarOutlined,
} from "@ant-design/icons";
import moment from "moment";
import URL_WITH_VERSION, { IMAGE_PATH } from "../../shared";
import { useLocation, useNavigate } from "react-router-dom";

const { Panel } = Collapse;
const SelectedVesselView = ({
  isLiveDetailLoading,
  vesselDetails = {},
  onClose,
  addmyfleet,
  vesselPositionDetails,
  liveSearchList,
}) => {
  const [open, setOpen] = useState(false);
  // console.log("vesselDetails.....", vesselDetails);

  const navigate = useNavigate();
  const showDetails = () => {
    navigate("/show-all-vessel-details", {
      state: { vesselPositionDetails, vesselDetails },
    });
  };

  const applyCss = {
    background: "#003e78",
    color: "#ffffff",
    padding: "8px",
    borderRadius: "4px",
  };

  const getStatus = (status) => {
    let info = "";
    switch (status) {
      case "0":
        info = "under way using engine";
        break;
      case "1":
        info = "at anchor";
        break;
      case "2":
        info = "not under command ";
        break;
      case "3":
        info = "restricted maneuverability";
        break;
      case "4":
        info = "constrained by her draught";
        break;
      case "5":
        info = "moored";
        break;
      case "6":
        info = "aground";
        break;
      case "7":
        info = "engaged in fishing";
        break;

      case "8":
        info = "under way sailing";
        break;
      case "9":
        info =
          "reserved for future amendment of navigational status for ships carrying DG, HS, or MP, or IMO hazard or pollutant category C, high-speed craft (HSC)";
        break;
      case "10":
        info =
          "reserved for future amendment of navigational status for ships carrying dangerous goods (DG), harmful substances (HS) or marine pollutants (MP), or IMO hazard or pollutant category A, wing in ground (WIG)";
        break;
      case "11":
        info = "power-driven vessel towing astern (regional use)";
        break;
      case "12":
        info =
          "power-driven vessel pushing ahead or towing alongside (regional use)";
        break;
      case "13":
        info = "reserved for future use";
        break;
      case "14":
        info = "AIS-SART (active), MOB-AIS, EPIRB-AIS";
        break;
      case "15":
        info =
          "undefined = default (also used by AIS-SART, MOB-AIS and EPIRB-AIS under test)";
        break;
    }
    return info;
  };

  return (
    <div>
      {vesselDetails && (
        <div
          className="filter-search-list selected-vessel"
          style={{ width: "520px" }}
        >
          {isLiveDetailLoading && (
            <div className="live-data-loader">
              <Spin />
            </div>
          )}
          {typeof onClose == "function" ? (
            <div className="close-icon-wrapper" onClick={onClose}>
              <CloseOutlined />
            </div>
          ) : undefined}

          <div className="list-data">
            {typeof addmyfleet == "function" ? (
              <div className="close-icon-wrapper1">
                <Tooltip title="Add this vessel into my fleet">
                  <StarOutlined
                    //onClick={() => addmyfleet(vesselDetails.IMO)}
                    onClick={() => addmyfleet(vesselDetails)}
                  />
                </Tooltip>
              </div>
            ) : undefined}

            {
              <div>
                <Button
                  type="primary"
                  shape="round"
                  icon={<FileSearchOutlined />}
                  size="middle"
                  onClick={showDetails}
                >
                  More Details
                </Button>
              </div>
            }

            <div className="heading-name-container">
              <img
                src={IMAGE_PATH + "icons/vessel-yacht.svg"}
                className="heading-image"
                alt="ship image"
              />
              <div className="heading-icon-list">
                <div className="service-logo-container">
                  <img src={IMAGE_PATH + "icons/speedometer.png"} alt="" />
                  <span className="icon-data">
                    {/* {vesselDetails.position? vesselDetails.position.SPEED: "NA"} */}
                    {vesselDetails.position
                      ? vesselDetails.position.speed
                      : "NA"}
                  </span>
                </div>
                <div className="service-logo-container">
                  <img src={IMAGE_PATH + "icons/waves.png"} alt="" />
                  <span className="icon-data">
                    {vesselDetails.position
                      ? vesselDetails.voyage.DRAUGHT
                      : "NA"}
                  </span>
                </div>
                <div className="service-logo-container">
                  <img src={IMAGE_PATH + "icons/explore.png"} alt="" />
                  <span className="icon-data">
                    {vesselDetails.position
                      ? vesselDetails.position.HEADING
                      : "NA"}
                  </span>
                </div>
              </div>
            </div>
            <div className="list-items">
              <div className="selected-data-wrapper">
                <div className="first-div">
                  <div className="heading-logo-container">
                    <img
                      src={IMAGE_PATH + "icons/anchor_green.svg"}
                      alt="location"
                    />
                    <div className="selected-data-name selected-vessel-name">
                      {/* {vesselDetails.SHIPNAME} */}
                      {vesselDetails.vessel_name}
                    </div>
                  </div>
                  <div className="heading-logo-container">
                    <img
                      src={IMAGE_PATH + "icons/anchor_red.svg"}
                      alt="location"
                    />
                    <div className="selected-data-name selected-vessel-id selected-next-port-name">
                      {vesselDetails.NEXT_PORT_NAME
                        ? vesselDetails.NEXT_PORT_NAME
                        : "NA"}
                    </div>
                  </div>
                </div>
                <div className="second-div">
                  <div className="second-div">
                    <span className="selected-data-name">IMO:</span>
                    &nbsp;
                    <span className="selected-data-name1">
                      {/* {vesselDetails.IMO ? vesselDetails.IMO : "NA"} */}
                      {vesselDetails.imo_number || "NA"}
                    </span>
                  </div>
                  <div className="second-div">
                    <span className="selected-data-name">MMSI:</span>
                    &nbsp;
                    <span className="selected-data-name1">
                      {/* {vesselDetails.IMO ? vesselDetails.MMSI : "NA"} */}
                      {vesselDetails.mmsi_number || "NA"}
                    </span>
                  </div>
                </div>
                <ul className="ul-lists-services">
                  {/* <li className="list-items-services">
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="service-logo-container">
                      <img src="assets/location-icon.png" alt="location" />
                      <h2 className="service-name">Current Location</h2>
                    </div>
                    <p className="second-div">
                      <div className="second-div">
                        <span className="selected-data-name">location:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {vesselDetails.position
                            ? vesselDetails.position.location_str
                            : "NA"}
                        </span>
                      </div>

                      <div className="second-div">
                        <span className="selected-data-name">Destination:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {" "}
                          {vesselDetails.voyage
                            ? vesselDetails.voyage.destination
                            : "NA"}
                        </span>
                      </div>
                    </p>
                    <p className="second-div">
                      <div className="second-div">
                        <span className="selected-data-name">latitude:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {vesselDetails.position
                            ? vesselDetails.position.latitude
                            : "NA"}
                        </span>
                      </div>

                      <div className="second-div">
                        <span className="selected-data-name">Longitude:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {" "}
                          {vesselDetails.position
                            ? vesselDetails.position.longitude
                            : "NA"}
                        </span>
                      </div>
                    </p>
                  </li>

                  <li>
                    <input type="checkbox" />
                    <i />
                    <div className="service-logo-container">
                      <img src="assets/last-port-ship.png" alt="" />
                      <h2 className="service-name">Last Portcall</h2>
                    </div>

                    <p className="second-div">
                      <div className="second-div">
                        <span className="selected-data-name">Name:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {" "}
                          {vesselDetails.last_port
                            ? vesselDetails.last_port.name
                            : "NA"}
                        </span>
                      </div>

                      <div className="second-div">
                        <span className="selected-data-name">
                          Location-code:
                        </span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {vesselDetails.last_port
                            ? vesselDetails.last_port.locode
                            : "NA"}
                        </span>
                      </div>
                    </p>
                    <p className="second-div">
                      <div className="second-div">
                        <span className="selected-data-name">ATA:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {vesselDetails.last_port &&
                          vesselDetails.last_port.ata !== "NA"
                            ? moment(vesselDetails.last_port.ata).format(
                                "YYYY-MM-DD HH:mm"
                              )
                            : "NA"}
                        </span>
                      </div>

                      <div className="second-div">
                        <span className="selected-data-name">ATD:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {vesselDetails.last_port &&
                          vesselDetails.last_port.atd != "NA"
                            ? moment(vesselDetails.last_port.atd).format(
                                "YYYY-MM-DD HH:mm"
                              )
                            : "NA"}
                        </span>
                      </div>
                    </p>
                  </li>

                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="service-logo-container">
                      <img src="assets/port.png" alt="" />
                      <h2 className="service-name">Next Portcall</h2>
                    </div>

                    <p className="second-div">
                      <div className="second-div">
                        <span className="selected-data-name">Name:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {vesselDetails.next_port
                            ? vesselDetails.next_port.name
                            : "NA"}
                        </span>
                      </div>

                      <div className="second-div">
                        <span className="selected-data-name">
                          location-code:
                        </span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {vesselDetails.next_port
                            ? vesselDetails.next_port.name
                            : "NA"}
                        </span>
                      </div>
                    </p>
                    <p className="second-div">
                      <div className="second-div">
                        <span className="selected-data-name">
                          Travel Distance:
                        </span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {" "}
                          {vesselDetails.next_port
                            ? vesselDetails.next_port.travel_distance_nm
                            : "NA"}
                        </span>
                      </div>

                      <div className="second-div">
                        <span className="selected-data-name">Travel Time:</span>
                        &nbsp;
                        <span className="selected-data-name1">
                          {" "}
                          {vesselDetails.next_port
                            ? vesselDetails.next_port.travel_time_h
                            : "NA"}
                        </span>
                      </div>
                    </p>
                  </li>

                  <li>
                    <input type="checkbox" defaultChecked />
                    <div className="service-logo-container">
                      <img src="assets/anchor.png" alt="" />
                      <h2 className="service-name">Vessel Particulers</h2>
                    </div>

                    <div className="">
                      <span className="selected-data-name">
                        Position Received:
                      </span>
                      &nbsp;
                      <span className="selected-data-name1">
                        {vesselDetails.position &&
                        vesselDetails.position.received != "NA"
                          ? moment(vesselDetails.position.received).format(
                              "YYYY-MM-DD HH:mm"
                            )
                          : "NA"}
                      </span>
                    </div>

                    <div className="">
                      <span className="selected-data-name">Nav Status:</span>
                      &nbsp;
                      <span className="selected-data-name1">
                        {vesselDetails.position
                          ? vesselDetails.position.nav_status
                          : "NA"}
                      </span>
                    </div>
                  </li>  */}

                  <li className="list-items-services roushan">
                    <Collapse
                      defaultActiveKey={["1", "2", "3", "4"]}
                      expandIcon={({ isActive }) => (
                        <DownOutlined rotate={isActive ? 180 : 0} />
                      )}
                      expandIconPosition="right"
                    >
                      <Panel
                        header={
                          <div
                            className="track"
                            style={{
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={IMAGE_PATH + "icons/location-icon.png"}
                              alt="location"
                            />
                            <h2
                              className="service-name"
                              style={{ margin: "0 0 0 4px" }}
                            >
                              Current Location
                            </h2>
                          </div>
                        }
                        key="1"
                      >
                        <>
                          <p className="second-div">
                            <div className="second-div">
                              <span className="selected-data-name">
                                location:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {/* {vesselDetails.position ? vesselDetails.position.location_str:"NA"} */}
                                {vesselDetails.position
                                  ? vesselDetails?.position?.location_str
                                  : "NA"}
                              </span>
                            </div>
                            <div className="second-div">
                              <span className="selected-data-name">
                                latitude:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {/* {vesselDetails.position ? vesselDetails.position.LAT: "NA"} */}
                                {vesselDetails?.position
                                  ? vesselDetails?.position.latitude
                                  : "NA"}
                              </span>
                            </div>
                          </p>
                          <p className="second-div">
                            <div className="second-div">
                              <span className="selected-data-name">
                                Destination:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {" "}
                                {/* {vesselDetails.voyage? vesselDetails.voyage.DESTINATION: "NA"} */}
                                {vesselDetails?.position
                                  ? vesselDetails?.voyage.destination
                                  : "NA"}
                              </span>
                            </div>
                            <div className="second-div">
                              <span className="selected-data-name">
                                Longitude:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {" "}
                                {/* {vesselDetails.position ? vesselDetails.position.LON : "NA"} */}
                                {vesselDetails?.position
                                  ? vesselDetails?.position.longitude
                                  : "NA"}
                              </span>
                            </div>
                          </p>
                        </>
                      </Panel>

                      <Panel
                        header={
                          <div
                            className="track"
                            style={{
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={IMAGE_PATH + "icons/last-port-ship.png"}
                              alt=""
                            />
                            <h2
                              className="service-name"
                              style={{ margin: "0 0 0 4px" }}
                            >
                              Last Portcall
                            </h2>
                          </div>
                        }
                        key="2"
                      >
                        <>
                          <p className="second-div">
                            <div className="second-div">
                              <span className="selected-data-name">Name:</span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {" "}
                                {vesselDetails?.last_port
                                  ? vesselDetails?.last_port.name
                                  : "NA"}
                              </span>
                            </div>

                            <div className="second-div">
                              <span className="selected-data-name">
                                Location-code:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {vesselDetails?.last_port
                                  ? vesselDetails?.last_port.locode
                                  : "NA"}
                              </span>
                            </div>
                          </p>
                          <p className="second-div">
                            <div className="second-div">
                              <span className="selected-data-name">ATA:</span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {vesselDetails?.last_port &&
                                vesselDetails?.last_port.ata !== "NA"
                                  ? moment(vesselDetails?.last_port.ata).format(
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  : "NA"}
                              </span>
                            </div>

                            <div className="second-div">
                              <span className="selected-data-name">ATD:</span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {vesselDetails?.last_port &&
                                vesselDetails?.last_port.atd != "NA"
                                  ? moment(vesselDetails?.last_port.atd).format(
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  : "NA"}
                              </span>
                            </div>
                          </p>
                        </>
                      </Panel>

                      <Panel
                        header={
                          <div
                            className="track"
                            style={{
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src={IMAGE_PATH + "icons/port.png"} alt="" />
                            <h2
                              className="service-name"
                              style={{ margin: "0 0 0 4px" }}
                            >
                              Next Portcall
                            </h2>
                          </div>
                        }
                        key="3"
                      >
                        <>
                          <p className="second-div">
                            <div className="second-div">
                              <span className="selected-data-name">Name:</span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {/* {vesselDetails.next_port ? vesselDetails.next_port.NEXT_PORT_NAME: "NA"} */}
                                {vesselDetails?.next_port
                                  ? vesselDetails?.next_port.name
                                  : "NA"}
                              </span>
                            </div>

                            <div className="second-div">
                              <span className="selected-data-name">
                                location-code:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {/* {vesselDetails.next_port ? vesselDetails.next_port.NEXT_PORT_NAME: "NA"} */}
                                {vesselDetails?.next_port
                                  ? vesselDetails?.next_port.locode
                                  : "NA"}
                              </span>
                            </div>
                          </p>
                          <p className="second-div">
                            <div className="second-div">
                              <span className="selected-data-name">
                                Travel Distance:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {" "}
                                {/* {vesselDetails.next_port ? vesselDetails.next_port.DISTANCE_TRAVELLED: "NA"} */}
                                {vesselPositionDetails?.DISTANCE_TRAVELLED
                                  ? vesselPositionDetails?.DISTANCE_TRAVELLED
                                  : "NA"}
                              </span>
                            </div>

                            <div className="second-div">
                              <span className="selected-data-name">
                                Travel Time:
                              </span>
                              &nbsp;
                              <span className="selected-data-name1">
                                {" "}
                                {vesselDetails?.next_port
                                  ? vesselDetails?.next_port.travel_time_h
                                  : "NA"}
                              </span>
                            </div>
                          </p>
                        </>
                      </Panel>

                      <Panel
                        header={
                          <div
                            className="track"
                            style={{
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src={IMAGE_PATH + "icons/anchor.png"} alt="" />
                            <h2
                              className="service-name"
                              style={{ margin: "0 0 0 4px" }}
                            >
                              Vessel Particulers
                            </h2>
                          </div>
                        }
                        key="4"
                      >
                        <>
                          <div className="d-flex justify-content-between mb-2">
                            <span className="selected-data-name">
                              Position Received:
                            </span>
                            <span className="selected-data-name1">
                              {vesselDetails?.position &&
                              vesselDetails?.position.received != "NA"
                                ? moment(
                                    vesselDetails?.position.received
                                  ).format("YYYY-MM-DD HH:mm")
                                : "NA"}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <span className="selected-data-name">
                              Nav Status:
                            </span>
                            <span className="selected-data-name1">
                              {vesselPositionDetails?.STATUS
                                ? vesselPositionDetails?.STATUS
                                : "NA"}
                            </span>
                          </div>
                        </>
                      </Panel>
                    </Collapse>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {open && (
        <Modal
          title="Searched Vessel Details..."
          centered
          open={open}
          onCancel={() => setOpen(false)}
          width={1000}
          footer={null}
        >
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="MMSI :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.MMSI || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="IMO :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.IMO || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="SHIP ID :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.SHIP_ID || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="LATITUDE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LAT || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="LONGITUDE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LON || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="SPEED :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.SPEED || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="HEADING :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.HEADING || "NA"}{" "}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="COURSE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.COURSE || "NA"}{" "}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="STATUS :">
                  <div style={applyCss}>
                    {getStatus(vesselPositionDetails?.STATUS)}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="TIMESTAMP :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.TIMESTAMP
                      ? moment(vesselPositionDetails?.TIMESTAMP).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="SHIP ID :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.SHIP_ID || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="DSRC :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.DSRC || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="UTC SECONDS :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.UTC_SECONDS || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="MARKET :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.MARKET || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="SHIP NAME :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.SHIPNAME || "NA"}{" "}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="SHIP TYPE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.SHIPTYPE || "NA"}{" "}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="CALL SIGN :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.CALLSIGN || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="FLAG :">
                  <div style={applyCss}>{vesselPositionDetails?.FLAG}</div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="LENGTH:">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LENGTH || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="WIDTH :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.WIDTH || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="GRT :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.GRT || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="DWT :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.DWT || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="DRAUGHT:">
                  <div style={applyCss}>
                    {vesselPositionDetails?.DRAUGHT || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="YEAR BUILT :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.YEAR_BUILT || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="SHIP COUNTRY :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.SHIP_COUNTRY || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="SHIP CLASS :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.SHIP_CLASS || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="ROT :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.ROT || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="TYPE_NAME :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.TYPE_NAME || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="AIS TYPE SUMMARY :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.AIS_TYPE_SUMMARY || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="DESTINATION :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.DESTINATION || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="ETA :">
                  <div style={applyCss}>
                    {vesselPositionDetails.ETA
                      ? moment(vesselPositionDetails?.ETA).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="L FORE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.L_FORE || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="W LEFT :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.W_LEFT || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="LAST PORT :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LAST_PORT || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="LAST PORT TIME :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LAST_PORT_TIME
                      ? moment(vesselPositionDetails?.LAST_PORT_TIME).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="LAST PORT ID :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LAST_PORT_ID || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="LAST PORT UNLOCODE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LAST_PORT_UNLOCODE || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="LAST PORT COUNTRY :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.LAST_PORT_COUNTRY || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="CURRENT PORT :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.CURRENT_PORT || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="CURRENT PORT ID :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.CURRENT_PORT_ID || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="CURRENT PORT UNLOCODE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.CURRENT_PORT_UNLOCODE || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="CURRENT PORT COUNTRY :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.CURRENT_PORT_COUNTRY || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="NEXT PORT ID :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.NEXT_PORT_ID || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="NEXT PORT UNLOCODE :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.NEXT_PORT_UNLOCODE || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="NEXT PORT NAME :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.NEXT_PORT_NAME || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="NEXT PORT COUNTRY :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.NEXT_PORT_COUNTRY || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="ETA CALC :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.ETA_CALC || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="ETA UPDATED :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.ETA_UPDATED || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="DISTANCE TO GO :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.DISTANCE_TO_GO || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="DISTANCE TRAVELLED :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.DISTANCE_TRAVELLED || "NA"}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="AVG SPEED :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.AVG_SPEED || "NA"}
                  </div>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="MAX SPEED :">
                  <div style={applyCss}>
                    {vesselPositionDetails?.MAX_SPEED || "NA"}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default SelectedVesselView;
