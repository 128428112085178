import React, { useEffect, useState } from "react";
import {
  Menu,
  Dropdown,
  Button,
  Typography,
  Box,
  Tooltip,
  Modal,
  Input,
  Space,
  message,
  Spin,
} from "antd";
import {
  DownOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
// import './styles.css'; // Assuming you have a CSS file for custom styles
import { Icon } from "@iconify-icon/react";
import {
  apiDeleteCall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../shared";
import "./email.css";
import {
  ReturnTemplateCargoFirm,
  ReturnTemplateCargoOrder,
  ReturnTemplateTonnageFirm,
  ReturnTemplateTonnageOrder,
} from "./templateData";
import Swal from "sweetalert2";

const templatesList = [
  { id: 1, template_name: "Template 1" },
  { id: 2, template_name: "Template 2" },
];

const orderState = {
  tonnageOrderList: [
    { id: 1, templatename: "Tonnage Order 1", template_type: "type1" },
  ],
  cargoOrderList: [
    { id: 1, templatename: "Cargo Order 1", template_type: "type1" },
  ],
  tonnageFirmList: [
    { id: 1, templateName: "Tonnage Firm Offer 1", template_type: "type1" },
  ],
  cargoFirmList: [
    { id: 1, templateName: "Cargo Firm Offer 1", template_type: "type1" },
  ],
};

const MenuComponent = ({
  localBody,
  setLocalBody,
  quillRef,
  composeData,
}) => {
  const [menuOpen, setMenuOpen] = useState({});
  const [visible, setVisible] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [emailTemplate, setEmailTemplate] = useState({
    tonnageOrder: { name: "", data: [] },
    cargoOrder: { name: "", data: [] },
    tonnageFirmOffer: { name: "", data: [] },
    cargoFirmOffer: { name: "", data: [] },
  });
  const [otherEmailTemplates, setOtherEmailTeplates] = useState([]);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);

  const fetchOtherEmailTemplate = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/get-email-templates`;
      const res = await getAPICall(url);
      if (res) {
        setOtherEmailTeplates(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllEmailTemplate = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/all-templates`;
      const res = await getAPICall(url);
      if (res) {
        setEmailTemplate({
          ...emailTemplate,
          tonnageOrder: { name: "Tonnage Order", data: res?.tonnage_templates },
          cargoOrder: { name: "Cargo Order", data: res?.cargo_templates },
          tonnageFirmOffer: {
            name: "Tonnage Firm Offer",
            data: res?.tonnage_firm,
          },
          cargoFirmOffer: { name: "Cargo Firm Offer", data: res?.caargo_firm },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllEmailTemplate();
    fetchOtherEmailTemplate();
  }, []);

  const handleMenuOpen = (key) => {
    setMenuOpen({ ...menuOpen, [key]: !menuOpen[key] });
  };

  const handleCloseOnhover = (key) => {
    setMenuOpen({ ...menuOpen, [key]: false });
  };

  const handleClick = (key) => {
    console.log(`Clicked on ${key}`);
  };

  const saveTemplate = () => {
    console.log("Save Draft as Template");
    setVisible(true);
  };

  const deleteTemplate = async (id, type) => {
    try {
      const url =
        type === "other"
          ? `${process.env.REACT_APP_MT_URL}accounts/delete-email-template/${id}`
          : `${process.env.REACT_APP_MT_URL}accounts/delete-template-type/${id}/${type}`;
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
        customClass: {
          container: "swal-custom-container",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await apiDeleteCall(url);
          if (res?.status) {
            openNotificationWithIcon("success", "Deleted Successfully.");
            type === "other"
              ? fetchOtherEmailTemplate()
              : fetchAllEmailTemplate();
          }
        }
      });
    } catch (err) {
      console.log(err.message);
      openNotificationWithIcon("error");
    }
  };

  const insertOtherTemplate = async (id) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/get-email-template/${id}`;
      const res = await getAPICall(url);
      if (res?.status) {
        setLocalBody(res?.data?.body);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getEmailTemplateById = async (id, template_type) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/template-type/${id}/${template_type}`;
      const res = await getAPICall(url);
      if (res?.status) {
        let body = "";
        if (template_type === "tonnage_order_template") {
          body = ReturnTemplateTonnageOrder(res?.data);
        } else if (template_type === "cargo_firm_offer") {
          body = ReturnTemplateCargoFirm(res?.data);
        } else if (template_type === "tonnage_firm_offer") {
          body = ReturnTemplateTonnageFirm(res?.data);
        } else if (template_type === "cargo_order_template") {
          body = ReturnTemplateCargoOrder(res?.data);
        }
        setLocalBody(body);
        // const quill = quillRef.current.getEditor();
        // quill.clipboard.dangerouslyPasteHTML(body);
        // console.log("editor data",quill.root.innerHTML)
        // setLocalBody(quill.root.innerHTML);
        // insertHtmlContent(body);
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  const deleteTonnageCargoTemplates = (id, type) => {
    console.log("Delete Tonnage Cargo Template with ID:", id, "Type:", type);
  };

  const handleTemplateSave = async () => {
    // setVisible(false)
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/add-email-template`;
      const payload = {
        send_to: composeData?.to,
        cc: composeData?.cc,
        bcc: composeData?.bcc,
        send_from: composeData?.owner_email,
        subject: composeData?.subject,
        template_name: templateName,
        body: composeData?.body,
      };
      setIsSavingTemplate(true);
      const response = await postAPICall(url, payload, "post");
      if (response?.status) {
        fetchOtherEmailTemplate();
        setVisible(false);
        setIsSavingTemplate(false);
        setTemplateName("");
        openNotificationWithIcon("success", "Template saved successfully.");
      } else {
        openNotificationWithIcon("error", response?.msg);
        setIsSavingTemplate(false);
        // setVisible(false);
      }
    } catch (err) {
      console.log(err.message);
      setIsSavingTemplate(false);
      setVisible(false);
    }
  };

  const menu = (
    <Menu
      className="templatePopover"
      getPopupContainer={(triggerNode) =>
        triggerNode.closest(".ant-modal-body")
      }
    >
      {/* <Menu.Item>
        <Typography className='template_heading'>INSERT TEMPLATE</Typography>
      </Menu.Item> */}
      <h4 style={{ textAlign: "center" }}>Insert Template</h4>
      <Menu.Item onClick={saveTemplate} className="menu-item-save">
        Save Draft as Template
      </Menu.Item>

      <Menu.SubMenu
        title="Other Templates"
        onTitleClick={() => handleMenuOpen("otherTemplates")}
        onMouseEnter={() => handleMenuOpen("otherTemplates")}
        onMouseLeave={() => handleCloseOnhover("otherTemplates")}
        popupClassName="custom-submenu"
      >
        {otherEmailTemplates?.map((template) => (
          <Menu.Item
            key={template.id}
            onClick={() => insertOtherTemplate(template.id)}
            className="menu-item"
          >
            {/* <EditOutlined style={{ marginRight: '5px' }} />
            {template?.template_name}
            <Button
              type='text'
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                deleteTemplate(template.id);
              }}
              className='menu-item-button'
            /> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{template?.template_name}</div>
              <div>
                <EditOutlined style={{ marginRight: "10px" }} />
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTemplate(template?.id, "other");
                  }}
                />
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      {Object.entries(emailTemplate).map(([key, item], index) => (
        <Menu.SubMenu
          key={index}
          title={item?.name}
          onTitleClick={() => handleMenuOpen(key)}
          onMouseEnter={() => handleMenuOpen(key)}
          onMouseLeave={() => handleCloseOnhover(key)}
          popupClassName="custom-submenu"
        >
          {item?.data.map((subItem) => (
            <Menu.Item
              key={subItem?.id}
              onClick={() =>
                getEmailTemplateById(subItem?.id, subItem?.template_type)
              }
              className="menu-item"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{subItem?.templateName || subItem?.templatename}</div>
                <div>
                  <EditOutlined style={{ marginRight: "10px" }} />
                  <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTemplate(subItem?.id, subItem?.template_type);
                    }}
                  />
                  {/* <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTonnageCargoTemplates(
                        subItem?.id,
                        subItem?.template_type
                      );
                    }}
                    className="menu-item-button"
                  /> */}
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ))}

      {/* Repeat similar structure for other menus */}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        getPopupContainer={(triggerNode) =>
          triggerNode.closest(".ant-modal-body")
        }
      >
        <Tooltip title="Email templates">
          <div style={{ cursor: "pointer", marginLeft: "20px" }}>
            <Icon icon={"tabler:pencil-bolt"} style={{ fontSize: "20px" }} />
          </div>
        </Tooltip>
      </Dropdown>
      <Modal
        title="Save Template"
        open={visible}
        closable
        destroyOnClose
        // onOk={handleOk}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleTemplateSave}
            disabled={isSavingTemplate}
          >
            {!isSavingTemplate ? (
              "Save"
            ) : (
              <span>
                Saving{" "}
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              </span>
            )}
          </Button>,
        ]}
      >
        <Input
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          placeholder="Enter template name"
        />
      </Modal>
    </>
  );
};

export default MenuComponent;
